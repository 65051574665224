import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { RatingSizeType } from '../../common';

@Component({
    selector: 'wuc-product-grade[title]',
    templateUrl: 'product-grade.component.html',
    styleUrls: ['product-grade.component.scss'],
})
export class WucProductGradeComponent implements OnInit, OnDestroy {
    @Input() public rating?: number;
    @Input() public ratingSize?: RatingSizeType;
    @Input() public title!: string;
    @Input() public isAnimated: boolean = false;
    @Input() public subTitle!: string;
    @Input() public layoutStyle?: 'sidebar' | 'headerSmall' | 'headerLarge' | 'headerBlock';

    @Output() public clicked: EventEmitter<void> = new EventEmitter<void>();

    constructor(private elementRef: ElementRef) {}

    private intersectionObserver!: IntersectionObserver;

    public isInView: boolean = false;

    public get hasAnimation(): boolean {
        return !!this.intersectionObserver && this.isAnimated;
    }

    public get isClickable(): boolean {
        return !this.layoutStyle || ['headerSmall', 'headerBlock', 'sidebar'].includes(this.layoutStyle);
    }

    public get ratingBubbleSize(): RatingSizeType {
        return this.ratingSize
            ? this.ratingSize
            : this.layoutStyle && ['headerSmall', 'headerBlock'].includes(this.layoutStyle)
                ? 'large'
                : 'medium';
    }

    public onClick(): void {
        if (this.isClickable) {
            this.clicked.emit();
        }
    }

    public ngOnInit(): void {
        if (typeof window !== 'undefined' && window) {
            if ('IntersectionObserver' in window) {
                this.intersectionObserver = new IntersectionObserver(
                    (entries: IntersectionObserverEntry[]) => {
                        entries.forEach((entry: IntersectionObserverEntry) => {
                            this.isInView = entry.isIntersecting;
                            if (this.isInView) {
                                this.intersectionObserver.disconnect();
                            }
                        });
                    },
                    {
                        threshold: 0.1,
                    }
                );
                this.intersectionObserver.observe(this.elementRef.nativeElement);
            }
        }
    }

    public ngOnDestroy(): void {
        if (this.intersectionObserver) {
            this.intersectionObserver.disconnect();
        }
    }
}
