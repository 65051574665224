/* eslint-disable brace-style */
import { Component, ElementRef, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { AutocompleteEnum } from '../autocomplete.enum';
import { WucInputBaseComponent } from '../input-base.component';
import { InputModeEnum } from '../input-mode.enum';
import { InputModeService } from '../input-mode.service';
import { InputInterface } from '../input.interface';

/**
 * @deprecated This component is deprecated. Please us the WucInputZipcodeComponent instead. WucInputZipcodeComponent default behavior is the same as this one.
 */
@Component({
    selector: 'wuc-input-zipcode-nl',
    templateUrl: 'input-zipcode-nl.component.html',
    styleUrls: ['input-zipcode-nl.component.scss'],
    // eslint-disable-next-line
    inputs: WucInputBaseComponent.inputs,
    providers: [
        {
            provide: WucInputBaseComponent,
            useExisting: WucInputZipcodeNlComponent,
        },
    ],
})
export class WucInputZipcodeNlComponent
    extends WucInputBaseComponent
    implements OnInit, ControlValueAccessor, InputInterface
{
    @ViewChild('input', { static: true }) private input!: ElementRef;
    @Input() public placeholder: string = '';

    public disabled: boolean = false;
    public value: string = '';
    public onChange!: (value: string) => void;
    public onTouched!: () => void;

    private readonly allowedCharactersRexExp: RegExp = /^[a-z\d ]*$/i;
    private readonly validPattern: string = '9999ZZZ';

    private readonly formatRexExp: RegExp = /^([1-9]\d{3})([A-Z]{1,2})$/;
    private readonly formatReplace: string = '$1 $2';

    // @ts-ignore
    constructor(@Optional() public override ngControl: NgControl) {
        super(ngControl);
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    public ngOnInit(): void {
        this.autocomplete = AutocompleteEnum.PostalCode;
        this.inputmode = InputModeEnum.Numeric;
    }

    // Update value from API; API => view
    public writeValue(value: string): void {
        this.value = this.formatInput(value);
        this.updateInputmode();
    }

    // Update value from view "on change"; View => API
    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    // Mark control as touched; View => API
    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    // Set disabled state from API
    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public onInput(value: string): void {
        this.value = this.formatInput(value);
        this.updateInputmode();
        this.emitValue(value);
    }

    public onBlur(): void {
        this.input.nativeElement.value = this.formatInput(this.value);
        this.emitValue(this.value);
        this.onTouched();
    }

    public onBeforeInput(event: InputEvent): void {
        if (!event.data) {
            return;
        }
        if (event.data && !this.allowedCharactersRexExp.test(event.data)) {
            event.preventDefault();
            return;
        }
    }

    public updateInputmode(): void {
        this.inputmode = InputModeService.getInputmodeForInput(this.validPattern, this.input.nativeElement);
    }

    private emitValue(value: string): void {
        this.onChange(value.replace(' ', '').toUpperCase());
    }

    private formatInput(value: string): string {
        if (!value) {
            return '';
        }
        return value.toUpperCase().replace(this.formatRexExp, this.formatReplace);
    }
}
