import { Component, Input, TemplateRef, ViewChild } from '@angular/core';

@Component({
    selector: 'wuc-select-option',
    templateUrl: 'select-option.component.html',
})
export class WucSelectOptionComponent {
    @ViewChild('optionContent', { read: TemplateRef, static: false })
    public optionContent!: TemplateRef<HTMLElement>;

    @Input() public value: {} | string | number = '';
}
