import { Component, ElementRef, Inject, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { IconUiIcon } from '../../common';
import { AutocompleteEnum } from '../autocomplete.enum';
import { WucInputBaseComponent } from '../input-base.component';
import { InputModeEnum } from '../input-mode.enum';
import { InputInterface } from '../input.interface';
import { InputPasswordConfigInterface } from './input-password-config.interface';
import { inputPasswordDefaultConfig } from './input-password-default-config';

@Component({
    selector: 'wuc-input-password',
    templateUrl: 'input-password.component.html',
    styleUrls: ['input-password.component.scss'],
    // eslint-disable-next-line
    inputs: WucInputBaseComponent.inputs,
    providers: [
        {
            provide: WucInputBaseComponent,
            useExisting: WucInputPasswordComponent,
        },
    ],
})
export class WucInputPasswordComponent
    extends WucInputBaseComponent
    implements OnInit, ControlValueAccessor, InputInterface
{
    @ViewChild('input', { static: true }) private input!: ElementRef;

    @Input() public placeholder: string = '';
    @Input() public hideHint: boolean = false;

    public isDisabled: boolean = false;
    public value: string = '';
    public onChange!: (value: string) => void;

    public onTouched!: () => void;
    public showDropdown: boolean = false;
    public iconEyeOpen: IconUiIcon = 'eye-open';
    public iconEyeClosed: IconUiIcon = 'eye-closed';

    public icon: IconUiIcon = this.iconEyeOpen;
    public inputType: 'text' | 'password' = 'password';

    public config: InputPasswordConfigInterface = inputPasswordDefaultConfig;

    constructor(
        @Optional() public override ngControl: NgControl,
        @Inject('passwordConfig') config: InputPasswordConfigInterface
    ) {
        super(ngControl);
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
        Object.assign(this.config, config);
    }

    public ngOnInit(): void {
        this.autocomplete = this.autocomplete || AutocompleteEnum.NewPassword;
        this.inputmode = InputModeEnum.Text;
        this.e2e = this.e2e || 'password';
    }

    // Update value from API; API => view
    public writeValue(value: string): void {
        this.value = value;
    }

    // Update value from view "on change"; View => API
    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    // Mark control as touched; View => API
    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    // Set disabled state from API
    public setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    public onInput(value: string): void {
        this.value = value;
        this.emitValue();
    }

    public onBlur(): void {
        this.emitValue();
        this.onTouched();
    }

    private emitValue(): void {
        this.onChange(this.value);
    }

    public get progressValue(): number {
        return (
            4 +
            ((this.hasRequiredAmountOfLetters ? 1 : 0) +
                (this.hasRequiredAmountOfNumbers ? 1 : 0) +
                (this.hasRequiredAmountOfSpecialCharacters && !this.hasInvalidSpecialCharacters ? 1 : 0) +
                (this.hasRequiredLength ? 1 : 0)) *
                24
        );
    }

    public get hasRequiredAmountOfLetters(): boolean {
        return this.value.replace(/[^a-z]/gi, '').length >= this.config.amountOfLetters;
    }

    public get hasRequiredAmountOfNumbers(): boolean {
        return this.value.replace(/\D/gi, '').length >= this.config.amountOfNumbers;
    }

    public get hasRequiredLength(): boolean {
        return this.value.length >= this.config.minLength && this.value.length <= this.config.maxLength;
    }

    public get hasRequiredAmountOfSpecialCharacters(): boolean {
        const pattern = new RegExp('[^\\' + this.config.specialCharacters.split('').join('\\') + ']', 'gi');
        return this.value.replace(pattern, '').length >= this.config.amountOfSpecialCharacters;
    }

    public get hasInvalidSpecialCharacters(): boolean {
        const pattern = new RegExp('[\\' + this.config.specialCharacters.split('').join('\\') + 'a-z\\d]', 'gi');
        return this.value.replace(pattern, '').length >= this.config.amountOfSpecialCharacters;
    }

    public get passwordHintText(): string {
        if (this.value === '') {
            return this.config.hintTextEmpty;
        }
        if (!this.hasRequiredAmountOfLetters) {
            return this.config.hintTextLetters;
        }
        if (!this.hasRequiredAmountOfNumbers) {
            return this.config.hintTextNumbers;
        }
        if (this.hasInvalidSpecialCharacters) {
            return this.replacePlaceholder(
                this.config.hintTextInvalidSpecialCharacters,
                this.specialCharactersWithSpaces
            );
        }
        if (!this.hasRequiredAmountOfSpecialCharacters) {
            return this.replacePlaceholder(this.config.hintTextSpecialCharacters, this.specialCharactersWithSpaces);
        }
        if (this.hasRequiredLength) {
            return this.config.hintTextOk;
        }

        return '';
    }

    public get specialCharactersWithSpaces(): string {
        return [...this.config.specialCharacters].join(' ');
    }

    public replacePlaceholder(text: string, textToInsert: string): string {
        return text.replace('#', textToInsert);
    }

    public replacePlaceholders(text: string, number1: number, number2: number): string {
        return text.replace('#1', number1.toString()).replace('#2', number2.toString());
    }

    public toggleVisiblePassword(): void {
        if (this.inputType === 'text') {
            this.inputType = 'password';
            this.icon = this.iconEyeOpen;
        } else {
            this.inputType = 'text';
            this.icon = this.iconEyeClosed;
        }
    }
}
