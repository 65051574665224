import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconGeneralIcon } from '../../common';
import { ExplanationArrowPosition } from './explanation-arrow.position';
import { ExplanationArrowType } from './explanation-arrow.type';

@Component({
    selector: 'wuc-explanation-arrow',
    templateUrl: 'explanation-arrow.component.html',
    styleUrls: [
        'explanation-arrow.component.scss',
        'explanation-arrow-primary.component.scss',
        'explanation-arrow-secundary.component.scss',
        'explanation-arrow-tertiary.component.scss',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WucExplanationArrowComponent {
    @Input() public position: ExplanationArrowPosition = 'topLeft';
    @Input() public type: ExplanationArrowType = 'primary';
    @Input() public fontSize: 'normal' | 'small' = 'normal';
    @Input() public text: string = '';
    @Input() public isFullWidth: boolean = false;

    public get icon(): IconGeneralIcon {
        switch (this.type) {
        case 'primary':
            switch (this.position) {
            case 'topLeft':
                return 'arrowRR';
            case 'topRight':
                return 'arrowLBL';
            case 'bottomLeft':
                return 'arrowROR';
            case 'bottomRight':
                return 'arrowLOL';
            }
            /* istanbul ignore next */
            break;
        case 'secondary':
        case 'tertiary':
            switch (this.position) {
            case 'topLeft':
                return 'arrowRO';
            case 'topRight':
                return 'arrowLO';
            case 'bottomLeft':
                return 'arrowRB';
            case 'bottomRight':
                return 'arrowLB';
            }
        }
    }

    public get size(): 'xl' | 'm' {
        switch (this.type) {
        case 'primary':
            return 'xl';
        case 'secondary':
        case 'tertiary':
            return 'm';
        }
    }

    public get iconPositionClass(): string {
        return this.position.replace(/([A-Z])/g, '-$1').toLowerCase();
    }
}
