import { CurrencyPipe } from '@angular/common';
import { Component, ElementRef, Input, Optional, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { WucInputBaseComponent } from '../input-base.component';
import { InputInterface } from '../input.interface';

@Component({
    selector: 'wuc-input-currency',
    templateUrl: 'input-currency.component.html',
    styleUrls: ['input-currency.component.scss'],
    // eslint-disable-next-line
    inputs: WucInputBaseComponent.inputs,
    providers: [{ provide: WucInputBaseComponent, useExisting: WucInputCurrencyComponent }],
})
export class WucInputCurrencyComponent extends WucInputBaseComponent implements ControlValueAccessor, InputInterface {
    @ViewChild('input') private inputElement!: ElementRef<HTMLInputElement>;

    @Input() public placeholder: string = '';
    @Input() public isPlaceholderSmall: boolean = false;
    @Input() public locales: string = 'nl-NL';
    @Input() public currency: string = 'EUR';

    public isDisabled: boolean = false;
    // To format the visible value to € 12,34 value must be a string.
    public value?: string;
    public onChange!: (value: number | null) => void;
    public onTouched!: () => void;
    // Match everything but digits and comma
    public invalidPattern: RegExp = /[^0-9,]/g;

    constructor(@Optional() public override ngControl: NgControl, public currencyPipe: CurrencyPipe) {
        super(ngControl);
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    public override setFocus(): void {
        this.inputElement.nativeElement.focus();
    }

    // Update value from API; API => view
    public writeValue(value: number): void {
        this.value = this.formatCurrency(value);
    }

    // Update value from view 'on change'; View => API
    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    // Update value from view 'on blur'; View => API
    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    public onInput(event: Event): void {
        let value = (event.target as HTMLInputElement).value;
        if (this.isInvalidInput(value)) {
            value = value.replace(this.invalidPattern, '');
        }

        this.onChange(this.createValidOnChangeValue(value));
    }

    public onBlur(value: string): void {
        if (this.isInvalidInput(value)) {
            value = value.replace(this.invalidPattern, '');
        }

        const trimmedNumber = this.createValidOnChangeValue(value);
        this.value = this.formatCurrency(trimmedNumber);
        this.onTouched();
    }

    // Comma is not a valid number; not 00,00 but 00.00.
    private createValidNumber(value: string): number {
        const replacedValue = value.replace(',', '.');
        return Number(replacedValue);
    }

    // This must be a number with dot and max two decimals
    private createValidOnChangeValue(value: string): number | null {
        return this.createValidNumber(value);
    }

    private isInvalidInput(value: string): boolean {
        if (value.length === 0) {
            return true;
        }

        if (this.hasMultipleCommas(value)) {
            return true;
        }

        return this.invalidPattern.test(value);
    }

    private formatCurrency(value: number | null): string {
        if (value === null) {
            return null as any;
        }
        return new Intl.NumberFormat(this.locales, { style: 'currency', currency: this.currency }).format(value);
    }

    private hasMultipleCommas(value: string): boolean {
        let commaCount = 0;
        const splitValue = value.split('');

        splitValue.forEach((character) => (character === ',' ? (commaCount = commaCount + 1) : null));

        return commaCount > 1;
    }
}
