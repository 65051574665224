import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucSkeletonModule } from '../skeleton';
import { WucRatingComponent } from './rating.component';

@NgModule({
    declarations: [WucRatingComponent],
    imports: [CommonModule, WucSkeletonModule],
    exports: [WucRatingComponent],
})
export class WucRatingModule {}
