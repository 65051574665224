import {
    AfterContentInit,
    Component,
    ContentChild,
    ContentChildren,
    ElementRef,
    Input,
    QueryList,
} from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { WucFormErrorComponent } from '../form-error';
import { WucFormFieldLabelComponent } from '../form-field-label';
import { WucInputBaseComponent } from '../input-base.component';
import { InputInterface } from '../input.interface';

@Component({
    selector: 'wuc-form-field',
    templateUrl: 'form-field.component.html',
    styleUrls: ['form-field.component.scss'],
    viewProviders: [
        {
            provide: ControlContainer,
            useExisting: FormGroupDirective,
        },
    ],
})
export class WucFormFieldComponent implements AfterContentInit {
    @ContentChildren(WucFormFieldLabelComponent, { descendants: true })
    public labels!: QueryList<WucFormFieldLabelComponent>;

    @ContentChildren(WucInputBaseComponent, { descendants: true })
    public inputs!: QueryList<InputInterface>;

    @ContentChildren(WucInputBaseComponent, { read: ElementRef })
    public inputElements!: QueryList<ElementRef>;

    @ContentChild(WucFormErrorComponent)
    public error!: WucFormErrorComponent;

    @Input() public name?: string;
    @Input() public hasNoState: boolean = false;

    /**
     * @deprecated The label is always placed .8rem from the top
     */
    @Input() public labelVerticalAlign: 'top' | 'center' = 'center';

    public inputInterfaces: InputInterface[] = [];

    constructor(public parent: FormGroupDirective) {}

    public ngAfterContentInit(): void {
        this.inputInterfaces = this.inputs.toArray();

        if (!this.name || !this.inputs.length) {
            return;
        }

        if (this.labels.length) {
            this.labels.first.for = this.name;
        }
        this.inputs.first.name = this.name;
    }
}
