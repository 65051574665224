import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NativeRouterLinkActiveModule } from '../../../core';
import { WucIconUiModule } from '../../common';
import { WucNavigationItemComponent } from './navigation-item.component';

@NgModule({
    imports: [CommonModule, RouterModule, WucIconUiModule, NativeRouterLinkActiveModule],
    declarations: [WucNavigationItemComponent],
    exports: [WucNavigationItemComponent],
})
export class WucNavigationItemModule {}
