<div
  class="wuc-balloon"
  [ngClass]="[
    'wuc-balloon--background-' + backgroundStyle + (borderStyle === 'dashed' ? '-dashed' : ''),
    'wuc-balloon--' + (arrowSide$ | async)
  ]"
  [style.min-width]="minWidth || null"
>
  <div
    #balloonContent
    class="wuc-balloon__content"
    [class.wuc-balloon__content--padding-small]="padding === 'small'"
    [class.wuc-balloon__content--padding-medium]="padding === 'medium'"
    [class.wuc-balloon__content--padding-large]="padding === 'large'"
  >
    <div class="wuc-balloon__content-inner">
      <ng-content></ng-content>
    </div>
    <wuc-icon-ui
      *ngIf="hasCloseIcon"
      class="wuc-balloon__close-icon"
      icon="cross-closed"
      (click)="onCloseIconClick($event)"
    ></wuc-icon-ui>
  </div>
  <div #balloonArrow class="wuc-balloon__arrow" [ngClass]="'wuc-balloon__arrow--' + (arrowPosition$ | async)"></div>
</div>
