import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucIconComponent } from './icon.component';

@NgModule({
    imports: [CommonModule],
    declarations: [WucIconComponent],
    exports: [WucIconComponent],
})
export class WucIconModule {}
