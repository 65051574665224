import { Component, Input } from '@angular/core';
import { ImageSizeInterface } from './image-size.interface';

@Component({
    selector: 'wuc-picture',
    templateUrl: 'picture.component.html',
    styleUrls: ['picture.component.scss'],
})
export class WucPictureComponent {
    @Input() public path: string = '';
    @Input() public imageDesktop: string = '';
    @Input() public imageTablet?: string;
    @Input() public imageMobile?: string;
    @Input() public alt: string = '';
    @Input() public title: string = '';
    @Input() public imageType: 'image/gif' | 'image/jpeg' | 'image/png' | 'image/svg+xml' | 'image/webp' =
        'image/svg+xml';

    @Input() public imageWidth?: ImageSizeInterface;
    @Input() public imageHeight?: ImageSizeInterface;

    @Input() public lazyLoad: boolean = true;
}
