<div
  class="wuc-input-radio wuc-input-container"
  [attr.data-testid]="testId"
  [class.wuc-input-radio--disabled]="isDisabled"
  [class.wuc-input-container--is-child-of-input]="isChildOfInput"
>
  <input
    #input
    [attr.tabindex]="0"
    class="wuc-input-radio__input wuc-input"
    type="radio"
    [attr.id]="inputId"
    [name]="name || ''"
    [value]="value"
    [attr.aria-label]="ariaLabel"
    [disabled]="isDisabled"
    [(ngModel)]="formValue"
    (click)="onInputClick($event)"
    (ngModelChange)="onValueChange($event)"
  />
  <label class="wuc-input-radio__label" [class.wuc-input-radio__label--bold]="boldLabel" [attr.for]="inputId">
    <span>
      <ng-content></ng-content>
    </span>
    <span *ngIf="info.children.length" class="wuc-input-radio__info-toggle" (click)="onInfoClick($event)">
      <wuc-icon-ui icon="information" type="primary" size="xs"></wuc-icon-ui>
    </span>
  </label>
</div>
<div #info class="wuc-input-radio-info" [class.wuc-input-radio-info--hidden]="!infoTextVisible">
  <ng-content select="[slot=info]"></ng-content>
</div>
