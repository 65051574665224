import { DOCUMENT } from '@angular/common';
import {
    AfterViewInit,
    Component,
    ContentChild,
    ElementRef,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ArrowPositionType, WucBalloonComponent } from '../balloon';
import { WucButtonComponent } from '../button';
import { IconUiIcon } from '../icon-ui';
import { DistancesInterface } from './distances.interface';

@Component({
    selector: 'wuc-drop-trigger',
    templateUrl: 'drop-trigger.component.html',
    styleUrls: ['drop-trigger.component.scss'],
})
export class WucDropTriggerComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    @Input() public position: 'top' | 'bottom' = 'bottom';
    @Input() public balloonHorizontalAlignment: 'left' | 'center' | 'right' = 'center';
    @Input() public container?: HTMLElement;
    @Input() public openOnClick: boolean = false;
    @Input() public fullWidth: boolean = false;

    private _balloonHasCloseIcon: boolean = false;
    @Input()
    public set balloonHasCloseIcon(hasCloseIcon: boolean) {
        this._balloonHasCloseIcon = hasCloseIcon;
        if (this.balloon) {
            this.balloon.hasCloseIcon = this._balloonHasCloseIcon;
        }
    }

    public get balloonHasCloseIcon(): boolean {
        return this._balloonHasCloseIcon;
    }

    @ContentChild(WucButtonComponent)
    private trigger!: WucButtonComponent;

    @ContentChild(WucBalloonComponent)
    private balloon!: WucBalloonComponent;

    @ViewChild('dropTrigger', { read: ElementRef })
    private dropTriggerElementRef!: ElementRef;

    @ViewChild('drop', { read: ElementRef })
    private dropElementRef!: ElementRef;

    public isDropVisible: boolean = false;

    private iconUp: IconUiIcon = 'arrow-up-bold';
    private iconDown: IconUiIcon = 'arrow-down-bold';
    private subscriptions: Subscription = new Subscription();

    constructor(private elementRef: ElementRef, @Inject(DOCUMENT) private document: Document) {}

    public ngOnInit(): void {
        this.subscriptions.add(
            fromEvent<Event>(this.document.defaultView as Window, 'resize')
                .pipe(filter(() => this.isDropVisible))
                .subscribe(() => this.hide())
        );
    }

    public ngAfterViewInit(): void {
        if (this.trigger) {
            this.subscriptions.add(
                this.trigger.buttonClick.subscribe({
                    next: (event: Event) => {
                        this.onToggle();
                        event.stopPropagation();
                    },
                })
            );
        }
        if (this.balloon) {
            this.balloon.hasCloseIcon = this.balloonHasCloseIcon;
            this.subscriptions.add(
                this.balloon.closeClicked.subscribe({
                    next: (event: Event) => {
                        this.hide();
                        event.stopPropagation();
                    },
                })
            );
        }

        this.moveDropIntoContainer();

        setTimeout(() => {
            this.setButtonIcon();
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['container']) {
            this.moveDropIntoContainer();
        }
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private moveDropIntoContainer(): void {
        if (this.dropTriggerElementRef && this.dropElementRef) {
            if (this.container) {
                this.container.appendChild(this.dropElementRef.nativeElement);
            } else {
                this.dropTriggerElementRef.nativeElement.appendChild(this.dropElementRef.nativeElement);
            }
        }
    }

    public onToggle(): void {
        this.setVisibility(!this.isDropVisible);
    }

    public show(): void {
        this.setVisibility(true);
    }

    public hide(): void {
        this.setVisibility(false);
    }

    public onMouseEnter(): void {
        if (!this.openOnClick) {
            this.show();
        }
    }

    public onMouseLeave(): void {
        if (!this.openOnClick) {
            this.hide();
        }
    }

    private setVisibility(isVisible: boolean): void {
        if (this.isDropVisible !== isVisible) {
            this.isDropVisible = isVisible;
            this.positionDrop();
        }
        this.setButtonIcon();
    }

    private setButtonIcon(): void {
        if (this.trigger && this.trigger.type !== 'clean') {
            this.trigger.iconRight = this.isDropVisible ? this.iconUp : this.iconDown;
        }
    }

    private positionDrop(): void {
        if (!this.balloon || !this.isDropVisible) {
            return;
        }

        const dropTriggerElement: HTMLElement = this.dropTriggerElementRef.nativeElement;
        const dropElement: HTMLElement = this.dropElementRef.nativeElement;
        const thisRect: DOMRect = this.elementRef.nativeElement.getBoundingClientRect();
        const dropTriggerRect: DOMRect = dropTriggerElement.getBoundingClientRect();
        const dropRect: DOMRect = dropElement.getBoundingClientRect();

        const dropElementStyle: CSSStyleDeclaration = this.dropElementRef.nativeElement.style;

        // let's keep the set position untouched
        let position = this.position;

        const horizontalArrowPosition = this.balloon.arrowPosition.replace(/^[a-z]+/, '');

        if (this.container) {
            const containerRect: DOMRect = this.container.getBoundingClientRect();
            const distances: DistancesInterface = WucDropTriggerComponent.getRelativeDistances(
                this.container,
                dropTriggerElement
            );

            // top overflow
            if (position === 'top' && distances.top < dropRect.height) {
                position = 'bottom';
            }
            // bottom overflow
            if (position === 'bottom' && distances.bottom < dropRect.height) {
                position = 'top';
            }

            if (!dropElementStyle.minWidth) {
                dropElementStyle.minWidth = `${dropRect.width}px`;
            }

            // position drop relative to trigger
            dropElementStyle.left = `${distances.left + dropTriggerRect.width / 2}px`;
            if (position === 'top') {
                dropElementStyle.top = 'unset';
                dropElementStyle.bottom = `${distances.bottom + dropTriggerRect.height}px`;
            } else {
                dropElementStyle.top = `${dropTriggerRect.bottom - containerRect.top}px`;
            }

            if (this.balloon) {
                // left overflow
                const leftOverflow = Math.max(
                    0,
                    containerRect.left - (thisRect.left - (dropRect.width - thisRect.width) / 2)
                );
                if (leftOverflow) {
                    dropElementStyle.transform = 'unset';
                    dropElementStyle.left = '0';
                    this.balloon.setArrowLeft(thisRect.width / 2 + distances.left);
                }
                // right overflow
                const rightOverflow = Math.max(
                    0,
                    thisRect.right + (dropRect.width - thisRect.width) / 2 - containerRect.right
                );
                if (rightOverflow) {
                    dropElementStyle.transform = 'unset';
                    dropElementStyle.left = 'unset';
                    dropElementStyle.right = '0';
                    this.balloon.setArrowRight(thisRect.width / 2 + distances.right);
                }

                // if no overflow, reset position and arrow
                if (!leftOverflow && !rightOverflow) {
                    dropElementStyle.transform = 'translateX(-50%)';
                    dropElementStyle.right = 'unset';
                    this.balloon.resetArrowOffset();
                }
            }
        } else {
            if (position === 'top') {
                dropElementStyle.top = `${-dropRect.height}px`;
            } else {
                dropElementStyle.removeProperty('top');
            }
            const arrowOffset = Math.max(thisRect.width / 2, 20);
            switch (this.balloonHorizontalAlignment) {
            case 'left':
                dropElementStyle.transform = 'unset';
                dropElementStyle.left = arrowOffset > 20 ? '0' : '-1rem';
                dropElementStyle.removeProperty('right');
                if (horizontalArrowPosition.toLowerCase() === 'middle') {
                    this.balloon.setArrowLeft(arrowOffset);
                } else {
                    this.balloon.resetArrowOffset();
                }
                break;
            case 'right':
                dropElementStyle.transform = 'unset';
                dropElementStyle.left = 'unset';
                dropElementStyle.right = arrowOffset > 20 ? '0' : '-1rem';
                if (horizontalArrowPosition.toLowerCase() === 'middle') {
                    this.balloon.setArrowRight(arrowOffset);
                } else {
                    this.balloon.resetArrowOffset();
                }
                break;
            default:
                dropElementStyle.removeProperty('transform');
                dropElementStyle.removeProperty('left');
                dropElementStyle.removeProperty('right');
                this.balloon.resetArrowOffset();
                break;
            }
        }

        // set position of arrow
        const verticalArrowPosition = position === 'top' ? 'bottom' : 'top';
        this.balloon.arrowPosition = `${verticalArrowPosition}${horizontalArrowPosition}` as ArrowPositionType;
    }

    private static getRelativeDistances(parent: HTMLElement, child: HTMLElement): DistancesInterface {
        const childRect = child.getBoundingClientRect();
        const parentRect = parent.getBoundingClientRect();

        return {
            top: childRect.top - parentRect.top,
            left: childRect.left - parentRect.left,
            right: parentRect.right - childRect.right,
            bottom: parentRect.bottom - childRect.bottom,
        };
    }
}
