<div class="wuc-tip-card">
  <div class="wuc-tip-card__header">
    <ng-content select="[slot=header]"></ng-content>
  </div>
  <div class="wuc-tip-card__body">
    <ng-content select="[slot=body]"></ng-content>
  </div>
  <div class="wuc-tip-card__footer">
    <ng-content select="[slot=footer]"></ng-content>
  </div>
  <div class="wuc-tip-card__image" [class.wuc-tip-card__image--max-image-size]="!noMaxImageSize">
    <ng-content select="[slot=image]"></ng-content>
  </div>
</div>
