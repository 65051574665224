import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BackgroundColorType } from '../../../core';
import { CompareTableInsurancesDataInterface } from './compare-table-insurances.data.interface';

@Component({
    selector: 'wuc-compare-table-insurances',
    templateUrl: 'compare-table-insurances.component.html',
    styleUrls: ['compare-table-insurances.component.scss'],
})
export class WucCompareTableInsurancesComponent {
    @Input() public isMobileView: boolean = false;
    @Input() public dataColumnsInMobileView: number = 2;
    @Input() public tableBackgroundColor: BackgroundColorType = 'light';
    @Input() public labelColumnBackgroundColor: BackgroundColorType = 'dark';
    @Input() public dataColumnBackgroundColor: BackgroundColorType = 'light';
    @Input() public data?: CompareTableInsurancesDataInterface;

    constructor(private router: Router) {}

    public onActionClick(commands: string[]): void {
        this.router.navigate(commands);
    }
}
