<div
  class="wuc-input-container wuc-input-container--border"
  [class.wuc-input-container--has-error]="hasError"
  [class.wuc-input-container--disabled]="disabled"
>
  <input
    #input
    type="text"
    class="wuc-input"
    [attr.autocomplete]="autocomplete"
    [attr.inputmode]="inputmode"
    [attr.aria-label]="ariaLabel"
    [attr.id]="name ?? null"
    [attr.e2e]="e2e ?? null"
    maxlength="18"
    [placeholder]="placeholder"
    [value]="value"
    (input)="onInput(input.value)"
    (beforeinput)="onBeforeInput($event)"
    (blur)="onTouched()"
    (keyup)="updateInputmode()"
    (click)="updateInputmode()"
    [disabled]="disabled"
  />
</div>
