import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucScribbleCircleModule } from '../scribble-circle';
import { WucCardComponent } from './card.component';

@NgModule({
    declarations: [WucCardComponent],
    imports: [WucScribbleCircleModule, CommonModule],
    exports: [WucCardComponent],
})
export class WucCardModule {}
