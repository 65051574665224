import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WucFormFieldStateModule } from '../form-field-state';
import { WucFormFieldComponent } from './form-field.component';

@NgModule({
    imports: [FormsModule, ReactiveFormsModule, CommonModule, WucFormFieldStateModule],
    providers: [FormGroupDirective],
    declarations: [WucFormFieldComponent],
    exports: [WucFormFieldComponent],
})
export class WucFormFieldModule {}
