import { Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { WucChatbotAnswerInterface, WucChatbotDialogOptionInterface } from '../chatbot-answer';
import { WucChatbotItemEnum } from './chatbot-list.interface';

@Component({
    selector: 'wuc-chatbot-list',
    templateUrl: 'chatbot-list.component.html',
    styleUrls: ['chatbot-list.component.scss'],
})
export class WucChatbotListComponent {
    @ViewChild('list') private listElement!: ElementRef;
    @ViewChildren('listItemQuestion') private listItemElements!: QueryList<ElementRef>;

    @Input() public items: (WucChatbotAnswerInterface | string)[] = [];
    @Input() public isMobile: boolean = false;

    @Output() public readonly dialogOption: EventEmitter<WucChatbotDialogOptionInterface> =
        new EventEmitter<WucChatbotDialogOptionInterface>();

    public wucChatbotItemEnum = WucChatbotItemEnum;

    public itemType(item: WucChatbotAnswerInterface | string): WucChatbotItemEnum {
        return typeof item === 'string' ? WucChatbotItemEnum.Question : WucChatbotItemEnum.Answer;
    }

    public asAnswer(item: WucChatbotAnswerInterface | string): WucChatbotAnswerInterface {
        return item as WucChatbotAnswerInterface;
    }

    public onDialogOption(dialogOption: WucChatbotDialogOptionInterface): void {
        this.dialogOption.emit(dialogOption);
    }

    public get lastItemElementRef(): ElementRef | null {
        return this.listItemElements?.length ? this.listItemElements.last : null;
    }
}
