import { Component, ContentChildren, ElementRef, Input, Optional, QueryList, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { WucInputBaseComponent } from '../input-base.component';
import { InputInterface } from '../input.interface';
import { WucSelectOptionComponent } from '../select-option';

@Component({
    selector: 'wuc-select',
    templateUrl: 'select.component.html',
    styleUrls: ['select.component.scss'],
    // eslint-disable-next-line
    inputs: WucInputBaseComponent.inputs,
    providers: [
        {
            provide: WucInputBaseComponent,
            useExisting: WucSelectComponent,
        },
    ],
})
export class WucSelectComponent extends WucInputBaseComponent implements ControlValueAccessor, InputInterface {
    @ViewChild('select', { static: true }) private select!: ElementRef;
    @Input() public placeholder: string = '';

    @ContentChildren(WucSelectOptionComponent)
    public options!: QueryList<WucSelectOptionComponent>;

    public isDisabled: boolean = false;
    public value?: string | {};
    public onChange!: (value: string | {}) => void;
    public onTouched!: () => void;

    constructor(@Optional() public override ngControl: NgControl) {
        super(ngControl);
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    // Update value from API; API => view
    public writeValue(value: string | {}): void {
        this.value = value;
    }

    // Update value from view "on change"; View => API
    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    // Update value from view "on blur"; View => API
    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    // Set disabled state from API
    public setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }
}
