import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucBalloonModule, WucCardModule, WucIconModule, WucLinkModule } from '../../common';
import { WucTabsetItemModule } from '../tabset-item';
import { WucTabsetComponent } from './tabset.component';

@NgModule({
    declarations: [WucTabsetComponent],
    imports: [CommonModule, WucTabsetItemModule, WucIconModule, WucBalloonModule, WucCardModule, WucLinkModule],
    exports: [WucTabsetComponent],
})
export class WucTabsetModule {}
