import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucBalloonModule, WucIconUiModule } from '../../common';
import { WucFormErrorComponent } from './form-error.component';

@NgModule({
    imports: [CommonModule, WucBalloonModule, WucIconUiModule],
    declarations: [WucFormErrorComponent],
    exports: [WucFormErrorComponent],
})
export class WucFormErrorModule {}
