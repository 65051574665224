import { Component, ElementRef, Input, Optional, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { WucInputBaseComponent } from '../input-base.component';
import { InputInterface } from '../input.interface';

@Component({
    selector: 'wuc-input-number',
    templateUrl: 'input-number.component.html',
    styleUrls: ['input-number.component.scss'],
    // eslint-disable-next-line
    inputs: WucInputBaseComponent.inputs,
    providers: [{ provide: WucInputBaseComponent, useExisting: WucInputNumberComponent }],
})
export class WucInputNumberComponent extends WucInputBaseComponent implements ControlValueAccessor, InputInterface {
    @ViewChild('input') private inputElement!: ElementRef<HTMLInputElement>;

    @Input() public placeholder: string = '';
    @Input() public isPlaceholderSmall: boolean = false;

    public isDisabled: boolean = false;
    public value?: string;
    public onChange!: (value: string) => void;
    public onTouched!: () => void;

    constructor(@Optional() public override ngControl: NgControl) {
        super(ngControl);
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    // Update value from API; API => view
    public writeValue(value: string): void {
        this.value = value;
    }

    // Update value from view "on change"; View => API
    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    // Update value from view "on blur"; View => API
    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    // Set disabled state from API
    public setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }
}
