import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IconGeneralIcon, IconGeneralType } from '../icon';

@Component({
    selector: 'wuc-alert',
    templateUrl: 'alert.component.html',
    styleUrls: ['alert.component.scss'],
})
export class WucAlertComponent {
    @Input() public icon?: IconGeneralIcon;
    @Input() public title: string = '';
    @Input() public text!: string;
    @Input() public isClosable: boolean = false;
    @Input() public orientation: 'horizontal' | 'vertical' = 'horizontal';
    @Input() public type: 'primary' | 'secondary' | 'neutral' = 'primary';
    @Input() public iconType: IconGeneralType = 'primary';
    @Output() public closeClicked: EventEmitter<Event> = new EventEmitter<Event>();

    public onCloseIconClick(event: Event): void {
        this.closeClicked.emit(event);
    }
}
