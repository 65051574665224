import { Component, Input, TemplateRef, ViewChild } from '@angular/core';

@Component({
    standalone: true,
    selector: 'wuc-display-table-value',
    templateUrl: 'display-table-value.component.html',
    styleUrls: ['display-table-value.component.scss'],
})
export class WucDisplayTableValueComponent {
    @Input()
    public isBold = false;

    @ViewChild('displayTableValue', { read: TemplateRef })
    public templateRef!: TemplateRef<HTMLElement>;
}
