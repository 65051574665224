import { Inject, Injectable } from '@angular/core';
import Pikaday from 'pikaday';
import { PikadayConfigInterface } from './pikaday-config.interface';

@Injectable()
export class PikadayService {
    constructor(@Inject('pikadayConfig') private config: PikadayConfigInterface) {}

    public async getInstance(options: Pikaday.PikadayOptions): Promise<Pikaday> {
        return await import('pikaday').then((pikaday) => {
            return new pikaday.default({
                format: this.config.format,
                i18n: this.config.i18n,
                ...options,
            });
        });
    }

    public get placeholder(): string {
        return this.config.placeholder;
    }

    public get placeholderSelect(): string {
        return this.config.placeholderSelect || this.placeholder;
    }
}
