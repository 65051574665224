<div class="wuc-alphabet-filter" [class.wuc-alphabet-filter--mobile]="isMobile" *ngIf="items?.length">
  <span class="wuc-alphabet-filter__item" *ngFor="let item of items">
    <wuc-link
      *ngIf="!item.isDisabled"
      type="primary"
      [linkTitle]="item.character"
      [route]="(item.linkType || defaultLinkType) === 'internal-link' ? item.linkData || null : null"
      [url]="(item.linkType || defaultLinkType) === 'external-link' ? item.linkData || null : null"
      (clicked)="onItemClick(item)"
    >
      <span class="wuc-alphabet-filter__item-text">
        {{ item.character }}
      </span>
    </wuc-link>
    <span
      *ngIf="item.isDisabled"
      [title]="item.character"
      class="wuc-alphabet-filter__item-text wuc-alphabet-filter__item-text--disabled"
    >
      {{ item.character }}
    </span>
  </span>
</div>
