import { ChangeDetectorRef, Component, TemplateRef, ViewChild } from '@angular/core';
import { WucListOptionsInterface } from './list-options.interface';

@Component({
    template: '',
})
export class WucListItemBaseComponent {
    @ViewChild('wucListItem', { read: TemplateRef }) public listItemTemplateRef!: TemplateRef<HTMLElement>;

    private _listOptions!: WucListOptionsInterface;

    public set listOptions(options: WucListOptionsInterface) {
        this._listOptions = options;
        this.changeDetectorRef.detectChanges();
    }

    public get listOptions(): WucListOptionsInterface {
        return this._listOptions;
    }

    constructor(public changeDetectorRef: ChangeDetectorRef) {}
}
