import { Component, Input } from '@angular/core';
import { IconUiIcon } from '../../common';

@Component({
    selector: 'wuc-input-alert',
    templateUrl: 'input-alert.component.html',
    styleUrls: ['input-alert.component.scss'],
})
export class WucInputAlertComponent {
    @Input() public alertType: 'error' | 'warning' = 'error';
    @Input() public messages?: string[];
    @Input() public icon?: IconUiIcon;
}
