import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WucIconUiModule } from '../../common';
import { WucFormFieldStateModule } from '../form-field-state';
import { WucInputTimeComponent } from './input-time.component';

@NgModule({
    imports: [FormsModule, ReactiveFormsModule, CommonModule, WucFormFieldStateModule, WucIconUiModule],
    declarations: [WucInputTimeComponent],
    exports: [WucInputTimeComponent],
})
export class WucInputTimeModule {}
