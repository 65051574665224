import { NgClass, NgIf } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucListIconItemComponent } from './list-icon-item.component';

@NgModule({
    imports: [NgIf, NgClass],
    declarations: [WucListIconItemComponent],
    exports: [WucListIconItemComponent],
})
export class WucListIconItemModule {}
