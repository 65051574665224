import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucSafeHtmlModule } from '../../../shared';
import { WucIconModule } from '../icon';
import { WucIconUiModule } from '../icon-ui';
import { WucAlertComponent } from './alert.component';

@NgModule({
    declarations: [WucAlertComponent],
    imports: [WucIconModule, WucIconUiModule, CommonModule, WucSafeHtmlModule],
    exports: [WucAlertComponent],
})
export class WucAlertModule {}
