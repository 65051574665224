import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { WucListItemBaseComponent } from '../list/list-item-base.component';

@Component({
    selector: 'wuc-list-icon-item',
    templateUrl: 'list-icon-item.component.html',
    styleUrls: ['list-icon-item.component.scss'],
    providers: [{ provide: WucListItemBaseComponent, useExisting: WucListIconItemComponent }],
})
export class WucListIconItemComponent extends WucListItemBaseComponent {
    @Input() public noPadding: boolean = false;

    constructor(public override changeDetectorRef: ChangeDetectorRef) {
        super(changeDetectorRef);
    }
}
