import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucIconModule } from '../icon/icon.module';
import { WucSkeletonModule } from '../skeleton';
import { WucPageBlockComponent } from './page-block.component';

@NgModule({
    declarations: [WucPageBlockComponent],
    imports: [WucIconModule, CommonModule, WucSkeletonModule],
    exports: [WucPageBlockComponent],
})
export class WucPageBlockModule {}
