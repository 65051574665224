<div class="wuc-chatbot-input" [class.wuc-chatbot-input--mobile]="isMobile">
  <div class="wuc-chatbot-input__input">
    <wuc-input-text
      #input
      [placeholder]="placeholder"
      [isPlaceholderSmall]="true"
      [formControl]="inputControl"
      [maxNumberOfCharacters]="maxNumberOfCharacters"
      [maxNumberOfCharactersText]="maxNumberOfCharactersText"
      [maxNumberOfCharactersPositionOutside]="isMobile"
    ></wuc-input-text>
    <wuc-button
      type="primary"
      iconRight="arrow-right-bold"
      [disabled]="isDisabled || inputControl.invalid"
      (buttonClick)="onSend()"
    >
      <div *ngIf="!isMobile">{{ buttonText }}</div>
    </wuc-button>
  </div>
  <div class="wuc-chatbot-input__footer">
    <ng-content select="[slot=footer]"></ng-content>
  </div>
</div>
