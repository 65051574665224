import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucSafeHtmlModule } from '../../../shared';
import { WucIconUiModule } from '../icon-ui';
import { WucPictureModule } from '../picture';
import { WucVideoComponent } from './video.component';

@NgModule({
    imports: [CommonModule, WucPictureModule, WucIconUiModule, WucSafeHtmlModule],
    declarations: [WucVideoComponent],
    exports: [WucVideoComponent],
})
export class WucVideoModule {}
