<div class="wuc-accordion"
     [class.wuc-accordion--primary]="accordionStyle === 'primary'"
     [class.wuc-accordion--tertiary]="accordionStyle === 'tertiary'"
     [class.wuc-accordion--quaternary]="accordionStyle === 'quaternary'"
     [class]="
    ' wuc-accordion--radius-' +
    radius +
    ' wuc-accordion--padding-' +
    padding
    ">
  <ng-content></ng-content>
</div>
