import { InputPasswordConfigInterface } from './input-password-config.interface';

export const inputPasswordDefaultConfig: InputPasswordConfigInterface = {
    amountOfLetters: 2,
    amountOfNumbers: 1,
    amountOfSpecialCharacters: 1,
    specialCharacters: '@.-?+!#&=',
    minLength: 8,
    maxLength: 40,
    passwordContainsText: 'Does your password contain:',
    amountOfLettersText: 'At least # letters',
    amountOfNumbersText: 'At least # numbers',
    passwordLengthText: '#1 or more characters (and no more than #2)',
    amountOfSpecialCharactersText: 'Strengthen your password with # of these characters',
    hintTextEmpty: 'Do not choose a password that you use for other websites or that are easy to guess.',
    hintTextOk: 'Well done! ☺',
    hintTextLetters: 'Your password is missing a letter',
    hintTextNumbers: 'Your password is missing a number',
    hintTextSpecialCharacters: 'You can make your password even stronger with 1 of these characters: #',
    hintTextInvalidSpecialCharacters:
        'One of the special characters you entered is not possible. ' +
        'You can use the following characters in addition to numbers and letters: #',
};
