import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucSafeHtmlModule } from '../../../shared';
import { WucIconModule } from '../../common';
import { WucExplanationArrowComponent } from './explanation-arrow.component';

@NgModule({
    imports: [CommonModule, WucIconModule, WucSafeHtmlModule],
    declarations: [WucExplanationArrowComponent],
    exports: [WucExplanationArrowComponent],
})
export class WucExplanationArrowModule {}
