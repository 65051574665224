import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { WucChatbotEscalationFeedbackInterface } from './chatbot-escalation-feedback.interface';
import { WucChatbotEscalationInterface } from './chatbot-escalation.interface';
import { Observable, Subscription } from 'rxjs';
import { escalationMessages } from './escalation-messages';

@Component({
    selector: 'wuc-chatbot-escalation',
    templateUrl: 'chatbot-escalation.component.html',
    styleUrls: ['chatbot-escalation.component.scss'],
})
export class WucChatbotEscalationComponent implements OnInit, OnDestroy {
    @Input() public options?: WucChatbotEscalationInterface;
    @Input() public isCompactView: boolean = false;
    @Input() public newQuestion$!: Observable<string>;

    @Output() public readonly feedback: EventEmitter<WucChatbotEscalationFeedbackInterface> =
        new EventEmitter<WucChatbotEscalationFeedbackInterface>();

    @Output() public readonly buttonClick: EventEmitter<void> = new EventEmitter<void>();

    public hasUserMadeUsefulnessChoice: boolean = false;
    public isAnswerUseful: boolean = false;
    public isFeedbackSend: boolean = false;

    private lastQuestion?: string;
    private subscription: Subscription = new Subscription();

    public inputControl: FormControl<string | null> = new FormControl<string>('', Validators.required);

    ngOnInit(): void {
        if (this.newQuestion$) {
            this.subscription.add(this.newQuestion$.subscribe((q) => (this.lastQuestion = q)));
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onUsefulness(useful: boolean): void {
        this.hasUserMadeUsefulnessChoice = true;
        this.isAnswerUseful = useful;
        this.onSend();
        this.buttonClick.emit();
    }

    public onSend(): void {
        const comment: string = this.isAnswerUseful ? escalationMessages.yesClickText : escalationMessages.noClickText;
        this.feedback.emit({
            wasUseful: this.isAnswerUseful,
            comment: this.inputControl.value || comment,
            rating: this.isAnswerUseful ? 5 : 0,
            q: this.lastQuestion,
        });

        if ((this.inputControl.value && !this.isAnswerUseful) || this.isAnswerUseful || !this.options?.feedback) {
            this.isFeedbackSend = true;
            this.inputControl.reset();
        }
    }

    public callCallback(callback?: () => void): void {
        if (callback) {
            callback();
        }
    }
}
