import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BackgroundColorType } from '../../../core';
import { WucCompareTableComponent } from '../compare-table';
import { WucCompareTableCoverageDataActionInterface } from './compare-table-coverage-data-action.interface';
import { WucCompareTableCoverageDataInterface } from './compare-table-coverage-data.interface';

@Component({
    selector: 'wuc-compare-table-coverage',
    templateUrl: 'compare-table-coverage.component.html',
    styleUrls: ['compare-table-coverage.component.scss'],
})
export class WucCompareTableCoverageComponent implements OnChanges {
    @Input() public isMobileView: boolean = false;
    @Input() public isHoverBorderVisible: boolean = false;
    @Input() public hasCursorPointer: boolean = false;
    @Input() public tableBackgroundColor: BackgroundColorType = 'light';
    @Input() public labelColumnBackgroundColor: BackgroundColorType = 'dark';
    @Input() public dataColumnBackgroundColor: BackgroundColorType = 'light';
    @Input() public data?: WucCompareTableCoverageDataInterface;
    @Input() public selectedColumnKey?: string;
    @Input() public testIdPrefix?: string;

    @Output() public clicked = new EventEmitter<string>();
    @Output() public action = new EventEmitter<string>();

    @ViewChild('compareTable') private compareTable!: WucCompareTableComponent;

    constructor(private router: Router) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['data']?.previousValue) {
            this.compareTable.reInitialize();
        }
    }

    public onButtonClick(columnKey: string, action: WucCompareTableCoverageDataActionInterface): void {
        if (action.routerLink) {
            this.router.navigate([action.routerLink]);
        } else if (action.href) {
            window.open(action.href, '_blank');
        } else if (action.callback) {
            action.callback();
        }
    }

    public onLinkClick(action: WucCompareTableCoverageDataActionInterface): void {
        if (!action.isDisabled && !action.routerLink && !action.href && action.callback) {
            action.callback();
        }
    }

    public onTableClick(columnKey: string): void {
        this.clicked.emit(columnKey);
    }

    public redraw(): void {
        this.compareTable.redraw();
    }
}
