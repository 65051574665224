import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { WucClickOutsideModule } from '../../../core';
import { WucHighlightModule } from '../../../shared';
import { WucIconUiModule } from '../../common';
import { WucFormFieldStateModule } from '../form-field-state';
import { WucSelectAutocompleteComponent } from './select-autocomplete.component';

@NgModule({
    imports: [
        WucFormFieldStateModule,
        CommonModule,
        FormsModule,
        WucHighlightModule,
        WucIconUiModule,
        WucClickOutsideModule,
    ],
    declarations: [WucSelectAutocompleteComponent],
    exports: [WucSelectAutocompleteComponent],
})
export class WucSelectAutocompleteModule {}
