import {
    Component,
    ContentChildren,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { delay, filter, startWith } from 'rxjs/operators';
import { IconUiIcon, WucLinkComponent } from '../../common';

@Component({
    selector: 'wuc-navigation-item',
    templateUrl: 'navigation-item.component.html',
    styleUrls: ['navigation-item.component.scss'],
})
export class WucNavigationItemComponent implements OnInit, OnDestroy {
    @ViewChild('content', { read: ElementRef })
    private contentElement!: ElementRef;

    @ContentChildren(WucLinkComponent, { descendants: true })
    private childLinks!: QueryList<WucLinkComponent>;

    @Input() public url?: string | null;
    @Input() public name?: string;
    @Input() public route?: string | string[];
    @Input() public icon?: IconUiIcon;
    @Input() public showDropdownFullWidth?: boolean;
    @Input() public position: 'left' | 'right' = 'left';
    @Input() public width: 'normal' | 'large' | 'full' = 'normal';

    @Output() public clicked = new EventEmitter<void>();
    @Output() public isOpened = new EventEmitter<boolean>();
    @Output() public resetState = new EventEmitter<boolean>();

    @HostListener('document:click', ['$event.target'])
    @HostListener('document:keydown.enter', ['$event.target'])
    public handleTargetClose(targetElement: HTMLElement): void {
        if (!this.elementRef.nativeElement.contains(targetElement)) {
            this.close();
        }
    }

    public showDivider: boolean = true;
    public firstItem: boolean = false;
    public lastItem: boolean = false;

    public opened: boolean = false;
    public isHovered: boolean = false;
    public hasActiveChild: boolean = false;

    private subscriptions: Subscription = new Subscription();

    constructor(
        public router: Router,
        public elementRef: ElementRef,
    ) {}

    public ngOnInit(): void {
        this.subscriptions.add(
            this.router.events
                .pipe(
                    // https://github.com/angular/angular/issues/43124
                    // https://stackoverflow.com/questions/68478577/filtering-for-navigationend-events-in-angular-12
                    filter((event): event is NavigationEnd => event instanceof NavigationEnd),
                    filter(() => !this.route),
                    startWith(null),
                    delay(0),
                )
                .subscribe(() => this.updateActive()),
        );
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public close(): void {
        this.opened = false;
        this.resetState.emit();
    }

    public toggle(): void {
        this.opened = !this.route && !this.opened;

        if (!this.route) {
            this.isOpened.emit(this.opened);
        }
    }

    public get isActive(): boolean {
        return this.opened || this.hasActiveChild || this.isHovered;
    }

    private updateActive(): void {
        this.hasActiveChild = this.childLinks?.some((link: WucLinkComponent) => link.isActive);
    }
}
