<div
  class="wuc-input-container wuc-input-container--border"
  [class.wuc-input-container--has-error]="hasError"
  [class.wuc-input-container--disabled]="isDisabled"
>
  <div class="wuc-license-plate">
    <div class="wuc-license-plate__input">
      <input
        #input
        type="text"
        class="wuc-input"
        [class.input--error]="hasError"
        [attr.autocomplete]="autocomplete"
        [attr.inputmode]="inputmode"
        [attr.aria-label]="ariaLabel"
        [attr.id]="name ?? null"
        [attr.e2e]="e2e ?? null"
        [placeholder]="placeholder"
        [value]="formattedValue"
        (input)="onFormatLicensePlate(input.value)"
        (blur)="onTouched()"
        [disabled]="isDisabled"
      />
    </div>
    <div class="wuc-license-plate__icon" (click)="input.focus()">
      <div class="wuc-license-plate__euro">
        <wuc-icon-ui icon="euro-stars" type="dark" size="xs"></wuc-icon-ui>
      </div>
      <div class="wuc-license-plate__country">NL</div>
    </div>
  </div>
</div>
