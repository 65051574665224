import { Component, Input, TemplateRef, ViewChild } from '@angular/core';

@Component({
    selector: 'wuc-summary-table-label',
    templateUrl: 'summary-table-label.component.html',
    styleUrls: ['summary-table-label.component.scss'],
})
export class WucSummaryTableLabelComponent {
    @Input()
    public isBold = false;

    @ViewChild('summaryTableLabel', { read: TemplateRef })
    public templateRef!: TemplateRef<HTMLElement>;
}
