<div
    class="wuc-display-field"
    [class.wuc-display-field--no-action]="hideAction"
>
  <div class="wuc-display-field__row">
    <div class="wuc-display-field__label" [class.wuc-display-field__label--bold]="boldLabel">
      <ng-content select="label"></ng-content>
    </div>
    <div class="wuc-display-field__content">
      <ng-content></ng-content>
    </div>
    <div class="wuc-display-field__action">
      <ng-content select="[slot=action]"></ng-content>
    </div>
  </div>
</div>
