import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WucFormFieldStateModule } from '../form-field-state';
import { WucInputNumberComponent } from './input-number.component';

@NgModule({
    imports: [FormsModule, ReactiveFormsModule, CommonModule, WucFormFieldStateModule],
    declarations: [WucInputNumberComponent],
    exports: [WucInputNumberComponent],
})
export class WucInputNumberModule {}
