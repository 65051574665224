<div class="wuc-chatbot-mini" [class.wuc-chatbot-mini--is-open]="isOpen">
  <wuc-picture
    imageDesktop="/icons/chat-shmuppet-80x100.png"
    [imageHeight]="{ mobile: 80, tablet: 80, desktop: 80 }"
    [imageWidth]="{ mobile: 64, tablet: 64, desktop: 64 }"
    class="wuc-chatbot-mini__picture"
    (click)="toggle()"
  ></wuc-picture>
  <wuc-balloon
    class="wuc-chatbot-mini__balloon"
    arrowPosition="leftTop"
    [backgroundStyle]="isOnDarkBackground ? 'tertiary' : 'secondary'"
    padding="none"
    [hideShadow]="true"
  >
    <div class="wuc-chatbot-mini__header" (click)="toggle()">
      <div>
        <ng-content select="[slot=intro]"></ng-content>
      </div>
      <wuc-icon-ui icon="bottom-open" class="wuc-chatbot-mini__header-icon"></wuc-icon-ui>
    </div>
    <div *ngIf="isOpen" class="wuc-chatbot-mini__input">
      <wuc-chatbot-input
        [buttonText]="buttonText"
        [placeholder]="placeholder"
        [maxNumberOfCharacters]="maxNumberOfCharacters"
        [maxNumberOfCharactersText]="maxNumberOfCharactersText"
        (question)="onSend($event)"
      ></wuc-chatbot-input>
    </div>
  </wuc-balloon>
</div>
