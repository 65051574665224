import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'wuc-input-read-only',
    templateUrl: 'input-read-only.component.html',
    styleUrls: ['input-read-only.component.scss'],
})
export class WucInputReadOnlyComponent {
    @Input() public action?: string;

    @Output() public clicked = new EventEmitter<void>();

    public onAction(): void {
        this.clicked.emit();
    }
}
