<ng-template #tableRowHeader>
  <ng-container *ngFor="let cell of cells; let index = index">
    <th *ngIf="header && index === 1" class="wuc-table-cell"></th>
    <th
      *ngIf="header"
      class="wuc-table-cell wuc-table-cell--header"
      [class.wuc-table-cell--padding]="cellPadding === 'normal'"
      [class.wuc-table-cell--padding-horizontal]="cellPadding === 'horizontal'"
      [class.wuc-table-cell--padding-vertical]="cellPadding === 'vertical'"
      [class.wuc-table-cell--padding-none]="cellPadding === 'none'"
      [class.wuc-table-cell--right]="cell.position === 'right'"
      [class.wuc-table-cell--center]="cell.position === 'center'"
      [class.wuc-table-cell--primary]="cell.color === 'primary'"
      [class.wuc-table-cell--bold]="cell.fontWeight === 'bold'"
      [class.wuc-table-cell--medium]="cell.fontWeight === 'medium'"
    >
      <ng-content *ngTemplateOutlet="cell.tableCell"></ng-content>
    </th>
  </ng-container>
</ng-template>

<ng-template #tableRowBody>
  <ng-container *ngFor="let cell of cells; let index = index">
    <td *ngIf="!header && index === 1" class="wuc-table-cell">
      <wuc-button
        *ngIf="rows?.length"
        class="wuc-table-cell--expand-button"
        type="clean"
        [icon]="showExpand ? 'up-open' : 'bottom-open'"
        [small]="true"
        title="Expand table row"
      ></wuc-button>
    </td>
    <td
      *ngIf="!header"
      class="wuc-table-cell"
      [class.wuc-table-cell--padding]="cellPadding === 'normal'"
      [class.wuc-table-cell--padding-horizontal]="cellPadding === 'horizontal'"
      [class.wuc-table-cell--padding-vertical]="cellPadding === 'vertical'"
      [class.wuc-table-cell--padding-none]="cellPadding === 'none'"
      [class.wuc-table-cell--right]="cell.position === 'right'"
      [class.wuc-table-cell--center]="cell.position === 'center'"
      [class.wuc-table-cell--primary]="cell.color === 'primary'"
      [class.wuc-table-cell--bold]="cell.fontWeight === 'bold'"
      [class.wuc-table-cell--medium]="cell.fontWeight === 'medium'"
    >
      <ng-content *ngTemplateOutlet="cell.tableCell"></ng-content>
    </td>
  </ng-container>
</ng-template>

<ng-template #tableRowExpanded let-first="first" let-last="last">
  <ng-container *ngFor="let cell of cells; let index = index">
    <td class="wuc-table-cell wuc-table-cell--expanded" *ngIf="index === 1"></td>
    <td
      class="wuc-table-cell wuc-table-cell--expanded"
      [class.wuc-table-cell--padding]="cellPadding === 'normal'"
      [class.wuc-table-cell--padding-horizontal]="cellPadding === 'horizontal'"
      [class.wuc-table-cell--padding-vertical]="cellPadding === 'vertical'"
      [class.wuc-table-cell--padding-none]="cellPadding === 'none'"
      [class.wuc-table-cell--center]="cell.position === 'center'"
      [class.wuc-table-cell--right]="cell.position === 'right'"
      [class.wuc-table-cell--primary]="cell.color === 'primary'"
      [class.wuc-table-cell--bold]="cell.fontWeight === 'bold'"
      [class.wuc-table-cell--medium]="cell.fontWeight === 'medium'"
      [class.wuc-table-cell--expanded-first]="first"
      [class.wuc-table-cell--expanded-last]="last"
    >
      <ng-content *ngTemplateOutlet="cell.tableCell"></ng-content>
    </td>
  </ng-container>
</ng-template>
