import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucIconUiModule } from '../icon-ui';
import { WucButtonComponent } from './button.component';

@NgModule({
    imports: [CommonModule, WucIconUiModule],
    declarations: [WucButtonComponent],
    exports: [WucButtonComponent],
})
export class WucButtonModule {}
