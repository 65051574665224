import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucIconUiModule } from '../icon-ui';
import { WucListIconItemModule } from '../list-icon-item';
import { WucListItemModule } from '../list-item';
import { WucListItemBaseComponent } from './list-item-base.component';
import { WucListComponent } from './list.component';

@NgModule({
    imports: [CommonModule, WucListItemModule, WucListIconItemModule, WucIconUiModule],
    declarations: [WucListComponent, WucListItemBaseComponent],
    exports: [WucListComponent, WucListItemModule, WucListIconItemModule],
})
export class WucListModule {}
