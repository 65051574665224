import { Injectable, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { AutocompleteType } from './autocomplete.type';
import { InputModeType } from './input-mode.type';
import { InputInterface } from './input.interface';

@Injectable()
export class WucInputBaseComponent implements InputInterface {
    @Input() public name?: string;
    @Input() public e2e?: string;
    @Input() public autocomplete?: AutocompleteType;
    @Input() public inputmode?: InputModeType;
    @Input() public ariaLabel?: string;

    // https://github.com/angular/angular/issues/5415#issuecomment-253509453
    // To be added to the child component's Component metadata
    public static inputs = ['name', 'autocomplete', 'inputmode', 'ariaLabel'];

    constructor(public ngControl: NgControl) {
        if (!ngControl) {
            console.error('Error: formControl missing');
        }
    }

    public get hasError(): boolean {
        return this.ngControl && this.ngControl.touched === true && this.ngControl.valid === false;
    }

    /* istanbul ignore next */
    public setFocus(): void {
        // overridable
    }
}
