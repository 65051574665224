import { NgModule } from '@angular/core';
import { WucFormFieldStateModule } from '../form-field-state';
import { WucInputInitialsComponent } from './input-initials.component';

@NgModule({
    imports: [WucFormFieldStateModule],
    declarations: [WucInputInitialsComponent],
    exports: [WucInputInitialsComponent],
})
export class WucInputInitialsModule {}
