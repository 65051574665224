<div class="wuc-compare-table" #dropContainer>
  <div
    #compareTable
    class="wuc-compare-table__table"
    [style.grid-template-columns]="getGridTemplateColumnsStyle()"
    [style.grid-template-rows]="getGridTemplateRowsStyle()"
    [ngClass]="'wuc-compare-table__table--' + tableBackgroundColor + '-background'"
  >
    <ng-container *ngIf="hasExtraInfo && !isMobileView">
      <ng-container *ngTemplateOutlet="extraInfoDesktopTemplate"></ng-container>
    </ng-container>
    <ng-container *ngFor="let column of columns">
      <ng-container *ngTemplateOutlet="column.templateRef"></ng-container>
    </ng-container>
    <wuc-swipe-hand-animated *ngIf="showSwipeHand" class="wuc-compare-table__swiping-hand"></wuc-swipe-hand-animated>
  </div>
  <div class="wuc-compare-table__footer">
    <ng-content select="[slot=footer]"></ng-content>
  </div>
</div>

<ng-template #extraInfoDesktopTemplate>
  <div><!-- spacer --></div>
  <div class="wuc-compare-table__extra-info">
    <wuc-card borderColor="primary" textAlign="center" background="light">
      <div class="wuc-compare-table__extra-info-content">
        <ng-content select="[slot=extraInfo]"></ng-content>
      </div>
    </wuc-card>
    <div class="wuc-compare-table__arrow-icon" [style.left.%]="arrowPositionPercentage">
      <wuc-icon type="primary" icon="arrowLO" size="l"></wuc-icon>
    </div>
  </div>
</ng-template>
