<div class="wuc-scribble-circle">
  <img
    alt="scribble circle image"
    title="scribble circle image"
    height="45px"
    width="140px"
    src="assets/scribble-circle.svg"
  />
  <span class="wuc-scribble-circle__text">{{ scribbleText.substring(0, 12) }}</span>
</div>
