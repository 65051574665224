<div class="input-radio-tile" (click)="onCardClick()">
  <div
    class="input-radio-tile__content"
    [class.input-radio-tile__content--selected]="formControl.value === value"
    [class.input-radio-tile__content--large]="size === 'large'"
  >
    <div class="input-radio-tile__check" *ngIf="formControl.value === value">
      <div class="input-radio-tile__check-icon">
        <wuc-icon-ui icon="check-closed" type="white" size="xs"></wuc-icon-ui>
      </div>
    </div>
    <wuc-icon
      [icon]="icon"
      [size]="size === 'large' ? 'xxl' : 'xl'"
      [type]="formControl.value === value ? 'white' : 'secondary'"
    ></wuc-icon>
    <div class="input-radio-tile__radio-button">
      <wuc-input-radio [formControl]="formControl" [name]="name || ''" [value]="value" [attr.aria-label]="ariaLabel">
        <ng-content></ng-content>
      </wuc-input-radio>
    </div>
  </div>
  <p class="input-radio-tile__text">
    {{ text }}
  </p>
</div>
