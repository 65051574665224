<div class="wuc-form-field">
  <div class="wuc-form-field__label-side" *ngIf="labels.length">
    <ng-content select="wuc-form-field-label"></ng-content>
    <ng-content select="[slot=below-label]"></ng-content>
  </div>
  <div class="wuc-form-field__input-side" [class.wuc-form-field__input-side--full-width]="labels.length === 0">
    <div class="wuc-form-field__input-container">
      <div class="wuc-form-field__input">
        <ng-content></ng-content>
      </div>
      <div class="wuc-form-field__subtext">
        <ng-content select="wuc-form-field-subtext"></ng-content>
      </div>
      <ng-content select="wuc-input-alert"></ng-content>
      <ng-content select="wuc-form-error"></ng-content>
      <ng-content select="[slot=below-input]"></ng-content>
    </div>
    <div *ngIf="!hasNoState" class="wuc-form-field__state">
      <wuc-form-field-state [inputs]="inputInterfaces"></wuc-form-field-state>
    </div>
  </div>
</div>
