<div
  class="wuc-input-container wuc-input-container--border"
  [class.wuc-input-container--has-error]="hasError"
  [class.wuc-input-container--disabled]="disabled"
>
  <input
    #input
    type="tel"
    class="wuc-input wuc-input-phonenumber"
    [class.input--error]="hasError"
    [attr.autocomplete]="autocomplete"
    [attr.inputmode]="inputmode"
    [attr.aria-label]="ariaLabel"
    [attr.id]="name ?? null"
    [attr.e2e]="e2e ?? null"
    [placeholder]="placeholder"
    [value]="value"
    (beforeinput)="onBeforeInput($event)"
    (blur)="onBlur($event)"
    [disabled]="disabled"
  />
  <wuc-icon-ui class="wuc-input__icon" size="s" icon="call" type="primary" (click)="input.focus()"></wuc-icon-ui>
</div>
