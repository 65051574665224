import { Component, ElementRef, Input, Optional, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { WucInputBaseComponent } from '../input-base.component';
import { InputInterface } from '../input.interface';

@Component({
    selector: 'wuc-input-initials',
    templateUrl: 'input-initials.component.html',
    styleUrls: ['input-initials.component.scss'],
    // eslint-disable-next-line
    inputs: WucInputBaseComponent.inputs,
    providers: [
        {
            provide: WucInputBaseComponent,
            useExisting: WucInputInitialsComponent,
        },
    ],
})
export class WucInputInitialsComponent extends WucInputBaseComponent implements ControlValueAccessor, InputInterface {
    @ViewChild('input', { static: true }) private input!: ElementRef;

    @Input() public placeholder: string = '';

    public disabled: boolean = false;
    public value: string = '';
    public onChange!: (value: string) => void;
    public onTouched!: () => void;

    private readonly allowedCharactersRexExp: RegExp = /^[a-z]*$/i;

    constructor(@Optional() public override ngControl: NgControl) {
        super(ngControl);
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    // Update value from API; API => view
    public writeValue(value: string): void {
        this.value = value;
    }

    // Update value from view "on change"; View => API
    public registerOnChange(fn: (_: string) => void): void {
        this.onChange = fn;
    }

    // Mark control as touched; View => API
    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    // Set disabled state from API
    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public onBeforeInput(event: InputEvent): void {
        if (!event.data) {
            return;
        }

        if (event.data && !this.allowedCharactersRexExp.test(event.data)) {
            event.preventDefault();
            return;
        }
    }

    public onInput(event: Event): void {
        this.handleInput(event.target as HTMLInputElement, 'input');
    }

    public onKeyDelete(event: Event): void {
        this.handleInput(event.target as HTMLInputElement, 'delete');
        event.preventDefault();
    }

    public onKeyBackspace(event: Event): void {
        this.handleInput(event.target as HTMLInputElement, 'backspace');
        event.preventDefault();
    }

    private handleInput(inputElement: HTMLInputElement, event: 'input' | 'backspace' | 'delete'): void {
        let cleanValue = inputElement.value.replace(/[^a-z]/gi, '');
        let startIndex = Math.ceil((inputElement.selectionStart || 0) / 2);
        let endIndex = Math.ceil((inputElement.selectionEnd || 0) / 2);

        if (event !== 'input') {
            if (startIndex === endIndex) {
                switch (event) {
                case 'backspace':
                    if (startIndex > 0) {
                        startIndex--;
                    }
                    break;
                case 'delete':
                    endIndex++;
                    break;
                }
            }
            cleanValue = cleanValue.slice(0, startIndex) + cleanValue.slice(endIndex);
        }

        this.value = cleanValue.replace(/([a-z](?!\.))/gi, '$1.').toUpperCase();
        inputElement.value = this.value;
        this.onChange(this.value);

        setTimeout(() => inputElement.setSelectionRange(startIndex * 2, startIndex * 2));
    }
}
