<ng-template #template>
  <div
    *ngIf="parentTable && parentColumn"
    (mouseenter)="onMouseEnter()"
    (mouseleave)="onMouseLeave()"
    (click)="onClick()"
    class="wuc-compare-table-cell"
    [ngClass]="'wuc-compare-table-cell--' + parentTable.tableBackgroundColor + '-background'"
    [class.wuc-compare-table-cell--border-bottom]="!isExtraInfoCell && !lastInColumn"
    [class.wuc-compare-table-cell--hide-border-bottom]="firstInColumn"
    [class.wuc-compare-table-cell--title-color]="firstInColumn"
    [class.wuc-compare-table-cell--hide]="parentColumn.isHidden"
    [class.wuc-compare-table-cell--fixed]="parentColumn.isFixed"
    [class.wuc-compare-table-cell--shrink-animation]="parentColumn.hasShrinkAnimation"
    [class.wuc-compare-table-cell--smooth-transition]="!parentColumn.isSmoothAnimationDisabled"
    [class.wuc-compare-table-cell--first-column]="parentColumn.isLabelColumn"
    [class.wuc-compare-table-cell--last-visible-column]="parentColumn.isLastColumn"
    [style.transform]="parentColumn.totalOffset ? 'translateX(' + -parentColumn.totalOffset + 'px)' : null"
  >
    <div
      class="wuc-compare-table-cell__content"
      [ngClass]="[
        'wuc-compare-table-cell__content--' +
          (isExtraInfoCell ? parentTable.tableBackgroundColor : parentColumn.backgroundColor) +
          '-background',
        'wuc-compare-table-cell__content--horizontal-align-' + horizontalAlignment,
        'wuc-compare-table-cell__content--vertical-align-' + verticalAlignment
      ]"
      [class.wuc-compare-table-cell__content--bordered]="isBordered"
      [class.wuc-compare-table-cell__content--swipe-hint]="!parentColumn.isFixed && parentTable.showSwipeHint"
      [class.wuc-compare-table-cell__content--border-primary]="parentColumn.isSelected"
      [class.wuc-compare-table-cell__content--hover]="
        !isExtraInfoCell && !parentColumn.isBordered && parentColumn.hasHover
      "
      [class.wuc-compare-table-cell__content--first-in-column]="firstInColumn"
      [class.wuc-compare-table-cell__content--last-in-column]="lastInColumn"
      [class.wuc-compare-table-cell__content--cursor-pointer]="
        !isExtraInfoCell && parentTable.hasCursorPointer && parentColumn.index !== -1
      "
      [class.wuc-compare-table-cell__content--disabled]="parentColumn.isDisabled"
    >
      <ng-content></ng-content>
    </div>
  </div>
</ng-template>
