import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucReadMoreComponent } from './read-more.component';

@NgModule({
    declarations: [WucReadMoreComponent],
    exports: [WucReadMoreComponent],
    imports: [CommonModule],
})
export class WucReadMoreModule {}
