import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[wucClickOutside]',
})
export class WucClickOutsideDirective {
    @Output() public readonly clickedOutside = new EventEmitter<MouseEvent>();

    constructor(private elementRef: ElementRef) {}

    @HostListener('document:click', ['$event', '$event.target'])
    public onClick(event: MouseEvent, targetElement: HTMLElement): void {
        if (!this.elementRef.nativeElement.contains(targetElement)) {
            this.clickedOutside.emit(event);
        }
    }
}
