<svg
  class="wuc-icon-ui"
  [class.wuc-icon-ui--primary]="type === 'primary'"
  [class.wuc-icon-ui--secondary]="type === 'secondary'"
  [class.wuc-icon-ui--tertiary]="type === 'tertiary'"
  [class.wuc-icon-ui--dark]="type === 'dark'"
  [class.wuc-icon-ui--white]="type === 'white'"
  [ngClass]="'wuc-icon-ui--' + size"
>
  <use [attr.href]="'icons/icons-ui.svg#' + icon"></use>
</svg>
