<div
  class="wuc-input-container wuc-input-container--border"
  [class.wuc-input-container--has-error]="hasError"
  [class.wuc-input-container--disabled]="isDisabled"
>
  <textarea
    #input
    class="wuc-input-textarea wuc-input"
    [class.input--error]="hasError"
    [attr.rows]="rows"
    [attr.autocomplete]="autocomplete"
    [attr.aria-label]="ariaLabel"
    [attr.id]="name ?? null"
    [attr.e2e]="e2e ?? null"
    [placeholder]="placeholder"
    (input)="onChange(input.value)"
    (blur)="onTouched()"
    [disabled]="isDisabled"
    [innerHTML]="value"
  ></textarea>
</div>
