import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucSafeHtmlModule } from '../../../shared';
import { WucButtonModule, WucLinkModule } from '../../common';
import { WucCompareTableModule } from '../compare-table';
import { WucCompareTableCoverageComponent } from './compare-table-coverage.component';

@NgModule({
    imports: [CommonModule, WucCompareTableModule, WucButtonModule, WucSafeHtmlModule, WucLinkModule],
    declarations: [WucCompareTableCoverageComponent],
    exports: [WucCompareTableCoverageComponent],
})
export class WucCompareTableCoverageModule {}
