import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Params, RouterLinkActive } from '@angular/router';
import { KeyCodeEnum } from '../../../core';
import { IconUiIcon, IconUiSize } from '../icon-ui';
import { RelType } from './rel.type';
import { TargetType } from './target.type';

@Component({
    selector: 'wuc-link',
    templateUrl: './link.component.html',
    styleUrls: ['./link.component.scss', './link-button.component.scss'],
})
export class WucLinkComponent {
    @Input() public type?:
        | 'primary'
        | 'secondary'
        | 'tertiary'
        | 'quaternary'
        | 'quinary'
        | 'button-primary'
        | 'button-secondary';

    @Input() public route!: string | string[] | null;
    @Input() public routeFragment?: string;
    @Input() public queryParams?: Params | null;
    @Input() public url!: string | null;
    @Input() public linkTitle?: string;
    @Input() public rel?: RelType[] | RelType;
    @Input() public iconRight?: IconUiIcon;
    @Input() public iconLeft?: IconUiIcon;
    @Input() public iconSize: IconUiSize = 's';
    @Input() public isRouterLinkActiveExact: boolean = false;
    @Input() public target?: TargetType;
    @Input() public hasDropTrigger: boolean = false;
    @Input() public isFullWidth: boolean = false;
    @Input() public isButtonSmall: boolean = false;
    @Input() public noWrap: boolean = false;

    /**
     * @deprecated You should use target="_blank"
     */
    @Input() public openUrlInNewTab: boolean = false;

    @Output() public clicked = new EventEmitter<void>();

    @ViewChild(RouterLinkActive)
    public routerLinkActive!: RouterLinkActive;

    public get isActive(): boolean {
        return this.routerLinkActive?.isActive;
    }

    public onClick(event?: Event): void {
        this.emitEvent();
        if (event) {
            event.preventDefault();
        }
    }

    public onKeyUp(event: KeyboardEvent): void {
        if (event.key === KeyCodeEnum.Enter) {
            this.emitEvent();
            event.preventDefault();
        }
    }

    public get relJoined(): string | null {
        if (Array.isArray(this.rel)) {
            return this.rel.join(' ');
        } else {
            return this.rel || null;
        }
    }

    public emitEvent(): void {
        this.clicked.emit();
    }
}
