<wuc-compare-table
  *ngIf="data"
  [isMobileView]="isMobileView"
  [dataColumnsInMobileView]="dataColumnsInMobileView"
  [isHoverBorderHidden]="true"
  [isBorderVisible]="true"
  [tableBackgroundColor]="tableBackgroundColor"
  [labelColumnBackgroundColor]="labelColumnBackgroundColor"
  [dataColumnBackgroundColor]="dataColumnBackgroundColor"
  selectedColumnKey="0"
>
  <wuc-compare-table-column>
    <wuc-compare-table-cell></wuc-compare-table-cell>
    <wuc-compare-table-cell *ngFor="let label of data.labels" horizontalAlignment="left" verticalAlignment="top">
      <span [innerHtml]="label | wucSafeHtml"></span>
    </wuc-compare-table-cell>
    <wuc-compare-table-cell></wuc-compare-table-cell>
  </wuc-compare-table-column>
  <wuc-compare-table-column *ngFor="let column of data.columns; let index = index" [key]="index.toString()">
    <wuc-compare-table-cell verticalAlignment="center">
      <span *ngIf="!column.logo" class="wuc-compare-table-insurances__title">
        {{ column.title }}
      </span>
      <span *ngIf="column.logo" class="wuc-compare-table-insurances__logo">
        <wuc-picture [alt]="column.logo.alt" [imageDesktop]="column.logo.path"></wuc-picture>
      </span>
    </wuc-compare-table-cell>
    <wuc-compare-table-cell *ngFor="let row of column.rows" [verticalAlignment]="row.verticalAlignment || 'center'">
      <wuc-compare-table-cell-item *ngFor="let item of row.items" [item]="item"></wuc-compare-table-cell-item>
    </wuc-compare-table-cell>
    <wuc-compare-table-cell verticalAlignment="top">
      <wuc-button
        *ngIf="column.action"
        [small]="true"
        [type]="column.action.isPrimary ? 'primary' : 'secondary'"
        (buttonClick)="onActionClick(column.action.routerLink)"
      >
        <span class="wuc-compare-table-insurances__button">
          {{ column.action.label }}
        </span>
      </wuc-button>
    </wuc-compare-table-cell>
  </wuc-compare-table-column>
  <div slot="footer" *ngIf="data.footer">
    <p class="wuc-compare-table-insurances__footer" [innerText]="data.footer"></p>
  </div>
</wuc-compare-table>
