import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'wucReplace',
})
export class WucReplacePipe implements PipeTransform {
    public transform(text: string, find: string | RegExp, replacement: string): string {
        if (!text) {
            return '';
        }
        return text.replace(find, replacement || '');
    }
}
