import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { WucCompareTableCellItemInterface } from './compare-table-cell-item.interface';

@Component({
    selector: 'wuc-compare-table-cell-item',
    templateUrl: 'compare-table-cell-item.component.html',
    styleUrls: ['compare-table-cell-item.component.scss'],
})
export class WucCompareTableCellItemComponent {
    @Input() public item!: WucCompareTableCellItemInterface;
    @Input() public isColumnLabel: boolean = false;

    public dropContainerElement?: HTMLElement;

    public setDropContainerElement(element: HTMLElement): void {
        this.dropContainerElement = element;
        this.changeDetectorRef.detectChanges();
    }

    constructor(private changeDetectorRef: ChangeDetectorRef) {}

    public asNumber(value: string | number | boolean): number {
        return Number(value);
    }

    public asString(value: string | boolean | number): string {
        return value.toString();
    }
}
