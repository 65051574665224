import { Component, Input, TemplateRef, ViewChild } from '@angular/core';

@Component({
    standalone: true,
    selector: 'wuc-display-table-label',
    templateUrl: 'display-table-label.component.html',
    styleUrls: ['display-table-label.component.scss'],
})
export class WucDisplayTableLabelComponent {
    @Input()
    public isBold = false;

    @ViewChild('displayTableLabel', { read: TemplateRef })
    public templateRef!: TemplateRef<HTMLElement>;
}
