import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WucCardModule, WucIconUiModule, WucListModule } from '../../common';
import { WucFormFieldStateModule } from '../form-field-state';
import { WucInputRadioComponent } from './input-radio.component';

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        WucFormFieldStateModule,
        WucCardModule,
        WucIconUiModule,
        WucListModule,
    ],
    declarations: [WucInputRadioComponent],
    exports: [WucInputRadioComponent],
})
export class WucInputRadioModule {}
