<div class="wuc-form-error">
  <wuc-balloon padding="medium" arrowPosition="topLeft">
    <ul class="wuc-form-error__content">
      <ng-container *ngIf="errorMessages; else singleErrorMessage">
        <li class="wuc-form-error__item" *ngFor="let errorMessage of errorMessages">
          <span class="wuc-form-error__icon">
            <wuc-icon-ui icon="cross-closed" type="primary"></wuc-icon-ui>
          </span>
          {{ errorMessage }}
        </li>
      </ng-container>
    </ul>
  </wuc-balloon>
</div>

<ng-template #singleErrorMessage>
  <li class="wuc-form-error__item">
    <span class="wuc-form-error__icon">
      <wuc-icon-ui icon="cross-closed" type="primary"></wuc-icon-ui>
    </span>
    <ng-content></ng-content>
  </li>
</ng-template>
