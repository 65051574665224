import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucAccordionItemModule } from '../accordion-item/accordion-item.module';
import { WucIconUiModule } from '../icon-ui';
import { WucAccordionComponent } from './accordion.component';

@NgModule({
    declarations: [WucAccordionComponent],
    imports: [WucIconUiModule, CommonModule, WucAccordionItemModule],
    exports: [WucAccordionComponent, WucAccordionItemModule],
})
export class WucAccordionModule {}
