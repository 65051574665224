<div *ngIf="options" class="wuc-chatbot-escalation" [class.wuc-chatbot-escalation--compact-view]="isCompactView">
  <div class="wuc-chatbot-escalation__left-side">
    <div class="wuc-chatbot-escalation__usefulness" *ngIf="!isFeedbackSend">
      <div class="wuc-chatbot-escalation__usefulness-title">
        {{ options.usefulness.title }}
      </div>
      <div class="wuc-chatbot-escalation__usefulness-buttons">
        <wuc-button [small]="true" type="secondary" (buttonClick)="onUsefulness(true)">
          {{ options.usefulness.buttonYesText }}
        </wuc-button>
        <wuc-button [small]="true" type="secondary" (buttonClick)="onUsefulness(false)">
          {{ options.usefulness.buttonNoText }}
        </wuc-button>
      </div>
    </div>

    <div *ngIf="isFeedbackSend" class="wuc-chatbot-escalation__thanks">
      <wuc-icon-ui icon="check-closed"></wuc-icon-ui>
      <span class="wuc-chatbot-escalation__thanks-text">
        {{ options.thanksText }}
      </span>
    </div>

    <div *ngIf="options.channels && hasUserMadeUsefulnessChoice" class="wuc-chatbot-escalation__channels">
      <wuc-accordion>
        <wuc-accordion-item *ngFor="let channel of options.channels" [accordionTitle]="channel.title">
          <div class="wuc-chatbot-escalation__channel" *ngFor="let item of channel.items">
            <wuc-link
              [route]="item.routerLink || null"
              [url]="item.url || null"
              (clicked)="callCallback(item.callback)"
              type="tertiary"
            >
              <div class="wuc-chatbot-escalation__channel-item">
                <div class="wuc-chatbot-escalation__channel-item-icon">
                  <wuc-icon [icon]="item.icon" size="l"></wuc-icon>
                </div>
                <div class="wuc-chatbot-escalation__channel-title-group">
                  <div class="wuc-chatbot-escalation__channel-title">{{ item.title }}</div>
                  <div class="wuc-chatbot-escalation__channel-sub-title">{{ item.subTitle }}</div>
                </div>
              </div>
            </wuc-link>
          </div>
        </wuc-accordion-item>
      </wuc-accordion>
    </div>
  </div>

  <div class="wuc-chatbot-escalation__right-side" *ngIf="options.feedback && hasUserMadeUsefulnessChoice && !isAnswerUseful && !isFeedbackSend">
    <div
      class="wuc-chatbot-escalation__input"
    >
      <div class="wuc-chatbot-escalation__input-title">
        {{ options.feedback.title }}
      </div>
      <div class="wuc-chatbot-escalation__input-text">
        <wuc-input-textarea
          [placeholder]="options.feedback.placeholder"
          [formControl]="inputControl"
          [rows]="10"
        ></wuc-input-textarea>
      </div>
      <div class="wuc-chatbot-escalation__input-button">
        <wuc-button type="primary" [disabled]="inputControl.invalid" (buttonClick)="onSend()">
          {{ options.feedback.sendButtonText }}
        </wuc-button>
      </div>
    </div>
  </div>
</div>
