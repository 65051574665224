import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NativeRouterLinkActiveDirective } from './native-router-link-active.directive';

@NgModule({
    imports: [CommonModule],
    exports: [NativeRouterLinkActiveDirective],
    declarations: [NativeRouterLinkActiveDirective],
})
export class NativeRouterLinkActiveModule {}
