import { CommonModule, CurrencyPipe, registerLocaleData } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucPriceComponent } from './price.component';
import localeNl from '@angular/common/locales/nl';
import localeDe from '@angular/common/locales/de';
registerLocaleData(localeNl);
registerLocaleData(localeDe);

@NgModule({
    declarations: [WucPriceComponent],
    exports: [WucPriceComponent],
    imports: [CommonModule, CurrencyPipe],
    providers: [CurrencyPipe],
})
export class WucPriceModule {}
