import { NgModule } from '@angular/core';
import { WucDisplayFieldComponent } from './display-field.component';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [CommonModule],
    declarations: [WucDisplayFieldComponent],
    exports: [WucDisplayFieldComponent],
})
export class WucDisplayFieldModule {}
