<ng-container>
  <div
    class="wuc-page-block"
    [class.wuc-page-block--is-solid]="blockType === 'solid'"
    [class.wuc-page-block--is-open]="blockType === 'open'"
    [class.wuc-page-block--with-icon]="icon"
    [class.wuc-page-block--is-mobile]="isMobile"
    [class.wuc-page-block--without-header]="hideHeader"
  >
    <div *ngIf="!hideHeader" class="wuc-page-block__header">
      <div *ngIf="icon" class="wuc-page-block__header-icon">
        <ng-container *ngIf="!isLoading && !skeleton; else iconLoadingTemplate">
          <wuc-icon [icon]="icon" size="l"></wuc-icon>
        </ng-container>
      </div>
      <div *ngIf="!isLoading && !skeleton; else headerTextLoadingTemplate" class="wuc-page-block__header-text">
        <ng-content select="[slot=header]"></ng-content>
      </div>
    </div>
    <div *ngIf="!isLoading && !skeleton; else contentLoadingTemplate" class="wuc-page-block__content">
      <ng-content></ng-content>
    </div>
  </div>
</ng-container>

<ng-template #iconLoadingTemplate>
  <wuc-skeleton width="4rem" height="4rem" shape="circle"></wuc-skeleton>
</ng-template>

<ng-template #headerTextLoadingTemplate>
  <wuc-skeleton height="3rem"></wuc-skeleton>
</ng-template>

<ng-template #contentLoadingTemplate>
  <wuc-skeleton [height]="skeletonHeight"></wuc-skeleton>
</ng-template>
