import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { WucCardModule } from '../../common';
import { WucChatbotEscalationModule } from '../chatbot-escalation';
import { WucChatbotInputModule } from '../chatbot-input';
import { WucChatbotListModule } from '../chatbot-list';
import { WucChatbotComponent } from './chatbot.component';

@NgModule({
    imports: [
        CommonModule,
        NgxPageScrollCoreModule,
        WucCardModule,
        WucChatbotEscalationModule,
        WucChatbotInputModule,
        WucChatbotListModule,
    ],
    declarations: [WucChatbotComponent],
    exports: [WucChatbotComponent],
})
export class WucChatbotModule {}
