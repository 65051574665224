export class InputService {
    public static replaceSelection(inputElement: HTMLInputElement, text: string): string {
        const currentValue = inputElement.value;
        const currentPositionStart = inputElement.selectionStart || 0;
        const currentPositionEnd = inputElement.selectionEnd || 0;
        const firstPart: string = currentValue.slice(0, currentPositionStart);
        const lastPart: string = currentValue.slice(currentPositionEnd);

        return `${firstPart}${text}${lastPart}`;
    }

    /***
     * Checks if value matches the pattern. Because the value can be an incomplete input a regex will not do
     * @param pattern String of 'Z' and '9' to indicate letters and numbers
     * @param value A string containing (part of) the value to test
     */
    public static isPatternMatch(pattern: string, value: string): boolean {
        if (!value) {
            return true;
        }

        return value.split('').every((character: string, index: number) => {
            switch (pattern[index]) {
            case 'Z':
                return /[a-zA-Z ]/.test(character);
            case '9':
                return /\d/.test(character);
            default:
                return false;
            }
        });
    }
}
