<div class="wuc-select-autocomplete" [class.active]="isOpen" wucClickOutside (clickedOutside)="onClickedOutside()">
  <div class="wuc-input-container wuc-input-container--border" [class.wuc-input-container--has-error]="hasError">
    <input
      #inputElement
      (click)="onClick()"
      (focusin)="onFocusIn()"
      (input)="onUserInput(inputElement.value)"
      (keydown)="onKeyDown($event)"
      [attr.aria-label]="ariaLabel"
      [attr.autocomplete]="autocomplete"
      [attr.e2e]="e2e ?? null"
      [attr.id]="name ?? null"
      [attr.inputmode]="inputmode"
      [attr.name]="name"
      [class.input--error]="hasError"
      [disabled]="isDisabled || !items?.length"
      [id]="id"
      [placeholder]="placeholder"
      [value]="searchValue"
      (blur)="onTouched()"
      class="wuc-input"
      type="text"
    />
    <div class="wuc-select-autocomplete__toggle" *ngIf="!isDisabled && items?.length" (click)="toggle($event)">
      <wuc-icon-ui icon="bottom-open" *ngIf="!isOpen"></wuc-icon-ui>
      <wuc-icon-ui icon="up-open" *ngIf="isOpen"></wuc-icon-ui>
    </div>
  </div>
  <ul
    [style.display]="isOpen ? 'block' : 'none'"
    class="wuc-select-autocomplete__suggestions-container"
    #filteredListElement
  >
    <li
      *ngFor="let item of filteredItems; let idx = index"
      class="wuc-select-autocomplete__list-item"
      (click)="$event.preventDefault(); onItemSelect(item)"
      [class.wuc-select-autocomplete__list-item--focused]="idx === filteredItemsCursorIndex"
      [innerHTML]="item.name | wucHighlight : searchValue"
    ></li>
    <li *ngIf="!filteredItems?.length">
      <div class="wuc-select-autocomplete__not-found">
        <i>{{ notFoundText }}</i>
      </div>
    </li>
  </ul>
</div>
