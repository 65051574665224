<div
    class="wuc-alert {{ 'wuc-alert--' + type }}"
    [class.wuc-alert--closable]="isClosable"
    [class.wuc-alert--has-action]="!!contentRef.innerText"
>
  <wuc-icon *ngIf="icon" size="l" [icon]="icon" [type]="iconType"></wuc-icon>
  <div class="wuc-alert__content" [class.wuc-alert__content--vertical]="orientation === 'vertical'">
    <div class="wuc-alert__content-text">
      <h4 class="wuc-alert__header" [innerHTML]="title | wucSafeHtml"></h4>
      <p [innerHTML]="text | wucSafeHtml"></p>
    </div>
  </div>
  <div
      #contentRef
      class="wuc-alert__action"
  >
    <ng-content></ng-content>
  </div>
  <wuc-icon-ui
    class="wuc-alert__close-icon"
    *ngIf="isClosable"
    icon="cross-closed"
    [type]="iconType"
    (click)="onCloseIconClick($event)"
  ></wuc-icon-ui>
</div>
