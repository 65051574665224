import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { WucSafeHtmlModule } from '../../../shared';
import { WucRackModule } from '../../layout';
import { WucInputRadioCardModule } from '../input-radio-card';
import { WucInputRadioCardGroupComponent } from './input-radio-card-group.component';

@NgModule({
    declarations: [WucInputRadioCardGroupComponent],
    exports: [WucInputRadioCardGroupComponent],
    imports: [CommonModule, WucRackModule, WucInputRadioCardModule, ReactiveFormsModule, WucSafeHtmlModule],
})
export class WucInputRadioCardGroupModule {}
