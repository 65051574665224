import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucReplacePipe } from './replace.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [WucReplacePipe],
    exports: [WucReplacePipe],
})
export class WucReplacePipeModule {}
