<div
  class="wuc-rating"
  [class.wuc-rating--undefined]="!rating"
  [class.wuc-rating--large]="size === 'large'"
  [class.wuc-rating--medium]="size === 'medium'"
  [class.wuc-rating--small]="size === 'small'"
>
  <ng-container *ngIf="rating; else skeletonTemplate">
    <span class="wuc-rating__integer">
      {{ integerPart }}
    </span>
    <span *ngIf="!hideDecimalWhenZero && decimalPart" class="wuc-rating__decimal">
      {{ seperator }}{{ decimalPart }}
    </span>
  </ng-container>
</div>
<ng-template #skeletonTemplate>
  <wuc-skeleton shape="circle" [height]="'inherit'" [width]="'inherit'"></wuc-skeleton>
</ng-template>
