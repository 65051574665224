import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WucIconUiModule } from '../../common';
import { WucFormFieldStateModule } from '../form-field-state';
import { WucInputZipcodeNlComponent } from './input-zipcode-nl.component';

@NgModule({
    imports: [FormsModule, ReactiveFormsModule, CommonModule, WucIconUiModule, WucFormFieldStateModule],
    declarations: [WucInputZipcodeNlComponent],
    exports: [WucInputZipcodeNlComponent],
})
export class WucInputZipcodeNlModule {}
