import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    HostBinding,
    Input,
    QueryList,
} from '@angular/core';
import { WucTableRowComponent } from '../table-row';
import { startWith } from 'rxjs/operators';

@Component({
    selector: 'wuc-table',
    templateUrl: 'table.component.html',
    styleUrls: ['table.component.scss'],
})
export class WucTableComponent implements AfterViewInit {
    @HostBinding('class.table-wrapper')
    @Input()
    public overflowScroll?: boolean;

    @Input() public isFullWidth?: boolean;
    @Input() public viewType?: 'small' | 'normal' = 'normal';

    @ContentChildren(WucTableRowComponent)
    public rows!: QueryList<WucTableRowComponent>;

    public headerRows?: WucTableRowComponent[];
    public contentRows?: WucTableRowComponent[];

    constructor(private changeDetectorRef: ChangeDetectorRef) {}

    public ngAfterViewInit(): void {
        this.rows.changes.pipe(startWith(null)).subscribe((): void => this.refreshRows());
    }

    public toggleExpand(contentRow: WucTableRowComponent): void {
        if (contentRow.rows.length) {
            contentRow.showExpand = !contentRow.showExpand;
        }
    }

    private refreshRows(): void {
        this.headerRows = this.rows.filter((row: WucTableRowComponent) => !!row.header);
        this.contentRows = this.rows.filter((row: WucTableRowComponent) => !row.header);
        this.changeDetectorRef.detectChanges();
    }
}
