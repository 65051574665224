import { Component, ContentChild, Input } from '@angular/core';
import { WucFormFieldLabelInfoComponent } from '../form-field-label-info';

@Component({
    selector: 'wuc-form-field-label',
    templateUrl: 'form-field-label.component.html',
    styleUrls: ['./form-field-label.component.scss'],
})
export class WucFormFieldLabelComponent {
    @Input() public for?: string;

    @ContentChild(WucFormFieldLabelInfoComponent)
    public info!: WucFormFieldLabelInfoComponent;

    public isInfoVisible: boolean = false;

    public onInfoIconClick(event: MouseEvent): void {
        this.isInfoVisible = !this.isInfoVisible;
        event.preventDefault();
    }
}
