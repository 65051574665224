import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucSafeHtmlPipe } from './safe-html.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [WucSafeHtmlPipe],
    exports: [WucSafeHtmlPipe],
})
export class WucSafeHtmlModule {}
