import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucRackComponent } from './rack.component';

@NgModule({
    declarations: [WucRackComponent],
    exports: [WucRackComponent],
    imports: [CommonModule],
})
export class WucRackModule {}
