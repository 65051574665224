import { ModuleWithProviders, NgModule } from '@angular/core';
import { WebsiteUiComponentsConfigInterface } from './website-ui-components-config.interface';

@NgModule({})
export class WebsiteUiComponentsModule {
    public static forRoot(
        websiteUiComponentsConfig: WebsiteUiComponentsConfigInterface
    ): ModuleWithProviders<WebsiteUiComponentsModule> {
        return {
            ngModule: WebsiteUiComponentsModule,
            providers: [
                {
                    provide: 'pikadayConfig',
                    useValue: websiteUiComponentsConfig.pikadayConfig,
                },
                {
                    provide: 'phonenumberConfig',
                    useValue: websiteUiComponentsConfig.phonenumberConfig,
                },
                {
                    provide: 'passwordConfig',
                    useValue: websiteUiComponentsConfig.passwordConfig,
                },
                {
                    provide: 'noVideoComponent',
                    useValue: websiteUiComponentsConfig.noVideoComponent,
                },
            ],
        };
    }
}
