import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { WucChatbotInputComponent } from '../chatbot-input';

@Component({
    selector: 'wuc-chatbot-mini',
    templateUrl: 'chatbot-mini.component.html',
    styleUrls: ['chatbot-mini.component.scss'],
})
export class WucChatbotMiniComponent {
    @ViewChild(WucChatbotInputComponent) private inputComponent!: WucChatbotInputComponent;

    @Input() public buttonText!: string;
    @Input() public placeholder: string = '';
    @Input() public maxNumberOfCharacters?: number;
    @Input() public maxNumberOfCharactersText?: string;
    @Input() public isOnDarkBackground: boolean = false;
    @Input() public onlyEmitOpen: boolean = false;

    @Output() public readonly question: EventEmitter<string> = new EventEmitter<string>();
    @Output() public readonly userOpenClick: EventEmitter<void> = new EventEmitter<void>();

    public isOpen: boolean = false;

    public onSend(question: string): void {
        this.question.emit(question);
    }

    public toggle(): void {
        if (this.onlyEmitOpen) {
            this.userOpenClick.emit();
        } else {
            this.isOpen = !this.isOpen;
            if (this.isOpen) {
                setTimeout(() => this.inputComponent?.setFocus());
            }
        }
    }
}
