<svg
  class="wuc-icon"
  [class.wuc-icon--primary]="type === 'primary'"
  [class.wuc-icon--secondary]="type === 'secondary'"
  [class.wuc-icon--dark]="type === 'dark'"
  [class.wuc-icon--white]="type === 'white'"
  [ngClass]="'wuc-icon--' + size"
>
  <use [attr.href]="'icons/icons-general.svg#' + icon"></use>
</svg>
