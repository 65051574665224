import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnChanges, Output } from '@angular/core';
import { WucStepInterface } from './step.interface';

@Component({
    selector: 'wuc-steps',
    templateUrl: 'steps.component.html',
    styleUrls: ['steps.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WucStepsComponent implements OnChanges {
    @Input() public steps!: WucStepInterface[];
    @Input() public currentStep!: WucStepInterface;
    @Input() public isAnimated: boolean = false;

    @Output() public stepClick: EventEmitter<WucStepInterface> = new EventEmitter<WucStepInterface>();

    @HostBinding('style.--current-percentage')
    private currentPercentage: string = '0';

    public currentIndex: number = 0;

    public ngOnChanges(): void {
        if (this.steps?.length && this.currentStep) {
            const stepCount = this.steps.length;
            this.currentIndex = this.steps.findIndex((step: WucStepInterface) => step.id === this.currentStep.id);
            this.currentPercentage = `${Math.max(0, (100 / stepCount) * this.currentIndex)}%`;
        }
    }

    public onStepClick(step: WucStepInterface): void {
        if (step.isClickable) {
            this.stepClick.emit(step);
        }
    }
}
