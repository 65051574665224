import { Component, Input, TemplateRef, ViewChild } from '@angular/core';

@Component({
    selector: 'wuc-table-cell',
    templateUrl: 'table-cell.component.html',
})
export class WucTableCellComponent {
    @Input() public position?: 'left' | 'center' | 'right';
    @Input() public color?: 'primary';
    @Input() public fontWeight?: 'medium' | 'bold';

    @ViewChild('tableCell', { read: TemplateRef })
    public tableCell!: TemplateRef<HTMLElement>;
}
