<div
  class="wuc-price"
  [class.wuc-price--extra-small]="size === 'xs'"
  [class.wuc-price--small]="size === 's'"
  [class.wuc-price--medium]="size === 'm'"
  [class.wuc-price--large]="size === 'l'"
  [class.wuc-price--xlarge]="size === 'xl'"
  [class.wuc-price--striketrough]="hasStriketrough"
>
  <span>
    {{ priceParts[0] }}
  </span>
  <span class="wuc-price__decimals">,{{ priceParts[1] }}</span>
</div>
