import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { InputInterface } from '../input.interface';
import { FormFieldStateEnum } from './form-field-state.enum';

@Component({
    selector: 'wuc-form-field-state',
    templateUrl: './form-field-state.component.html',
    styleUrls: ['./form-field-state.component.scss'],
})
export class FormFieldStateComponent implements OnChanges, OnDestroy {
    @Input() public inputs: InputInterface[] = [];

    public formFieldStateEnum = FormFieldStateEnum;

    private subscriptions: Subscription = new Subscription();

    constructor(private changeDetectorRef: ChangeDetectorRef) {}

    public ngOnChanges(): void {
        setTimeout(() =>
            this.inputs.forEach((input: InputInterface) => {
                input.ngControl.control?.updateValueAndValidity();
                this.subscriptions.add(
                    input.ngControl.control?.statusChanges?.subscribe(() => this.changeDetectorRef.detectChanges())
                );
            })
        );
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public get state(): FormFieldStateEnum {
        const controls = this.inputs
            .filter((input: InputInterface) => !!input.ngControl)
            .map((input: InputInterface) => input.ngControl.control as UntypedFormControl);

        if (!controls.length) {
            return FormFieldStateEnum.None;
        }

        if (controls.some((control: UntypedFormControl) => control.pending)) {
            return FormFieldStateEnum.IsPending;
        }

        if (controls.some((control: UntypedFormControl) => control.touched && control.invalid)) {
            return FormFieldStateEnum.HasError;
        }

        if (controls.every((control: UntypedFormControl) => control.valid)) {
            return FormFieldStateEnum.HasSuccess;
        }

        return FormFieldStateEnum.None;
    }
}
