import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucIconUiModule } from '../icon-ui';
import { WucBalloonComponent } from './balloon.component';

@NgModule({
    declarations: [WucBalloonComponent],
    exports: [WucBalloonComponent],
    imports: [CommonModule, WucIconUiModule],
})
export class WucBalloonModule {}
