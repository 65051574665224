import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucButtonModule } from '../button';
import { WucIconUiModule } from '../icon-ui';
import { WucTableRowComponent } from './table-row.component';

@NgModule({
    imports: [CommonModule, WucIconUiModule, WucButtonModule],
    declarations: [WucTableRowComponent],
    exports: [WucTableRowComponent],
})
export class WucTableRowModule {}
