import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucIconUiModule } from '../icon-ui';
import { WucAccordionItemComponent } from './accordion-item.component';
import { WucIconModule } from '../icon';

@NgModule({
    declarations: [WucAccordionItemComponent],
    imports: [WucIconUiModule, WucIconModule, CommonModule],
    exports: [WucAccordionItemComponent],
})
export class WucAccordionItemModule {}
