<div *ngIf="isVideoVisible" class="wuc-video">
  <ng-container *ngIf="videoImage">
    <wuc-picture [imageDesktop]="videoImage"></wuc-picture>
  </ng-container>
  <ng-container *ngIf="!videoImage">
    <div class="wuc-video__circle">
      <wuc-icon-ui icon="right-open" size="xl" type="white"></wuc-icon-ui>
    </div>
  </ng-container>
</div>
<div class="wuc-video__no-video-component" [class.wuc-video__no-video-component--hidden]="isVideoVisible">
  <ng-container #noVideoContent></ng-container>
</div>
