import { Component, Input, TemplateRef, ViewChild } from '@angular/core';

/**
 * @deprecated use wuc-input-alert.
 */
@Component({
    selector: 'wuc-form-error',
    templateUrl: 'form-error.component.html',
    styleUrls: ['form-error.component.scss'],
})
export class WucFormErrorComponent {
    @Input() public errorMessages?: string[];

    @ViewChild('errorTemplate', { read: TemplateRef })
    public errorTemplate!: TemplateRef<HTMLElement>;
}
