import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucBalloonModule, WucPictureModule } from '../../common';
import { WucChatbotAnswerModule } from '../chatbot-answer';
import { WucChatbotListComponent } from './chatbot-list.component';

@NgModule({
    declarations: [WucChatbotListComponent],
    exports: [WucChatbotListComponent],
    imports: [CommonModule, WucChatbotAnswerModule, WucBalloonModule, WucPictureModule],
})
export class WucChatbotListModule {}
