import { NgModule } from '@angular/core';
import { WucBalloonModule } from '../../common';
import { WucFormFieldLabelInfoComponent } from './form-field-label-info.component';

@NgModule({
    declarations: [WucFormFieldLabelInfoComponent],
    exports: [WucFormFieldLabelInfoComponent],
    imports: [WucBalloonModule],
})
export class WucFormFieldLabelInfoModule {}
