<wuc-card
  #cardRef
  class="wuc-input-radio__card"
  (selected)="onCardClick()"
  [clickable]="!isDisabled"
  [borderColor]="formControl.value === value ? 'primary' : undefined"
  borderWidth="small"
>
  <wuc-input-radio
    [formControl]="formControl"
    [name]="name || ''"
    [value]="value"
    [testId]="testId"
    [inputId]="inputId"
    [attr.aria-label]="ariaLabel"
    [isChildOfInput]="true"
  >
    <ng-content></ng-content>
  </wuc-input-radio>
  <ng-content select="[slot=body]"></ng-content>
</wuc-card>
