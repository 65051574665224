import { Component, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { IconGeneralIcon } from '../../common/icon/icon-general.icon';
import { WucInputBaseComponent } from '../input-base.component';
import { InputInterface } from '../input.interface';

@Component({
    selector: 'wuc-input-radio-tile[icon][text]',
    templateUrl: 'input-radio-tile.component.html',
    providers: [
        {
            provide: WucInputBaseComponent,
            useExisting: WucInputRadioTileComponent,
        },
    ],
    // eslint-disable-next-line
    inputs: WucInputBaseComponent.inputs,
    styleUrls: ['input-radio-tile.component.scss'],
})
export class WucInputRadioTileComponent
    extends WucInputBaseComponent
    implements ControlValueAccessor, InputInterface, OnInit, OnDestroy
{
    @Input() public value!: string | {};
    @Input() public icon!: IconGeneralIcon;
    @Input() public text!: string;
    @Input() public size: 'default' | 'large' = 'default';

    public static selector = 'wuc-input-radio-card';
    public static nextId = 0;
    public readonly id: string;
    public formControl = new UntypedFormControl();
    public isDisabled: boolean = false;

    private subscriptions: Subscription = new Subscription();

    constructor(@Optional() public override ngControl: NgControl) {
        super(ngControl);
        this.id = `${WucInputRadioTileComponent.selector}-${WucInputRadioTileComponent.nextId++}`;
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    public ngOnInit(): void {
        if (this.ngControl.control) {
            this.subscriptions.add(
                this.ngControl.control.valueChanges.subscribe((value) => {
                    if (this.value !== value) {
                        this.formControl.setValue(value);
                    }
                })
            );
        }
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public onChange!: (_: string | {}) => void;
    public onTouched!: () => void;

    public registerOnChange(onChange: (value: string | {}) => void): void {
        this.onChange = onChange;
    }

    public registerOnTouched(onTouched: () => void): void {
        this.onTouched = onTouched;
    }

    public writeValue(value: string | {}): void {
        this.formControl.setValue(value);
    }

    public onValueChange(): void {
        this.onChange(this.value);
        this.onTouched();
    }

    public onCardClick(): void {
        this.formControl.setValue(this.value);
        this.onValueChange();
    }
}
