<table class="wuc-table" [class.table--full-width]="isFullWidth">
  <thead *ngIf="headerRows?.length">
    <ng-container *ngFor="let row of headerRows">
      <tr class="table-row" [class.wuc-table-row--border-bottom]="row.border === 'bottom'"
          [class.wuc-table-row--border-thin]="row.borderSize === 'thin'"
          [class.wuc-table-row--border-normal]="row.borderSize === 'normal'"
          [class.wuc-table-row--border-thick]="row.borderSize === 'thick'">
        <ng-content *ngTemplateOutlet="row.tableRowHeader"></ng-content>
      </tr>
    </ng-container>
  </thead>
  <tbody *ngIf="contentRows?.length">
    <ng-container *ngFor="let row of contentRows">
      <tr
        class="table-row"
        [class.wuc-table-row--border-bottom]="row.border === 'bottom' && !row.showExpand"
        [class.wuc-table-row--border-thin]="row.borderSize === 'thin'"
        [class.wuc-table-row--border-normal]="row.borderSize === 'normal'"
        [class.wuc-table-row--border-thick]="row.borderSize === 'thick'"
        (click)="toggleExpand(row)"
      >
        <ng-content *ngTemplateOutlet="row.tableRowBody"></ng-content>
      </tr>

      <ng-container *ngFor="let expandRow of row.rows; let first = first; let last = last">
        <ng-container *ngIf="row.showExpand">
          <tr>
            <ng-content
              *ngTemplateOutlet="expandRow.tableRowExpanded; context: { first: first, last: last }"
            ></ng-content>
          </tr>
        </ng-container>
      </ng-container>
    </ng-container>
  </tbody>
</table>
