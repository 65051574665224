import {
    AfterContentInit,
    ChangeDetectionStrategy,
    Component,
    ContentChildren,
    Input,
    OnChanges,
    OnDestroy,
    QueryList,
} from '@angular/core';
import { Subscription } from 'rxjs';
// Do not import from index, creates a cyclic import
import { AccordionItemIconsEnum } from '../accordion-item/accordion-item-icons.enum';
import { WucAccordionItemComponent } from '../accordion-item/accordion-item.component';
import { WucAccordionOptionsInterface } from './accordion-options.interface';
import { IconGeneralIcon } from '../icon';

@Component({
    selector: 'wuc-accordion',
    templateUrl: 'accordion.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['accordion.scss']
})
export class WucAccordionComponent implements AfterContentInit, OnChanges, OnDestroy {
    @Input() public hasNumber: boolean = false;
    @Input() public accordionStyle: 'primary' | 'secondary' | 'tertiary' | 'quaternary' = 'secondary';
    @Input() public padding: 'none' | 'small' | 'medium' | 'large' | 'very-large' = 'small';
    @Input() public radius: 'small' | 'medium' = 'small';
    @Input() public numberPrefix: string | null = null;
    @Input() public icon?: AccordionItemIconsEnum;
    @Input() public customIcon?: IconGeneralIcon;
    @Input() public openOnlySelected: boolean = false;
    @Input() public onDark: boolean = false;
    @Input() public isClean: boolean = false;

    private subscriptions = new Subscription();

    @ContentChildren(WucAccordionItemComponent)
    public items: QueryList<WucAccordionItemComponent> = new QueryList<WucAccordionItemComponent>();

    public ngAfterContentInit(): void {
        this.initItems();
        this.subscriptions.add(this.items.changes.subscribe(() => this.initItems()));
    }

    public ngOnChanges(): void {
        this.updateOptions();
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private initItems(): void {
        this.items.forEach((item: WucAccordionItemComponent, index: number) => {
            item.number = index + 1;
            this.subscriptions.add(this.expandedSubscription(item));
        });
        this.updateOptions();
    }

    private updateOptions(): void {
        const options: WucAccordionOptionsInterface = {
            icon: this.icon,
            customIcon: this.customIcon,
            hasNumber: this.hasNumber,
            numberPrefix: this.numberPrefix,
            onDark: this.onDark,
            openOnlySelected: this.openOnlySelected,
            isClean: this.isClean,
        };
        this.items.forEach((item: WucAccordionItemComponent) => {
            item.accordionOptions = options;
        });
    }

    private expandedSubscription(item: WucAccordionItemComponent): Subscription {
        return item.isExpanded.subscribe(() => {
            if (this.openOnlySelected) {
                const isExpanded = item.expanded;
                this.items.forEach((accordion: WucAccordionItemComponent) => (accordion.expanded = false));
                item.expanded = !isExpanded;
            } else {
                item.expanded = !item.expanded;
            }
        });
    }
}
