import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucBalloonModule, WucIconUiModule, WucListModule, WucProgressBarModule } from '../../common';
import { WucInputPasswordComponent } from './input-password.component';

@NgModule({
    imports: [CommonModule, WucIconUiModule, WucBalloonModule, WucListModule, WucProgressBarModule],
    declarations: [WucInputPasswordComponent],
    exports: [WucInputPasswordComponent],
})
export class WucInputPasswordModule {}
