import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucSummaryTableLabelModule } from '../summary-table-label';
import { WucSummaryTableRowComponent } from './summary-table-row.component';

@NgModule({
    declarations: [WucSummaryTableRowComponent],
    exports: [WucSummaryTableRowComponent],
    imports: [CommonModule, WucSummaryTableLabelModule],
})
export class WucSummaryTableRowModule {}
