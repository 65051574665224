import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'wuc-skeleton',
    templateUrl: 'skeleton.component.html',
    styleUrls: ['skeleton.component.scss'],
})
export class WucSkeletonComponent {
    @HostBinding('style.width') @Input() public width: string = '100%';
    @HostBinding('style.height') @Input() public height: string = '3.2rem';
    @Input() public shape: 'square' | 'circle' | 'default' = 'default';
}
