import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { KeyCodeEnum } from '../../../core';
import { WucAccordionOptionsInterface } from '../accordion/accordion-options.interface';
import { AccordionItemIconsEnum } from './accordion-item-icons.enum';

@Component({
    selector: 'wuc-accordion-item',
    templateUrl: 'accordion-item.component.html',
    styleUrls: ['accordion-item.component.scss'],
})
export class WucAccordionItemComponent implements AfterViewInit {
    @ViewChild('accordionItem', { read: ElementRef }) public elementRef!: ElementRef;
    @ViewChild('accordionNumberPrefix', { read: ElementRef }) public accordionNumberPrefix!: ElementRef;
    @ViewChild('accordionNumber', { read: ElementRef }) public accordionNumber!: ElementRef;
    @ViewChild('accordionContent', { read: ElementRef }) public accordionContent!: ElementRef;

    @Output() public isExpanded = new EventEmitter<boolean>();
    @Output() public clicked = new EventEmitter<void>();

    /**
     * @deprecated use slot="title"
     */
    @Input() public accordionTitle: string = '';
    @Input() public accordionStyle: 'normal' | 'indented' = 'normal';

    public isFirstAccordion: boolean = false;
    public isLastAccordion: boolean = false;
    public number?: number;
    public expanded: boolean = false;
    public accordionItemIconsEnum = AccordionItemIconsEnum;

    private _accordionOptions: WucAccordionOptionsInterface = {
        onDark: false,
        openOnlySelected: false,
        hasNumber: false,
        numberPrefix: null,
        isClean: false,
    };

    public set accordionOptions(options: WucAccordionOptionsInterface) {
        this._accordionOptions = options;
        this.changeDetectorRef.detectChanges();
    }

    public get accordionOptions(): WucAccordionOptionsInterface {
        return this._accordionOptions;
    }

    constructor(private changeDetectorRef: ChangeDetectorRef) {}

    public setWidthNumberPrefix(): void {
        if (this.accordionOptions.numberPrefix !== null && this.accordionOptions.numberPrefix !== '') {
            this.accordionNumber.nativeElement.style.width = `${
                this.accordionNumberPrefix.nativeElement.offsetWidth + 16
            }px`;
        }
    }

    public setWidthContent(): void {
        if (this.accordionOptions.numberPrefix && this.accordionContent) {
            this.accordionContent.nativeElement.style.paddingLeft = `${
                this.accordionNumberPrefix.nativeElement.offsetWidth + 36
            }px`;
        }
    }

    public ngAfterViewInit(): void {
        const element = this.elementRef.nativeElement as HTMLElement;
        /* istanbul ignore next */
        this.isFirstAccordion = !element.parentElement?.previousElementSibling;
        /* istanbul ignore next */
        this.isLastAccordion = !element.parentElement?.nextElementSibling;
        this.changeDetectorRef.detectChanges();

        // Set the width of the number prefix. This will make sure all are equal width (each number has a different width, this is needed to fix that).
        // We need to wait a little bit because the accordionOptions are not available yet in ngAfterViewInit because of the way this information is passed.
        setTimeout(() => {
            this.setWidthNumberPrefix();
        }, 10);
    }

    public toggleIsExpanded(): void {
        this.isExpanded.emit(this.expanded);
        this.clicked.emit();
        this.changeDetectorRef.detectChanges();

        // Set width of content after expanding. This is needed because the width of the number prefix is not predefined (developer can enter anything).
        this.setWidthContent();
    }

    public onKeyPress(keyboardEvent: KeyboardEvent): void {
        if (keyboardEvent.key === KeyCodeEnum.Enter) {
            this.toggleIsExpanded();
        }
    }
}
