<div
  class="wuc-input-text wuc-input-container wuc-input-container--border"
  [class.wuc-input-container--has-error]="hasError"
>
  <input
    #input
    type="text"
    class="wuc-input"
    [class.input--error]="hasError"
    [class.input--placeholder-small]="isPlaceholderSmall"
    [attr.autocomplete]="autocomplete"
    [attr.inputmode]="inputmode"
    [attr.aria-label]="ariaLabel"
    [attr.id]="name ?? null"
    [attr.e2e]="e2e ?? null"
    [placeholder]="placeholder"
    [(ngModel)]="value"
    (input)="onInput($event)"
    (blur)="onBlur(input.value)"
    [disabled]="isDisabled"
  />
</div>
