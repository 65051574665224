import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WucIconUiModule } from '../../common';
import { WucFormFieldStateModule } from '../form-field-state';
import { WucSelectOptionModule } from '../select-option';
import { WucSelectComponent } from './select.component';

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        WucSelectOptionModule,
        WucIconUiModule,
        WucFormFieldStateModule,
    ],
    declarations: [WucSelectComponent],
    exports: [WucSelectComponent, WucSelectOptionModule],
})
export class WucSelectModule {}
