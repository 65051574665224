import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WucClickOutsideModule } from '../../../core';
import { WucIconUiModule } from '../../common';
import { WucFormFieldStateModule } from '../form-field-state';
import { WucInputDateComponent } from './input-date.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        WucClickOutsideModule,
        WucFormFieldStateModule,
        WucIconUiModule,
    ],
    declarations: [WucInputDateComponent],
    exports: [WucInputDateComponent],
})
export class WucInputDateModule {}
