import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucReplacePipeModule, WucSafeHtmlModule } from '../../../shared';
import {
    WucBalloonModule,
    WucIconUiModule,
    WucLinkModule,
    WucListModule,
    WucPictureModule,
    WucVideoModule,
} from '../../common';
import { WucChatbotEscalationModule } from '../chatbot-escalation';
import { WucChatbotAnswerComponent } from './chatbot-answer.component';

@NgModule({
    imports: [
        CommonModule,
        WucPictureModule,
        WucBalloonModule,
        WucIconUiModule,
        WucLinkModule,
        WucVideoModule,
        WucListModule,
        WucChatbotEscalationModule,
        WucSafeHtmlModule,
        WucReplacePipeModule,
    ],
    declarations: [WucChatbotAnswerComponent],
    exports: [WucChatbotAnswerComponent],
})
export class WucChatbotAnswerModule {}
