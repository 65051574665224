<div
  class="wuc-display-table-row wuc-display-table-row--border"
  [class.wuc-display-table-row--border-bold]="hasBoldBorder"
>
  <div class="wuc-display-table-row__label" *ngIf="label">
    <ng-content *ngTemplateOutlet="label.templateRef"></ng-content>
  </div>
  <div class="wuc-display-table-row__value" *ngIf="value">
    <ng-content *ngTemplateOutlet="value.templateRef"></ng-content>
  </div>
</div>
