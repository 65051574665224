import { Component, Input } from '@angular/core';
import { IconUiIcon } from './icon-ui.icon';
import { IconUiSize } from './icon-ui.size';
import { IconUiType } from './icon-ui.type';

@Component({
    selector: 'wuc-icon-ui',
    templateUrl: 'icon-ui.component.html',
    styleUrls: ['icon-ui.component.scss'],
})
export class WucIconUiComponent {
    @Input() public icon: IconUiIcon = 'car';
    @Input() public size: IconUiSize = 'xs';
    @Input() public type?: IconUiType = 'primary';
}
