import { AfterContentInit, Component, ContentChildren, EventEmitter, Input, Output, QueryList } from '@angular/core';
import { WucTabsetItemComponent } from '../tabset-item';

@Component({
    selector: 'wuc-tabset',
    templateUrl: 'tabset.component.html',
    styleUrls: [
        'tabset.component.scss',
        'tabset-primary.component.scss',
        'tabset-secondary.component.scss',
        'tabset-tertiary.component.scss',
        'tabset-quartiary.component.scss',
    ],
})
export class WucTabsetComponent implements AfterContentInit {
    @Input() public tabsetStyle: 'primary' | 'secondary' | 'tertiary' | 'quartiary' = 'primary';
    @Input() public selectedTabIndex: number = 0;

    @Output() public selectedTabIndexChange: EventEmitter<number> = new EventEmitter<number>();

    @ContentChildren(WucTabsetItemComponent)
    public tabs: QueryList<WucTabsetItemComponent> = new QueryList<WucTabsetItemComponent>();

    public selectedTab?: WucTabsetItemComponent;

    public ngAfterContentInit(): void {
        setTimeout(() => {
            this.selectedTab = this.tabs.get(this.selectedTabIndex);
        });
    }

    public onTabSelect(index: number): void {
        this.selectedTab = this.tabs.get(index);
        this.selectedTabIndex = index;
        this.selectedTabIndexChange.emit(index);
    }
}
