import { CurrencyPipe } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'wuc-price',
    templateUrl: 'price.component.html',
    styleUrls: ['price.component.scss'],
})
export class WucPriceComponent {
    public priceParts: string[] = [];
    @Input() public size: 'xs' | 's' | 'm' | 'l' | 'xl' = 'm';
    @Input() public hasStriketrough: boolean = false;
    @Input() public locale: string = 'nl';
    @Input() public set price(value: number) {
        const transformedPrice = this.currencyPipe.transform(value, '', '', undefined, this.locale);
        if (transformedPrice) {
            this.priceParts = transformedPrice.split(',');
        }
    }

    constructor(private currencyPipe: CurrencyPipe) {}
}
