<ng-template #template>
  <ng-container *ngIf="parentTable?.isMobileView && parentTable?.extraInfoColumnKey">
    <ng-container *ngTemplateOutlet="extraInfoCell.templateRef"></ng-container>
  </ng-container>
  <ng-container *ngFor="let cell of cells">
    <ng-container *ngTemplateOutlet="cell.templateRef"></ng-container>
  </ng-container>
</ng-template>

<wuc-compare-table-cell #extraInfoCell [isExtraInfoCell]="true">
  <wuc-compare-table-cell-extra-info>
    <ng-content select="[slot=extraInfo]"></ng-content>
  </wuc-compare-table-cell-extra-info>
</wuc-compare-table-cell>
