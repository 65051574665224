import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucSafeHtmlModule } from '../../../shared';
import {
    WucBalloonModule,
    WucButtonModule,
    WucCardModule,
    WucDropTriggerModule,
    WucIconModule,
    WucIconUiModule,
    WucPriceModule,
    WucTextHighlightModule,
} from '../../common';
import { WucSwipeHandAnimatedModule } from '../swipe-hand-animated';
import { WucCompareTableComponent } from './compare-table.component';
import {
    WucCompareTableCellComponent,
    WucCompareTableCellExtraInfoComponent,
    WucCompareTableCellItemComponent,
    WucCompareTableColumnComponent,
} from './components';

@NgModule({
    imports: [
        CommonModule,
        WucCardModule,
        WucIconModule,
        WucIconUiModule,
        WucSwipeHandAnimatedModule,
        WucPriceModule,
        WucSafeHtmlModule,
        WucDropTriggerModule,
        WucBalloonModule,
        WucButtonModule,
        WucTextHighlightModule,
    ],
    declarations: [
        WucCompareTableCellExtraInfoComponent,
        WucCompareTableCellItemComponent,
        WucCompareTableCellComponent,
        WucCompareTableColumnComponent,
        WucCompareTableComponent,
    ],
    exports: [
        WucCompareTableCellItemComponent,
        WucCompareTableCellComponent,
        WucCompareTableColumnComponent,
        WucCompareTableComponent,
    ],
})
export class WucCompareTableModule {}
