<ng-template #wucListItem>
  <li
    *ngIf="listOptions"
    class="wuc-list-icon-item"
    [ngClass]="{
      'wuc-list-icon-item--expanded': listOptions.expanded,
      'wuc-list-icon-item--padded': listOptions.padded && !noPadding,
      'wuc-list-icon-item--lined': listOptions.lined,
      'wuc-list-icon-item--line-dark': listOptions.lineColor === 'dark'
    }"
  >
    <div class="wuc-list-icon-item__custom-icon">
      <ng-content select="[wucListItemCustomIcon]"></ng-content>
    </div>
    <div class="wuc-list-icon-item__content">
      <ng-content></ng-content>
    </div>
  </li>
</ng-template>
