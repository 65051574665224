import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class TemporaryDateService {
    /**
     * Returns the current year as number
     */
    public static get currentYearWithoutCentury(): number {
        return Number(new Date().getFullYear().toString().slice(2));
    }

    /**
     * Returns yesterday as string in 'yyyy-mm-dd' format
     */
    public static get yesterday(): string {
        return TemporaryDateService.dateToString(new Date(Date.now() - 3600 * 1000 * 24));
    }

    /**
     * Returns today as string in 'yyyy-mm-dd' format
     */
    public static get today(): string {
        return TemporaryDateService.dateToString(new Date());
    }

    /**
     * Returns tomorrow as string in 'yyyy-mm-dd' format
     */
    public static get tomorrow(): string {
        return TemporaryDateService.dateToString(new Date(Date.now() + 3600 * 1000 * 24));
    }

    /**
     * Returns true is passed date is today
     * @param date Date to check
     */
    public static isDateToday(date: Date): boolean {
        if (!date) {
            return false;
        }
        const today = new Date();
        return (
            date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear()
        );
    }

    /**
     * Returns given date as string in 'yyyy-mm-dd' format
     * @param date
     * @param seperator
     */
    public static dateToString(date: Date, seperator: string = '-'): string {
        if (!date) {
            return '';
        }
        return `${date.getFullYear()}${seperator}${TemporaryDateService.pad(date.getMonth() + 1)}${seperator}${TemporaryDateService.pad(
            date.getDate()
        )}`;
    }

    /**
     * Returns given date as 'dd-mm-yyyy'
     * @param date Date in 'yyyy-mm-dd' or 'dd-mm-yyyy' format
     */
    public static dateStringToDayFirst(date: string): string {
        if (/^\d{4}\D\d{2}\D\d{2}$/.test(date)) {
            return TemporaryDateService.reverseDateString(date);
        }
        return date;
    }

    /**
     * Returns given date as 'yyyy-mm-dd'
     * @param date Date in 'yyyy-mm-dd' or 'dd-mm-yyyy' format
     */
    public static dateStringToYearFirst(date: string): string {
        if (/^\d{2}\D\d{2}\D\d{4}$/.test(date)) {
            return TemporaryDateService.reverseDateString(date);
        }
        return date;
    }

    /**
     * Returns given date in reverse year-month-day order
     * @param date Date in 'yyyy-mm-dd' or 'dd-mm-yyyy' format
     */
    public static reverseDateString(date: string): string {
        const seperator: string = TemporaryDateService.getSeperator(date);
        return date.split(seperator).reverse().join(seperator);
    }

    /**
     * Returns the given number as string with an optional '0' as prefix
     * @param number
     */
    public static pad(number: number): string {
        return number.toString().padStart(2, '0');
    }

    /**
     * Returns the seperator used in the given date string
     * @param date
     */
    public static getSeperator(date: string): string {
        return date?.match(/[-.]/)?.[0] || '-';
    }

    /**
     * Replaces the seperator with the given one
     * @param date
     * @param seperator
     */
    public static replaceSeperator(date: string, seperator: string = '-'): string {
        return date?.replace(/[-.]/g, seperator);
    }

}
