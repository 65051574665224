import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { IconGeneralIcon } from '../../common';

@Component({
    selector: 'wuc-tabset-item[name]',
    templateUrl: 'tabset-item.component.html',
})
export class WucTabsetItemComponent {
    @Input() public name!: string;
    @Input() public id!: string;
    @Input() public icon?: IconGeneralIcon;
    @Input() public isHidden: boolean = false;

    @ViewChild('template', { read: TemplateRef })
    public template!: TemplateRef<HTMLElement>;
}
