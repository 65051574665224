import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucIconUiModule, WucLinkModule } from '../../common';
import { WucStepsComponent } from './steps.component';

@NgModule({
    imports: [CommonModule, WucIconUiModule, WucLinkModule],
    declarations: [WucStepsComponent],
    exports: [WucStepsComponent],
})
export class WucStepsModule {}
