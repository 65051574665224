import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { KeyCodeEnum } from '../../../core';
import { WucInputBaseComponent } from '../../forms';

@Component({
    selector: 'wuc-chatbot-input[buttonText]',
    templateUrl: 'chatbot-input.component.html',
    styleUrls: ['chatbot-input.component.scss'],
})
export class WucChatbotInputComponent implements OnInit {
    @ViewChild('input') private inputComponent!: WucInputBaseComponent;

    @Input() public isDisabled: boolean = false;
    @Input() public isMobile: boolean = false;
    @Input() public buttonText!: string;
    @Input() public placeholder: string = '';
    @Input() public maxNumberOfCharactersText?: string | undefined;
    @Input() public maxNumberOfCharacters?: number | undefined;

    @Output() public readonly question: EventEmitter<string> = new EventEmitter<string>();

    @HostListener('keydown', ['$event'])
    private onKeyDown(event: KeyboardEvent): void {
        if (event.code === KeyCodeEnum.Enter) {
            this.onSend();
        }
    }

    public ngOnInit(): void {
        this.setFocus();
    }

    public inputControl: FormControl<string | null> = new FormControl<string>('', [
        Validators.required,
        Validators.pattern(/^.*\S.*$/),
    ]);

    public onSend(): void {
        if (!this.isDisabled) {
            this.question.emit((this.inputControl.value as string)?.trim());
            this.inputControl.reset();
        }
    }

    public setFocus(): void {
        if (this.inputComponent) {
            this.inputComponent.setFocus();
        }
    }
}
