import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
    name: 'wucSafeHtml',
})
export class WucSafeHtmlPipe implements PipeTransform {
    constructor(private domSanitizer: DomSanitizer) {}

    public transform(text: string): SafeHtml {
        if (!text) {
            return '';
        }
        return this.domSanitizer.bypassSecurityTrustHtml(text);
    }
}
