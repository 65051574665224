import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucButtonModule } from '../button';
import { WucTableCellModule } from '../table-cell';
import { WucTableRowModule } from '../table-row';
import { WucTableComponent } from './table.component';

@NgModule({
    imports: [CommonModule, WucTableRowModule, WucTableCellModule, WucButtonModule],
    declarations: [WucTableComponent],
    exports: [WucTableComponent, WucTableRowModule, WucTableCellModule],
})
export class WucTableModule {}
