import { Component, ElementRef, Input, OnChanges, Optional, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { KeyCodeEnum } from '../../../core';
import { WucInputBaseComponent } from '../input-base.component';
import { InputInterface } from '../input.interface';
import { SelectAutocompleteItemInterface } from './select-autocomplete-item.interface';

@Component({
    selector: 'wuc-select-autocomplete',
    templateUrl: 'select-autocomplete.component.html',
    styleUrls: ['select-autocomplete.component.scss'],
    providers: [
        {
            provide: WucInputBaseComponent,
        },
    ],
    // eslint-disable-next-line
    inputs: WucInputBaseComponent.inputs,
})
export class WucSelectAutocompleteComponent
    extends WucInputBaseComponent
    implements ControlValueAccessor, InputInterface, OnChanges
{
    public static PAGE_SIZE = 7;

    @ViewChild('inputElement', { static: false })
    public inputElement!: ElementRef;

    @ViewChild('filteredListElement', { static: false })
    public filteredListElement!: ElementRef;

    @Input() public id: string = '';
    @Input() public placeholder: string = '';
    @Input() public notFoundText: string = '';
    @Input() public items: SelectAutocompleteItemInterface[] = [];

    public filteredItems: SelectAutocompleteItemInterface[] = [];
    public filteredItemsCursorIndex: number = -1;
    public isDisabled: boolean = false;
    public value: SelectAutocompleteItemInterface | null = null;
    public searchValue: string = '';
    public onChange!: (value: SelectAutocompleteItemInterface | null) => void;
    public onTouched!: () => void;
    public isOpen: boolean = false;

    private hadFirstClick: boolean = false;

    constructor(@Optional() public override ngControl: NgControl) {
        super(ngControl);
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['items']) {
            if (this.value && this.items?.length) {
                const id = this.value.id;
                const item = this.items.find((item: SelectAutocompleteItemInterface) => item.id === id);
                if (item) {
                    this.searchValue = item.name;
                } else {
                    this.searchValue = '';
                    this.clearValue();
                }
            }
        }
    }

    public toggle(event: MouseEvent): void {
        event.stopPropagation();
        if (this.isOpen) {
            this.close();
        } else {
            this.open();
            this.focusOnInput();
            this.onTouched();
        }
    }

    public open(): void {
        this.isOpen = true;
        this.filterList();
    }

    public close(): void {
        if (this.isOpen) {
            this.isOpen = false;
        }
    }

    public filterList(): void {
        if (this.value) {
            this.filteredItems = this.items.slice();
            return;
        }

        if (this.items && this.searchValue) {
            this.filteredItems = this.items.filter(
                (item) => item.name.toLowerCase().indexOf(this.searchValue.toLowerCase()) > -1
            );
        } else {
            this.filteredItems = this.items.slice();
        }

        this.filteredItemsCursorIndex = -1;
    }

    public onItemSelect(item: SelectAutocompleteItemInterface): void {
        if (!item || this.value?.id === item.id) {
            return;
        }

        this.searchValue = item.name;
        this.value = item;

        this.onChange(item);
        this.close();
        this.focusOnInput();
    }

    public onUserInput(userInput: string): void {
        this.clearValue();
        this.isOpen = !this.isDisabled && !!userInput;
        this.searchValue = userInput;
        this.filterList();
        this.onTouched();
    }

    public onKeyDown(event: KeyboardEvent): void {
        if (!this.isOpen && event.code === KeyCodeEnum.ArrowDown) {
            // this.clearValue();
            this.filterList();
            this.isOpen = true;
        }

        if (this.isOpen) {
            const maxIndex = this.filteredItems.length - 1;

            let isKeyHandled = true;

            switch (event.code) {
            case KeyCodeEnum.ArrowDown:
                this.filteredItemsCursorIndex++;
                break;
            case KeyCodeEnum.ArrowUp:
                this.filteredItemsCursorIndex--;
                break;
            case KeyCodeEnum.PageDown:
                this.filteredItemsCursorIndex =
                        this.filteredItemsCursorIndex + WucSelectAutocompleteComponent.PAGE_SIZE;
                break;
            case KeyCodeEnum.PageUp:
                this.filteredItemsCursorIndex =
                        this.filteredItemsCursorIndex - WucSelectAutocompleteComponent.PAGE_SIZE;
                break;
            case KeyCodeEnum.Home:
                this.filteredItemsCursorIndex = 0;
                break;
            case KeyCodeEnum.End:
                this.filteredItemsCursorIndex = maxIndex;
                break;
            case KeyCodeEnum.Tab:
            case KeyCodeEnum.Escape:
                this.onControlLeave();
                this.close();
                break;
            case KeyCodeEnum.Enter:
                this.onItemSelect(this.filteredItems[this.filteredItemsCursorIndex]);
                break;
            default:
                isKeyHandled = false;
            }

            if (isKeyHandled) {
                event.stopPropagation();
                event.preventDefault();

                if (this.filteredItemsCursorIndex < 0) {
                    this.filteredItemsCursorIndex = 0;
                }
                if (this.filteredItemsCursorIndex > maxIndex) {
                    this.filteredItemsCursorIndex = maxIndex;
                }

                this.scrollItemInView(this.filteredItemsCursorIndex);
            }
        }
    }

    public onClickedOutside(): void {
        this.onControlLeave();
        this.close();
    }

    private scrollItemInView(selectedIdx: number): void {
        if (selectedIdx > -1) {
            const selectedItemElement: HTMLElement = this.filteredListElement.nativeElement.children[selectedIdx];
            const options: ScrollIntoViewOptions = {
                behavior: 'auto',
                block: 'nearest',
                inline: 'nearest',
            };
            selectedItemElement.scrollIntoView(options);
        }
    }

    private clearValue(): void {
        this.value = null;
        this.onChange(null);
    }

    // Update value from API; API => view
    public writeValue(value: SelectAutocompleteItemInterface): void {
        this.value = value;
    }

    // Update value from view "on change"; View => API
    public registerOnChange(fn: (value: SelectAutocompleteItemInterface | null) => void): void {
        this.onChange = fn;
    }

    // Update control "on blur"; View => API
    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    // Set disabled state from API
    public setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    public focusOnInput(): void {
        this.inputElement.nativeElement.focus();
    }

    private onControlLeave(): void {
        if (!this.value) {
            this.searchValue = '';
        }
    }

    public onClick(): void {
        if (this.hadFirstClick) {
            this.open();
        }
        this.hadFirstClick = true;
    }

    public onFocusIn(): void {
        this.hadFirstClick = false;
    }
}
