<div
  class="wuc-compare-table-cell-item"
  [ngClass]="{ 'wuc-compare-table-cell-item--column-label ': isColumnLabel }"
  [ngSwitch]="item.type"
>
  <ng-container *ngSwitchCase="'text'">
    <span [innerHtml]="asString(item.value) | wucSafeHtml"></span>
  </ng-container>
  <ng-container *ngSwitchCase="'text-large'">
    <span class="wuc-compare-table-cell-item--text-large" [innerHtml]="asString(item.value) | wucSafeHtml"></span>
  </ng-container>
  <ng-container *ngSwitchCase="'text-primary'">
    <wuc-text-highlight type="secondary">
      <span [innerHtml]="asString(item.value) | wucSafeHtml"></span>
    </wuc-text-highlight>
  </ng-container>
  <ng-container *ngSwitchCase="'price'">
    <wuc-price [price]="asNumber(item.value)"></wuc-price>
  </ng-container>
  <ng-container *ngSwitchCase="'price-strikethrough'">
    <wuc-price [price]="asNumber(item.value)" size="xs" [hasStriketrough]="true"></wuc-price>
  </ng-container>
  <ng-container *ngSwitchCase="'price-small'">
    <wuc-price [price]="asNumber(item.value)" size="xs"></wuc-price>
  </ng-container>
  <ng-container *ngSwitchCase="'boolean'">
    <wuc-icon-ui
      size="s"
      [type]="item.value ? 'primary' : 'secondary'"
      [icon]="item.value ? 'check-closed' : 'cross-closed'"
    ></wuc-icon-ui>
  </ng-container>
  <wuc-drop-trigger *ngIf="item.info" [container]="dropContainerElement">
    <wuc-button type="clean" iconType="primary">
      <wuc-icon-ui icon="information"></wuc-icon-ui>
    </wuc-button>
    <wuc-balloon>
      <div class="wuc-compare-table-cell-item__drop-trigger-content" [innerHtml]="item.info | wucSafeHtml"></div>
    </wuc-balloon>
  </wuc-drop-trigger>
</div>
