<div
  class="wuc-input-container wuc-input-container--border"
  [class.wuc-input-container--has-error]="hasError"
  wucClickOutside
  (clickedOutside)="onClickedOutside()"
>
  <input
    class="wuc-input wuc-input-date__input-inactive"
    [class.wuc-input-date__input-inactive--hidden]="isActive"
    [class.wuc-input-date__input-inactive--is-disabled]="isDisabled"
    (focusin)="onActivate()"
    [placeholder]="placeholder"
    [formControl]="inactiveControl"
  />
  <div class="wuc-input wuc-input-date__input-wrapper" [class.wuc-input-date__input-wrapper--hidden]="!isActive">
    <input
      #inputDay
      type="text"
      class="wuc-input wuc-input-date__input wuc-input-date__input-day"
      [placeholder]="placeholderDay"
      [attr.autocomplete]="autocomplete"
      [attr.inputmode]="inputmode"
      [attr.aria-label]="ariaLabel"
      [attr.id]="name ? name + '-day' : null"
      [attr.e2e]="e2e ?? null"
      maxlength="2"
      [(ngModel)]="valueDay"
      (beforeinput)="onBeforeInputDay($event)"
      (keydown)="onKeyDownDay($event)"
      (input)="onInputDay($event)"
      (focusin)="onFocusIn()"
      (focusout)="onFocusOutDay()"
    />
    <span class="wuc-input-date__separator">{{ visualDateSeparator }}</span>
    <input
      #inputMonth
      type="text"
      class="wuc-input wuc-input-date__input wuc-input-date__input-month"
      [placeholder]="placeholderMonth"
      [attr.autocomplete]="autocomplete"
      [attr.inputmode]="inputmode"
      [attr.aria-label]="ariaLabel"
      [attr.id]="name ? name + '-month' : null"
      [attr.e2e]="e2e ?? null"
      maxlength="2"
      [(ngModel)]="valueMonth"
      (beforeinput)="onBeforeInputMonth($event)"
      (keydown)="onKeyDownMonth($event)"
      (input)="onInputMonth($event)"
      (focusin)="onFocusInMonth()"
      (focusout)="onFocusOutMonth()"
    />
    <span class="wuc-input-date__separator">{{ visualDateSeparator }}</span>
    <input
      #inputYear
      type="text"
      class="wuc-input wuc-input-date__input wuc-input-date__input-year"
      [placeholder]="placeholderYear"
      [attr.autocomplete]="autocomplete"
      [attr.inputmode]="inputmode"
      [attr.aria-label]="ariaLabel"
      [attr.id]="name ? name + '-year' : null"
      [attr.e2e]="e2e ?? null"
      maxlength="4"
      [(ngModel)]="valueYear"
      (beforeinput)="onBeforeInputYear($event)"
      (keydown)="onKeyDownYear($event)"
      (input)="onInputYear($event)"
      (focusin)="onFocusInYear()"
      (focusout)="onFocusOutYear()"
    />
    <div class="wuc-input-date__filler" (click)="onInputFillerClick()">&nbsp;</div>
  </div>
</div>
