import { Component, Input } from '@angular/core';

@Component({
    selector: 'wuc-display-field',
    templateUrl: 'display-field.component.html',
    styleUrls: ['display-field.component.scss'],
})
export class WucDisplayFieldComponent {
    @Input() public boldLabel: boolean = false;
    @Input() public hideAction: boolean = false;
}
