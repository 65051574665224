import { Component, Input } from '@angular/core';

@Component({
    selector: 'wuc-summary-table',
    templateUrl: 'summary-table.component.html',
    styleUrls: ['summary-table.component.scss'],
})
export class WucSummaryTableComponent {
    @Input()
    public hasBorder: boolean = false;
}
