import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    Input,
    QueryList,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { BackgroundColorType } from '../../../../../core';
import { WucCompareTableInterface } from '../../compare-table.interface';
import { WucCompareTableCellComponent } from '../compare-table-cell';
import { WucCompareTableColumnInterface } from './compare-table-column.interface';

@Component({
    selector: 'wuc-compare-table-column',
    templateUrl: 'compare-table-column.component.html',
})
export class WucCompareTableColumnComponent implements WucCompareTableColumnInterface, AfterViewInit {
    @Input() public key!: string;
    @Input() public isDisabled: boolean = false;

    @ViewChild('template', { read: TemplateRef })
    public templateRef!: TemplateRef<HTMLElement>;

    @ContentChildren(WucCompareTableCellComponent)
    public cells: QueryList<WucCompareTableCellComponent> = new QueryList<WucCompareTableCellComponent>();

    @ViewChild('extraInfoCell')
    private extraInfoCell!: WucCompareTableCellComponent;

    public parentTable!: WucCompareTableInterface;

    // referenced bij child cells to display hover border
    public hasHover: boolean = false;
    public hasShrinkAnimation: boolean = false;
    public isSmoothAnimationDisabled: boolean = false;
    public index: number = 0;

    public isHidden: boolean = false;
    public isLabelColumn: boolean = false;
    public isFixed: boolean = false;
    public offset: number = 0;
    public relativeOffset: number = 0;
    public columnOrderOffset: number = 0;
    public isLastColumn: boolean = false;

    private animationLength: number = 300;

    constructor(private changeDetectorRef: ChangeDetectorRef) {}

    public setParent(parentTable: WucCompareTableInterface): void {
        this.parentTable = parentTable;
        this.extraInfoCell.setParent(this);
        this.initColumn();
    }

    public get totalOffset(): number {
        if (!this.parentTable.isMobileView) {
            return 0;
        }
        return this.isFixed
            ? this.columnOrderOffset * this.parentTable.columnWidth
            : (this.columnOrderOffset + this.offset) * this.parentTable.columnWidth + this.relativeOffset;
    }

    public ngAfterViewInit(): void {
        this.initColumn();
    }

    public onMouseEnter(): void {
        this.hasHover = !this.parentTable.isHoverBorderHidden && !this.isLabelColumn;
    }

    public onMouseLeave(): void {
        this.hasHover = false;
    }

    public onClick(): void {
        this.parentTable.handleClick(this.key);
    }

    public get isSelected(): boolean {
        return !!this.key && this.parentTable.selectedColumnKey === this.key;
    }

    public get isBordered(): boolean {
        return (
            !this.isLabelColumn &&
            (this.parentTable.isMobileView || this.isSelected || this.hasHover || this.parentTable.isBorderVisible)
        );
    }

    public get backgroundColor(): BackgroundColorType {
        return this.isLabelColumn
            ? this.parentTable.labelColumnBackgroundColor
            : this.parentTable.dataColumnBackgroundColor;
    }

    private initColumn(): void {
        if (this.cells.length > 0) {
            this.cells.first.firstInColumn = true;
            this.cells.last.lastInColumn = true;
        }

        this.cells.forEach((cell: WucCompareTableCellComponent) => {
            cell.setParent(this);
        });
        this.changeDetectorRef.detectChanges();
    }

    public resetOffset(): void {
        this.offset = 0;
        this.relativeOffset = 0;
    }

    public startShrinkAnimation(): void {
        if (!this.isSmoothAnimationDisabled) {
            this.hasShrinkAnimation = true;
            this.callbackAfterAnimationEnds(() => (this.hasShrinkAnimation = false));
        }
    }

    public temporarilyDisableSmoothAnimation(): void {
        this.isSmoothAnimationDisabled = true;
        this.callbackAfterAnimationEnds(() => (this.isSmoothAnimationDisabled = false));
    }

    /* to hide the column during column swap animation */
    public temporarilyHide(): void {
        this.isHidden = true;
        this.callbackAfterAnimationEnds(() => (this.isHidden = false));
    }

    private callbackAfterAnimationEnds(handler: Function): void {
        setTimeout(() => handler(), this.animationLength);
    }
}
