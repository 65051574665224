import { Component, Input } from '@angular/core';
import { IconGeneralIcon } from './icon-general.icon';
import { IconGeneralSize } from './icon-general.size';
import { IconGeneralType } from './icon-general.type';

@Component({
    selector: 'wuc-icon',
    templateUrl: 'icon.component.html',
    styleUrls: ['icon.component.scss'],
})
export class WucIconComponent {
    @Input() public icon: IconGeneralIcon = 'car';
    @Input() public size: IconGeneralSize = 'm';
    @Input() public type: IconGeneralType = 'primary';
}
