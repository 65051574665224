import { Component, Input } from '@angular/core';

@Component({
    selector: 'wuc-text-highlight',
    templateUrl: 'text-highlight.component.html',
    styleUrls: ['text-highlight.component.scss'],
})
export class WucTextHighlightComponent {
    @Input() public type: 'primary' | 'secondary' = 'primary';
}
