import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { WucInputRadioModule } from '..';
import { WucIconModule, WucIconUiModule } from '../../common';
import { WucInputRadioTileComponent } from './input-radio-tile.component';

@NgModule({
    declarations: [WucInputRadioTileComponent],
    imports: [WucInputRadioModule, ReactiveFormsModule, WucIconModule, WucIconUiModule, CommonModule],
    exports: [WucInputRadioTileComponent],
})
export class WucInputRadioTileModule {}
