import { Component } from '@angular/core';

@Component({
    selector: 'wuc-slogan',
    templateUrl: 'slogan.component.html',
    styleUrls: ['slogan.component.scss'],
})
export class WucSloganComponent {
    public sloganText: string = 'We all benefit';
}
