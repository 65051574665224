<div
  class="wuc-input-container wuc-input-container--border"
  [class.wuc-input-container--has-error]="hasError"
  [class.wuc-input-container--disabled]="isDisabled"
>
  <input
    #input
    type="time"
    class="wuc-input input-time"
    [attr.autocomplete]="autocomplete"
    [attr.inputmode]="inputmode"
    [attr.aria-label]="ariaLabel"
    [attr.id]="name ?? null"
    [attr.e2e]="e2e ?? null"
    [disabled]="isDisabled"
    [ngModel]="value"
    (ngModelChange)="onValueChange($event)"
    (blur)="onTouched()"
    [class.input--error]="hasError"
  />
  <wuc-icon-ui class="wuc-input__icon" size="s" icon="clock" type="primary" (click)="input.focus()"></wuc-icon-ui>
</div>
