<div class="wuc-input-radio-card-group">
  <wuc-rack
    [columnsMobile]="columnsMobile"
    [columnsTablet]="columnsTablet"
    [columnsDesktop]="columnsDesktop"
    [gridGap]="gridGap"
  >
    <wuc-input-radio-card
      *ngFor="let item of items"
      [formControl]="formControl"
      [value]="item.id"
      [testId]="item.testId"
      [inputId]="item.name"
    >
      <span [innerHtml]="item.label | wucSafeHtml"></span>
    </wuc-input-radio-card>
  </wuc-rack>
</div>
