<div
  class="wuc-product-grade"
  [class.wuc-product-grade--sidebar]="layoutStyle === 'sidebar'"
  [class.wuc-product-grade--header-small]="layoutStyle === 'headerSmall'"
  [class.wuc-product-grade--header-large]="layoutStyle === 'headerLarge'"
  [class.wuc-product-grade--header-block]="layoutStyle === 'headerBlock'"
  [class.wuc-product-grade--animated]="hasAnimation"
  [class.wuc-product-grade--bounce-in]="rating && isInView && hasAnimation"
  [class.wuc-product-grade--hidden]="hasAnimation && !rating"
>
  <div>
    <div class="wuc-product-grade__title" [innerHtml]="title | wucSafeHtml"></div>
    <ng-container *ngIf="layoutStyle === 'headerBlock'">
      <ng-container *ngTemplateOutlet="ratingTemplate"></ng-container>
    </ng-container>
    <ng-container *ngIf="subTitle">
      <div
        *ngIf="rating; else skeletonTemplate"
        class="wuc-product-grade__sub-title"
        [class.wuc-product-grade__sub-title--as-link]="isClickable"
        [innerHtml]="subTitle | wucSafeHtml"
        (click)="onClick()"
      ></div>
    </ng-container>
  </div>
  <ng-container *ngIf="layoutStyle !== 'headerBlock'">
    <ng-container *ngTemplateOutlet="ratingTemplate"></ng-container>
  </ng-container>
</div>
<ng-template #ratingTemplate>
  <span class="wuc-product-grade__rating" [class.wuc-product-grade__rating--as-link]="isClickable" (click)="onClick()">
    <wuc-rating [rating]="rating" [size]="ratingBubbleSize"></wuc-rating>
  </span>
</ng-template>
<ng-template #skeletonTemplate>
  <div class="wuc-product-grade__sub-title-skeleton">
    <wuc-skeleton height="100%" width="50%"></wuc-skeleton>
  </div>
</ng-template>
