<div class="wuc-steps" [class.wuc-steps--animated]="isAnimated">
  <div class="wuc-steps__container">
    <div
      *ngFor="let step of steps; let index = index"
      class="wuc-steps__step"
      [class.wuc-steps__step--clickable]="step.isClickable"
      [title]="step.title"
      (click)="onStepClick(step)"
    >
      <div
        class="wuc-steps__step-number"
        [class.wuc-steps__step-number--checked]="index < currentIndex"
        [class.wuc-steps__step-number--current]="index === currentIndex"
      >
        <div *ngIf="index >= currentIndex">{{ index + 1 }}</div>
        <div *ngIf="index < currentIndex" class="wuc-steps__check-icon">
          <wuc-icon-ui icon="check-closed" type="white"></wuc-icon-ui>
        </div>
      </div>
      <div class="wuc-steps__step-title">
        <wuc-link *ngIf="step.isClickable" type="quaternary" (clicked)="onStepClick(step)">
          {{ step.label }}
        </wuc-link>
        <ng-container *ngIf="!step.isClickable">
          {{ step.label }}
        </ng-container>
      </div>
    </div>
  </div>
  <div class="wuc-steps__image">
    <ng-content></ng-content>
  </div>
</div>
