import { AfterContentInit, Component, ContentChildren, ElementRef, OnDestroy, QueryList } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { WucNavigationItemComponent } from '../navigation-item';

@Component({
    selector: 'wuc-navigation',
    templateUrl: 'navigation.component.html',
    styleUrls: ['navigation.component.scss'],
})
export class WucNavigationComponent implements AfterContentInit, OnDestroy {
    @ContentChildren(WucNavigationItemComponent)
    public items!: QueryList<WucNavigationItemComponent>;

    private subscriptions: Subscription = new Subscription();

    constructor(private elementRef: ElementRef, private router: Router) {}

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public ngAfterContentInit(): void {
        if (this.items) {
            this.items.forEach((item: WucNavigationItemComponent, index: number) => {
                item.isOpened.subscribe((isOpened: boolean) => this.handleItemOpened(index, isOpened));
                item.resetState.subscribe(() => this.handleResetState(index));
            });
            this.items.first.showDivider = false;
            this.items.first.firstItem = true;
            this.items.last.lastItem = true;

            this.subscriptions.add(
                this.router.events
                    .pipe(filter((event: any) => event instanceof NavigationEnd))
                    .subscribe(() => this.onRouteChange())
            );
        }
    }

    private handleItemOpened(itemIndex: number, isOpened: boolean): void {
        if (this.items) {
            this.items.forEach((item: WucNavigationItemComponent, index: number) => {
                item.showDivider = index !== 0;
                if (index !== itemIndex) {
                    item.close();
                }
            });
            const sibling = this.items.get(itemIndex + 1);

            if (sibling) {
                sibling.showDivider = !isOpened;
            }

            const item = this.items.get(itemIndex);

            if (item && item.width !== 'full') {
                const elementBox: DOMRect = this.elementRef.nativeElement.getBoundingClientRect();
                const itemBox: DOMRect = item.elementRef.nativeElement.getBoundingClientRect();
                if (itemBox.left - elementBox.left + (item.width === 'normal' ? 320 : 630) > elementBox.width) {
                    item.position = 'right';
                }
            }
        }
    }

    private handleResetState(itemIndex: number): void {
        let sibling = this.items.get(itemIndex + 1);
        if (this.items) {
            this.items.forEach((item: WucNavigationItemComponent) =>
                !item.opened && sibling ? (sibling.showDivider = true) : false
            );
        }
    }

    private onRouteChange(): void {
        this.items.forEach((item: WucNavigationItemComponent) => {
            item.close();
        });
    }
}
