import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { WucCompareTableInterface } from '../../compare-table.interface';
// Do not import from index, creates a cyclic import
import { WucCompareTableCellItemInterface } from '../compare-table-cell-item/compare-table-cell-item.interface';
import { WucCompareTableColumnInterface } from '../compare-table-column/compare-table-column.interface';

@Component({
    selector: 'wuc-compare-table-cell',
    templateUrl: 'compare-table-cell.component.html',
    styleUrls: ['compare-table-cell.component.scss'],
})
export class WucCompareTableCellComponent {
    @Input() public items!: WucCompareTableCellItemInterface[];

    @Input() public horizontalAlignment: 'left' | 'center' | 'right' = 'center';
    @Input() public verticalAlignment: 'top' | 'center' | 'bottom' = 'center';
    @Input() public fixedLeftPosition: string | null = null;
    @Input() public firstInColumn: boolean = false;
    @Input() public lastInColumn: boolean = false;
    @Input() public isExtraInfoCell: boolean = false;
    @Input() public preventClick: boolean = false;

    @ViewChild('template', { read: TemplateRef })
    public templateRef!: TemplateRef<HTMLElement>;

    public parentTable!: WucCompareTableInterface;
    public parentColumn!: WucCompareTableColumnInterface;

    public setParent(parentColumn: WucCompareTableColumnInterface): void {
        this.parentColumn = parentColumn;
        this.parentTable = parentColumn.parentTable;
    }

    public onMouseEnter(): void {
        this.parentColumn.onMouseEnter();
    }

    public onMouseLeave(): void {
        this.parentColumn.onMouseLeave();
    }

    public onClick(): void {
        if (!this.preventClick) {
            this.parentColumn.onClick();
        }
    }

    public get isBordered(): boolean {
        return !this.isExtraInfoCell && this.parentColumn.isBordered;
    }
}
