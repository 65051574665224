<picture>
  <source
    *ngIf="imageMobile && imageTablet"
    [type]="imageType"
    [attr.defaultImage]="lazyLoad ? 'assets/default-image-1x1.png' : null"
    [attr.lazyLoad]="lazyLoad ? imageMobile : null"
    [attr.srcset]="lazyLoad ? null : imageMobile"
    [attr.width]="imageWidth?.mobile"
    [attr.height]="imageHeight?.mobile"
    media="(max-width: 575px)"
  />
  <source
    *ngIf="imageMobile && !imageTablet"
    [type]="imageType"
    [attr.defaultImage]="lazyLoad ? 'assets/default-image-1x1.png' : null"
    [attr.lazyLoad]="lazyLoad ? imageMobile : null"
    [attr.srcset]="lazyLoad ? null : imageMobile"
    [attr.width]="imageWidth?.mobile"
    [attr.height]="imageHeight?.mobile"
    media="(max-width: 767px)"
  />
  <source
    *ngIf="imageTablet"
    [type]="imageType"
    [attr.defaultImage]="lazyLoad ? 'assets/default-image-1x1.png' : null"
    [attr.lazyLoad]="lazyLoad ? imageTablet : null"
    [attr.srcset]="lazyLoad ? null : imageTablet"
    [attr.width]="imageWidth?.tablet"
    [attr.height]="imageHeight?.tablet"
    media="(min-width: 576px) and (max-width: 767px)"
  />
  <source
    [type]="imageType"
    [attr.defaultImage]="lazyLoad ? 'assets/default-image-1x1.png' : null"
    [attr.lazyLoad]="lazyLoad ? imageDesktop : null"
    [attr.srcset]="lazyLoad ? null : imageDesktop"
    [attr.width]="imageWidth?.desktop"
    [attr.height]="imageHeight?.desktop"
    media="(min-width: 768px)"
  />
  <img
    *ngIf="lazyLoad"
    src="assets/default-image-1x1.png"
    [lazyLoad]="imageDesktop"
    [alt]="alt"
    [title]="title"
    class="wuc-picture"
    [attr.width]="imageWidth?.desktop"
    [attr.height]="imageHeight?.desktop"
    [ngStyle]="{ 'aspect-ratio': imageWidth && imageHeight ? imageWidth.desktop + '/' + imageHeight.desktop : 'auto' }"
  />
  <img
    *ngIf="!lazyLoad"
    [src]="imageDesktop"
    [alt]="alt"
    [title]="title"
    class="wuc-picture"
    [attr.width]="imageWidth?.desktop"
    [attr.height]="imageHeight?.desktop"
    [ngStyle]="{ 'aspect-ratio': imageWidth && imageHeight ? imageWidth.desktop + '/' + imageHeight.desktop : 'auto' }"
  />
</picture>
