<div
    class="wuc-input-container wuc-input-container--border"
    [class.wuc-input-container--has-error]="hasError"
    [class.wuc-input-container--disabled]="isDisabled"
>
    <input
        #input
        [type]="'text'"
        class="wuc-input"
        [readonly]="!allowTextInput"
        [placeholder]="allowTextInput ? pikadayService.placeholder : pikadayService.placeholderSelect"
        [attr.autocomplete]="'off'"
        [attr.inputmode]="inputmode"
        [attr.aria-label]="ariaLabel"
        [attr.id]="name ?? null"
        [attr.e2e]="e2e ?? null"
        [disabled]="isDisabled"
        [(ngModel)]="value"
        (blur)="onInputBlur()"
        [class.input--error]="hasError"
    />
    <div #icon>
        <wuc-icon-ui
            class="wuc-input__icon wuc-date-picker__icon"
            size="s"
            icon="calendar"
            type="primary"
            (click)="input.click()"
        ></wuc-icon-ui>
    </div>
</div>
<div #pikadayContainer></div>
