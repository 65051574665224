import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucClickOutsideModule } from '../../../core';
import { WucDropTriggerComponent } from './drop-trigger.component';

@NgModule({
    imports: [CommonModule, WucClickOutsideModule],
    declarations: [WucDropTriggerComponent],
    exports: [WucDropTriggerComponent],
})
export class WucDropTriggerModule {}
