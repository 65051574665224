<div
  class="wuc-input-container wuc-input-container--border"
  [class.wuc-input-container--has-error]="hasError"
  [class.wuc-input-container--disabled]="disabled"
>
  <input
    #input
    type="text"
    class="wuc-input input-zipcode-nl"
    minlength="6"
    [class.input--error]="hasError"
    [attr.autocomplete]="autocomplete"
    [attr.inputmode]="inputmode"
    [attr.aria-label]="ariaLabel"
    [attr.id]="name ?? null"
    [attr.e2e]="e2e ?? null"
    [placeholder]="placeholder"
    [value]="value"
    (input)="onInput(input.value)"
    (beforeinput)="onBeforeInput($event)"
    (blur)="onBlur()"
    (keyup)="updateInputmode()"
    (click)="updateInputmode()"
    [disabled]="disabled"
  />
  <wuc-icon-ui class="wuc-input__icon" size="s" icon="location" type="primary" (click)="input.focus()"></wuc-icon-ui>
</div>
