import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WucIconUiModule } from '../../common';
import { WucFormFieldStateModule } from '../form-field-state';
import { WucInputDatePickerComponent } from './input-date-picker.component';
import { PikadayService } from './pikaday.service';

@NgModule({
    imports: [FormsModule, ReactiveFormsModule, CommonModule, WucFormFieldStateModule, WucIconUiModule],
    declarations: [WucInputDatePickerComponent],
    exports: [WucInputDatePickerComponent],
    providers: [PikadayService],
})
export class WucInputDatePickerModule {}
