<div class="wuc-input-read-only">
  <div class="wuc-input-read-only__content">
    <ng-content></ng-content>
  </div>
  <div *ngIf="action" class="wuc-input-read-only__action-button">
    <wuc-button type="clean" (buttonClick)="onAction()">
      <span class="wuc-input-read-only__action-text">{{ action }}</span>
    </wuc-button>
  </div>
</div>
