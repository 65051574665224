import { Component, Input } from '@angular/core';

@Component({
    selector: 'wuc-progress-bar',
    templateUrl: 'progress-bar.component.html',
    styleUrls: ['progress-bar.component.scss'],
})
export class WucProgressBarComponent {
    @Input() public label?: string;
    @Input() public percentage: number = 0;
    @Input() public hasDynamicColors: boolean = false;

    public get color(): 'primary' | 'error-high' | 'error-medium' | 'error-low' | 'success' {
        if (!this.hasDynamicColors) {
            return 'primary';
        }
        if (this.percentage <= 25) {
            return 'error-high';
        }
        if (this.percentage <= 50) {
            return 'error-medium';
        }
        if (this.percentage < 100) {
            return 'error-low';
        }
        return 'success';
    }
}
