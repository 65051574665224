<div
  #accordionItem
  class="wuc-accordion-item"
  [class.wuc-accordion-item--on-dark]="accordionOptions.onDark"
  [class.wuc-accordion-item--clean]="accordionOptions.isClean"
  [class.wuc-accordion-item--first]="isFirstAccordion"
  [class.wuc-accordion-item--last]="isLastAccordion"
  [class.wuc-accordion-item--indented]="accordionStyle === 'indented'"
  tabindex="0"
  (keypress)="onKeyPress($event)"
>
  <div
    class="wuc-accordion-item__header"
    [class.wuc-accordion-item__header--expanded]="expanded"
    [class.wuc-accordion-item__header--space-left]="accordionOptions.hasNumber || accordionOptions.icon"
    [class.wuc-accordion-item__header--double-icon]="accordionOptions.hasNumber && accordionOptions.icon"
    [class.wuc-accordion-item__header--no-space-right]="accordionOptions.icon === accordionItemIconsEnum.Plus"
    (click)="toggleIsExpanded()"
  >
    <span class="wuc-accordion-item__icon-left" *ngIf="accordionOptions.icon === accordionItemIconsEnum.Check">
      <wuc-icon-ui icon="check-closed" type="primary"></wuc-icon-ui>
    </span>
    <span
      class="wuc-accordion-item__icon-left wuc-accordion-item__icon-information"
      *ngIf="accordionOptions.icon === accordionItemIconsEnum.Info"
    >
      <wuc-icon-ui icon="information" type="primary"></wuc-icon-ui>
    </span>
    <span
      class="wuc-accordion-item__icon-left"
      [class.wuc-accordion-item__icon-left--expanded]="expanded"
      *ngIf="accordionOptions.icon === accordionItemIconsEnum.Plus"
    >
      <wuc-icon-ui icon="add" type="primary"></wuc-icon-ui>
    </span>
    <span class="wuc-accordion-item__custom-icon" *ngIf="accordionOptions.customIcon">
      <wuc-icon [icon]="accordionOptions.customIcon" type="primary"></wuc-icon>
    </span>
    <span
      #accordionNumber
      class="wuc-accordion-item__number"
      [class.wuc-accordion-item__number--double-icon]="accordionOptions.hasNumber && accordionOptions.icon"
      *ngIf="accordionOptions.hasNumber"
    >
      <span #accordionNumberPrefix *ngIf="accordionOptions.numberPrefix">
        {{ accordionOptions.numberPrefix + " " }}
      </span>
      {{ number }}.
    </span>
    <span class="wuc-accordion-item__title">
      <span [innerHTML]="accordionTitle"></span>
      <ng-content select="[slot=title]"></ng-content>
    </span>

    <span
      class="wuc-accordion-item__icon-right"
      [class.wuc-accordion-item__icon-right--expanded]="expanded"
      [class.wuc-accordion-item__icon-right--with-custom-icon]="accordionOptions.customIcon"
      *ngIf="accordionOptions.icon !== accordionItemIconsEnum.Plus"
    >
      <wuc-icon-ui icon="bottom-open" type="primary"></wuc-icon-ui>
    </span>
  </div>
  <div
    #accordionContent
    class="wuc-accordion-item__content"
    [class.wuc-accordion-item__content--space-left]="accordionOptions.hasNumber || accordionOptions.icon"
    [class.wuc-accordion-item__content--double-icon]="accordionOptions.hasNumber && accordionOptions.icon"
    *ngIf="expanded"
  >
    <ng-content></ng-content>
  </div>
</div>
