<ng-template #wucListItem>
  <li
    *ngIf="listOptions"
    class="wuc-list-item"
    [ngClass]="{
      'wuc-list-item--expanded': listOptions.expanded,
      'wuc-list-item--padded': listOptions.padded && !noPadding,
      'wuc-list-item--lined': listOptions.lined,
      'wuc-list-item--line-dark': listOptions.lineColor === 'dark',
      'wuc-list-item--numbered': listOptions.numbered,
      'wuc-list-item--icon': listOptions.icon
    }"
  >
    <div class="wuc-list-item__image" [class.wuc-list-item__image--hidden]="!listOptions.icon">
      <div *ngIf="listOptions.icon" class="wuc-list-item__icon wuc-list-item__icon--{{ icon }}">
        <wuc-icon-ui [size]="size" [icon]="icon"></wuc-icon-ui>
      </div>
    </div>
    <ng-content select=":not([wucListItemIcon])"></ng-content>
  </li>
</ng-template>
