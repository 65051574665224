import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucHighlightPipe } from './highlight.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [WucHighlightPipe],
    exports: [WucHighlightPipe],
})
export class WucHighlightModule {}
