<div #list class="wuc-chatbot-list" [class.wuc-chatbot-list--mobile]="isMobile">
  <div class="wuc-chatbot-list__item wuc-chatbot-list__answer">
    <div class="wuc-chatbot-list__answer-smuppet">
      <wuc-picture imageDesktop="/icons/chat-shmuppet-80x100.png"></wuc-picture>
    </div>
    <div class="wuc-chatbot-list__answer-balloon">
      <wuc-balloon arrowPosition="leftTop" backgroundStyle="secondary" padding="medium" [hideShadow]="true">
        <div class="wuc-chatbot-list__text">
          <ng-content select="[slot=intro]"></ng-content>
        </div>
      </wuc-balloon>
    </div>
  </div>
  <ng-container *ngFor="let item of items; let isLast = last">
    <ng-container [ngSwitch]="itemType(item)">
      <div *ngSwitchCase="wucChatbotItemEnum.Answer" class="wuc-chatbot-list__item wuc-chatbot-list__answer">
        <div class="wuc-chatbot-list__answer-smuppet">
          <wuc-picture imageDesktop="/icons/chat-shmuppet-80x100.png"></wuc-picture>
        </div>
        <div class="wuc-chatbot-list__answer-balloon">
          <wuc-balloon arrowPosition="leftTop" backgroundStyle="secondary" padding="medium">
            <wuc-chatbot-answer [answer]="asAnswer(item)" (dialogOption)="onDialogOption($event)"></wuc-chatbot-answer>
          </wuc-balloon>
        </div>
        <div *ngIf="isLast" class="wuc-chatbot-list__footer">
          <ng-content select="[slot=footer]"></ng-content>
        </div>
      </div>
      <div
        *ngSwitchCase="wucChatbotItemEnum.Question"
        #listItemQuestion
        class="wuc-chatbot-list__item wuc-chatbot-list__question"
      >
        <wuc-balloon arrowPosition="rightMiddle" padding="medium">
          <div class="wuc-chatbot-list__text">
            {{ item }}
          </div>
        </wuc-balloon>
      </div>
    </ng-container>
  </ng-container>
</div>
