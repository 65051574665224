import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    Input,
    NgZone,
    OnDestroy,
    Optional,
    Renderer2,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { combineLatest, fromEvent, Subscription } from 'rxjs';
import { WucVideoClass } from './video.class';

@Component({
    selector: 'wuc-video',
    templateUrl: 'video.component.html',
    styleUrls: ['video.component.scss'],
})
export class WucVideoComponent implements AfterViewInit, OnDestroy {
    @ViewChild('noVideoContent', { read: ViewContainerRef }) private noVideoContentContainerRef!: ViewContainerRef;
    @Input() public videoId!: string;
    @Input() public videoImage?: string;

    private subscriptions: Subscription = new Subscription();
    public isVideoVisible: boolean = true;

    constructor(
        @Optional() @Inject('noVideoComponent') private noConsentComponent: WucVideoClass,
        private changeDetectorRef: ChangeDetectorRef,
        private viewContainerRef: ViewContainerRef,
        private ngZone: NgZone,
        private elementRef: ElementRef,
        private renderer: Renderer2
    ) {}

    public ngAfterViewInit(): void {
        this.loadNoVideoComponent();
        // a normal click handler on the element or a hostListner
        // triggers a changedetection cycle in the parent component
        this.subscriptions.add(
            combineLatest([fromEvent(this.elementRef.nativeElement, 'click'), this.noConsentComponent.isVideoVisible$])
                .pipe()
                .subscribe(() => {
                    this.startVideo();
                })
        );
        if (this.noConsentComponent) {
            this.subscriptions.add(
                this.noConsentComponent.isVideoVisible$.subscribe((isVisible: boolean) => {
                    this.isVideoVisible = isVisible;
                    this.changeDetectorRef.detectChanges();
                })
            );
        }
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public startVideo(): void {
        this.renderer.setProperty(
            this.elementRef.nativeElement,
            'innerHTML',
            `<iframe
            width="100%"
            height="100%"
            src="https://www.youtube-nocookie.com/embed/${this.videoId}?autoplay=1"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            style="border: none"
            allowfullscreen="">
        </iframe>`
        );
    }

    private loadNoVideoComponent(): void {
        this.noVideoContentContainerRef.createComponent<Component>(this.noConsentComponent.noVideoComponent);
        this.changeDetectorRef.detectChanges();
    }
}
