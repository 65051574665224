import { Component, Input } from '@angular/core';
import { RackColumnsType } from './rack-columns.type';

@Component({
    selector: 'wuc-rack',
    templateUrl: 'rack.component.html',
    styleUrls: ['rack.component.scss'],
})
export class WucRackComponent {
    @Input() public columnsMobile: RackColumnsType = 1;
    @Input() public columnsTablet?: RackColumnsType;
    @Input() public columnsDesktop?: RackColumnsType;
    @Input() public gridGap: 'none' | 'small' | 'medium' | 'large' = 'medium';

    public get tablet(): number | string {
        return this.columnsTablet ?? this.columnsMobile;
    }

    public get desktop(): number | string {
        return this.columnsDesktop ?? this.columnsTablet ?? this.columnsMobile;
    }
}
