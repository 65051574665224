import { NgForOf, NgIf } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucLinkModule } from '../../common';
import { WucAlphabetFilterComponent } from './alphabet-filter.component';

@NgModule({
    declarations: [WucAlphabetFilterComponent],
    exports: [WucAlphabetFilterComponent],
    imports: [NgForOf, NgIf, WucLinkModule],
})
export class WucAlphabetFilterModule {}
