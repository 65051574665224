<div [class]="'wuc-tabset wuc-tabset--' + tabsetStyle">
  <div class="wuc-tabset__tabs">
    <ng-container *ngFor="let tab of tabs; let index = index; let isLast = last">
      <div
        class="wuc-tabset__tab"
        [class.wuc-tabset__tab--current]="selectedTabIndex === index"
        [class.wuc-tabset__tab--hidden]="tab.isHidden"
        [attr.id]="tab.id"
        (click)="onTabSelect(index)"
      >
        <div *ngIf="tabsetStyle !== 'tertiary'" class="wuc-tabset__tab-inner">
          <wuc-icon *ngIf="tabsetStyle === 'primary' && tab.icon" [icon]="tab.icon" size="l" type="primary"></wuc-icon>
          <wuc-link class="wuc-tabset__tab-name" type="quaternary" (clicked)="onTabSelect(index)">
            {{ tab.name }}
          </wuc-link>
        </div>
        <div *ngIf="tabsetStyle === 'tertiary'" class="wuc-tabset__tab-inner">
          <wuc-balloon
            *ngIf="selectedTabIndex === index"
            arrowPosition="bottomMiddle"
            backgroundStyle="primary"
            [hideShadow]="true"
          >
            <wuc-link class="wuc-tabset__tab-name" type="quaternary" (clicked)="onTabSelect(index)">
              {{ tab.name }}
            </wuc-link>
          </wuc-balloon>
          <wuc-card *ngIf="selectedTabIndex !== index" cardStyle="tertiary">
            <span class="wuc-tabset__tab-name">{{ tab.name }}</span>
          </wuc-card>
        </div>
        <div *ngIf="!isLast" class="wuc-tabset__tab-spacer"></div>
      </div>
    </ng-container>
    <div *ngIf="tabsetStyle === 'primary'" class="wuc-tabset__tab-filler"></div>
  </div>
  <div class="wuc-tabset__tab-content" *ngIf="selectedTab">
    <ng-container *ngTemplateOutlet="selectedTab.template"></ng-container>
  </div>
</div>
