import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ContentChild, Input } from '@angular/core';
import { WucDisplayTableLabelComponent } from '../display-table-label';
import { WucDisplayTableValueComponent } from '../display-table-value';

@Component({
    standalone: true,
    selector: 'wuc-display-table-row',
    templateUrl: 'display-table-row.component.html',
    styleUrls: ['display-table-row.component.scss'],
    imports: [CommonModule],
})
export class WucDisplayTableRowComponent implements AfterViewInit {
    @ContentChild(WucDisplayTableLabelComponent)
    public label!: WucDisplayTableLabelComponent;

    @ContentChild(WucDisplayTableValueComponent)
    public value!: WucDisplayTableValueComponent;

    @Input() public hasBoldBorder: boolean = false;

    constructor(private changeDetectorRef: ChangeDetectorRef) {}

    public ngAfterViewInit(): void {
        this.changeDetectorRef.detectChanges();
    }
}
