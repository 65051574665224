import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucIconUiModule } from '../../common';
import { FormFieldStateComponent } from './form-field-state.component';

@NgModule({
    imports: [CommonModule, WucIconUiModule],
    declarations: [FormFieldStateComponent],
    exports: [FormFieldStateComponent],
})
export class WucFormFieldStateModule {}
