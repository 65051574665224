import { NgModule } from '@angular/core';
import { WucLineModule } from '../../layout/line/line.module';
import { WucSloganModule } from '../slogan/slogan.module';
import { WucSloganFooterComponent } from './slogan-footer.component';

@NgModule({
    declarations: [WucSloganFooterComponent],
    exports: [WucSloganFooterComponent],
    imports: [WucLineModule, WucSloganModule],
})
export class WucSloganFooterModule {}
