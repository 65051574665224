import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucIconUiComponent } from './icon-ui.component';

@NgModule({
    imports: [CommonModule],
    declarations: [WucIconUiComponent],
    exports: [WucIconUiComponent],
})
export class WucIconUiModule {}
