import { Component, ElementRef, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { WucInputBaseComponent } from '../input-base.component';
import { InputModeService } from '../input-mode.service';
import { InputInterface } from '../input.interface';
import { InputService } from '../input.service';

@Component({
    selector: 'wuc-input-iban-nl',
    templateUrl: 'input-iban-nl.component.html',
    styleUrls: ['input-iban-nl.component.scss'],
    // eslint-disable-next-line
    inputs: WucInputBaseComponent.inputs,
    providers: [
        {
            provide: WucInputBaseComponent,
            useExisting: WucInputIbanNLComponent,
        },
    ],
})
export class WucInputIbanNLComponent
    extends WucInputBaseComponent
    implements ControlValueAccessor, InputInterface, OnInit
{
    @ViewChild('input', { static: true }) private input!: ElementRef;

    @Input() public placeholder: string = '';

    public disabled: boolean = false;
    public value: string = '';
    public onChange!: (value: string) => void;
    public onTouched!: () => void;

    private readonly allowedCharactersRexExp: RegExp = /^[a-z\d]*$/i;
    private readonly validPattern: string = 'ZZ99ZZZZ9999999999';

    constructor(@Optional() public override ngControl: NgControl) {
        super(ngControl);
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    public ngOnInit(): void {
        this.updateInputmode();
    }

    // Update value from API; API => view
    public writeValue(value: string): void {
        this.value = value;
    }

    // Update value from view "on change"; View => API
    public registerOnChange(fn: (_: string) => void): void {
        this.onChange = fn;
    }

    // Mark control as touched; View => API
    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    // Set disabled state from API
    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public onInput(value: string): void {
        this.value = value.toUpperCase();
        this.updateInputmode();
        this.onChange(this.value);
    }

    public onBeforeInput(event: InputEvent): void {
        if (!event.data) {
            return;
        }
        if (event.data && !this.allowedCharactersRexExp.test(event.data)) {
            event.preventDefault();
            return;
        }

        const newValue = InputService.replaceSelection(this.input.nativeElement, event.data);

        if (!InputService.isPatternMatch(this.validPattern, newValue)) {
            event.preventDefault();
        }
    }

    public updateInputmode(): void {
        this.inputmode = InputModeService.getInputmodeForInput(this.validPattern, this.input.nativeElement);
    }
}
