import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { WucAlphabetFilterItemInterface } from './alphabet-filter-item.interface';
import { WucAlphabetFilterItemType } from './alphabet-filter-item.type';

@Component({
    selector: 'wuc-alphabet-filter',
    templateUrl: 'alphabet-filter.component.html',
    styleUrls: ['alphabet-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WucAlphabetFilterComponent {
    @Input() public items!: WucAlphabetFilterItemInterface[];
    @Input() public defaultLinkType?: WucAlphabetFilterItemType;
    @Input() public isMobile: boolean = false;

    @Output() public itemSelect: EventEmitter<WucAlphabetFilterItemInterface> =
        new EventEmitter<WucAlphabetFilterItemInterface>();

    public onItemClick(item: WucAlphabetFilterItemInterface): void {
        if ((item.linkType || this.defaultLinkType) === 'event') {
            this.itemSelect.emit(item);
        }
    }
}
