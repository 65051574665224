import {
    AfterContentInit,
    Component,
    ContentChildren,
    Input,
    OnChanges,
    OnDestroy,
    QueryList,
    SimpleChanges,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ListIconType } from './list-icon.type';
import { WucListItemBaseComponent } from './list-item-base.component';

@Component({
    selector: 'wuc-list',
    templateUrl: 'list.component.html',
    styleUrls: ['list.component.scss'],
})
export class WucListComponent implements AfterContentInit, OnDestroy, OnChanges {
    @ContentChildren(WucListItemBaseComponent)
    public items!: QueryList<WucListItemBaseComponent>;

    @Input() public icon?: ListIconType;
    @Input() public columns: number = 1;
    @Input() public numbered: boolean = false;
    @Input() public expanded: boolean = false;
    @Input() public lined: boolean = false;
    @Input() public noLastLine: boolean = false;
    @Input() public lineColor?: 'dark';

    public rows: number = 1;

    private subscriptions: Subscription = new Subscription();

    public ngAfterContentInit(): void {
        this.subscriptions.add(this.items.changes.subscribe(() => this.updateListItems()));
        this.updateListItems();
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if ('columns' in changes && this.items) {
            this.updateListItems();
        }
    }

    private updateListItems(): void {
        const items = this.items.toArray();
        const lastIndex: number = items.length - 1;
        items.forEach(
            (item: WucListItemBaseComponent, index: number) =>
                (item.listOptions = {
                    icon: this.icon,
                    numbered: this.numbered,
                    expanded: this.expanded,
                    lined: this.lined && (!this.noLastLine || index !== lastIndex),
                    lineColor: this.lineColor,
                    padded: this.lined,
                })
        );
        this.rows = Math.ceil(items.length / this.columns);
    }
}
