import { Component, ElementRef, Input, Optional, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { WucInputBaseComponent } from '../input-base.component';
import { InputInterface } from '../input.interface';
import { WucInputPhonenumberService } from './input-phonenumber.service';

@Component({
    selector: 'wuc-input-phonenumber',
    templateUrl: 'input-phonenumber.component.html',
    styleUrls: ['input-phonenumber.component.scss'],
    // eslint-disable-next-line
    inputs: WucInputBaseComponent.inputs,
    providers: [
        {
            provide: WucInputBaseComponent,
            useExisting: WucInputPhonenumberComponent,
        },
    ],
})
export class WucInputPhonenumberComponent
    extends WucInputBaseComponent
    implements ControlValueAccessor, InputInterface
{
    @ViewChild('input', { static: true }) private input!: ElementRef;

    @Input() public placeholder: string = '';

    public disabled: boolean = false;
    public value: string = '';
    public onChange!: (value: string) => void;
    public onTouched!: () => void;

    private readonly allowedCharactersRexExp: RegExp = /^\+?\d*$/i;

    constructor(
        @Optional() public override ngControl: NgControl,
        private inputPhonenumberService: WucInputPhonenumberService
    ) {
        super(ngControl);
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    // Update value from API; API => view
    public writeValue(value: string): void {
        this.value = value;
    }

    // Update value from view "on change"; View => API
    public registerOnChange(fn: (_: string) => void): void {
        this.onChange = fn;
    }

    // Mark control as touched; View => API
    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    // Set disabled state from API
    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public onBeforeInput(event: InputEvent): void {
        if (event.data && !this.allowedCharactersRexExp.test(event.data)) {
            event.preventDefault();
        }
    }

    public onBlur(event: Event): void {
        this.formatInput(event.target as HTMLInputElement);
        event.preventDefault();
        this.onTouched();
    }

    private formatInput(inputElement: HTMLInputElement): void {
        const formattedValue = this.inputPhonenumberService.format(inputElement.value);

        if (formattedValue) {
            this.value = formattedValue.formatted;
            this.onChange(formattedValue.cleaned);
        }
    }
}
