<div class="wuc-form-field-state">
  <wuc-icon-ui
    class="wuc-form-field-state__icon wuc-form-field-state__icon--has-error"
    *ngIf="state === formFieldStateEnum.HasError"
    type="primary"
    icon="cross-closed"
  ></wuc-icon-ui>
  <wuc-icon-ui
    class="wuc-form-field-state__icon wuc-form-field-state__icon--has-success"
    *ngIf="state === formFieldStateEnum.HasSuccess"
    type="secondary"
    icon="check-closed"
  ></wuc-icon-ui>
  <wuc-icon-ui
    class="wuc-form-field-state__icon wuc-wuc-form-field-state__icon--is-pending"
    *ngIf="state === formFieldStateEnum.IsPending"
    type="secondary"
    icon="loading"
  ></wuc-icon-ui>
</div>
