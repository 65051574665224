import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WucChatbotEscalationFeedbackInterface, WucChatbotEscalationInterface } from '../chatbot-escalation';
import { WucChatbotAnswerInterface, WucChatbotDialogOptionInterface } from './chatbot-answer.interface';

@Component({
    selector: 'wuc-chatbot-answer[answer]',
    templateUrl: 'chatbot-answer.component.html',
    styleUrls: ['chatbot-answer.component.scss'],
})
export class WucChatbotAnswerComponent {
    @Input() public answer!: WucChatbotAnswerInterface;
    @Input() public escalation?: WucChatbotEscalationInterface;

    @Output() public readonly dialogOption: EventEmitter<WucChatbotDialogOptionInterface> =
        new EventEmitter<WucChatbotDialogOptionInterface>();

    @Output() public readonly feedback: EventEmitter<WucChatbotEscalationFeedbackInterface> =
        new EventEmitter<WucChatbotEscalationFeedbackInterface>();

    public onDialogOptionClick(value: WucChatbotDialogOptionInterface): void {
        this.dialogOption.emit(value);
    }

    public onFeedback(feedback: WucChatbotEscalationFeedbackInterface): void {
        this.feedback.emit(feedback);
    }
}
