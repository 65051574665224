<ul
  class="wuc-list"
  [ngClass]="{
    'wuc-list--numbered': numbered,
    'wuc-list--lined': lined,
    'wuc-list--line-dark': lineColor === 'dark',
    'wuc-list--expanded': expanded
  }"
  [style.grid-template-rows]="'repeat(' + rows + ', auto)'"
>
  <ng-container *ngFor="let item of items">
    <ng-content *ngTemplateOutlet="item.listItemTemplateRef"></ng-content>
  </ng-container>
</ul>
