import { NgModule } from '@angular/core';
import { WucLinkModule } from '../link';
import { WucSummaryTableLabelModule } from '../summary-table-label';
import { WucSummaryTableRowModule } from '../summary-table-row';
import { WucSummaryTableValueModule } from '../summary-table-value';
import { WucSummaryTableComponent } from './summary-table.component';

@NgModule({
    declarations: [WucSummaryTableComponent],
    exports: [WucSummaryTableComponent],
    imports: [WucLinkModule, WucSummaryTableRowModule, WucSummaryTableLabelModule, WucSummaryTableValueModule],
})
export class WucSummaryTableModule {}
