import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucSafeHtmlModule } from '../../../shared';
import { WucRatingModule, WucSkeletonModule } from '../../common';
import { WucProductGradeComponent } from './product-grade.component';

@NgModule({
    imports: [CommonModule, WucRatingModule, WucSkeletonModule, WucSafeHtmlModule],
    declarations: [WucProductGradeComponent],
    exports: [WucProductGradeComponent],
})
export class WucProductGradeModule {}
