<div class="wuc-chatbot-answer">
  <div class="wuc-chatbot-answer__element" *ngFor="let element of answer.elements">
    <ng-container [ngSwitch]="element.type">
      <ng-container *ngSwitchCase="'paragraph'">
        <ng-container *ngTemplateOutlet="paragraphTemplate; context: { $implicit: element.parts }"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'orderedList'">
        <ng-container *ngTemplateOutlet="orderedListTemplate; context: { $implicit: element.parts }"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'unorderedList'">
        <ng-container *ngTemplateOutlet="unorderedlistTemplate; context: { $implicit: element.parts }"></ng-container>
      </ng-container>
    </ng-container>
  </div>
  <div *ngIf="escalation" class="wuc-chatbot-answer__escalation">
    <wuc-chatbot-escalation [options]="escalation" (feedback)="onFeedback($event)"></wuc-chatbot-escalation>
  </div>
</div>

<ng-template #paragraphTemplate let-parts>
  <p class="wuc-chatbot-answer__paragraph">
    <ng-container *ngFor="let part of parts">
      <ng-container *ngTemplateOutlet="textTemplate; context: { $implicit: part }"></ng-container>
    </ng-container>
  </p>
</ng-template>
<ng-template #orderedListTemplate let-parts>
  <wuc-list [numbered]="true" class="wuc-chatbot-answer__list">
    <wuc-list-item *ngFor="let part of parts">
      <ng-container *ngTemplateOutlet="textTemplate; context: { $implicit: part }"></ng-container>
    </wuc-list-item>
  </wuc-list>
</ng-template>
<ng-template #unorderedlistTemplate let-parts>
  <wuc-list icon="dot-small" class="wuc-chatbot-answer__list">
    <wuc-list-item *ngFor="let part of parts">
      <span class="wuc-chatbot-answer__list-item-text">
        <ng-container *ngTemplateOutlet="textTemplate; context: { $implicit: part }"></ng-container>
      </span>
    </wuc-list-item>
  </wuc-list>
</ng-template>

<ng-template #textTemplate let-part>
  <ng-container [ngSwitch]="part.type">
    <ng-container *ngSwitchCase="'text'">
      <span [innerHtml]="part.data | wucReplace: '&nbsp;':' ' | wucSafeHtml"></span>
    </ng-container>
    <ng-container *ngSwitchCase="'dialogoption'">
      <wuc-link type="primary" (clicked)="onDialogOptionClick(part.data)">
        <span [innerHtml]="part.data.text | wucSafeHtml"></span>
      </wuc-link>
    </ng-container>
    <ng-container *ngSwitchCase="'video'">
      <div class="wuc-chatbot-answer__video">
        <wuc-video [videoId]="part.data.videoId"></wuc-video>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'link'">
      <wuc-link [url]="part.data.url" [target]="part.data.target">
        <span [innerHtml]="part.data.text | wucSafeHtml"></span>
      </wuc-link>
    </ng-container>
  </ng-container>
</ng-template>
