import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { WucButtonModule } from '../../common';
import { WucInputTextModule } from '../../forms';
import { WucChatbotInputComponent } from './chatbot-input.component';

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, WucButtonModule, WucInputTextModule],
    declarations: [WucChatbotInputComponent],
    exports: [WucChatbotInputComponent],
})
export class WucChatbotInputModule {}
