import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { WucPictureComponent } from './picture.component';

@NgModule({
    imports: [CommonModule, LazyLoadImageModule],
    declarations: [WucPictureComponent],
    exports: [WucPictureComponent],
})
export class WucPictureModule {}
