<div class="wuc-input-container" [class.wuc-input-container--has-error]="hasError">
  <input
    class="wuc-input wuc-input-checkbox"
    [class.wuc-input-checkbox--disabled]="isDisabled"
    type="checkbox"
    [attr.aria-label]="ariaLabel"
    [attr.id]="name ?? null"
    [attr.e2e]="e2e ?? null"
    [disabled]="isDisabled"
    [ngModel]="value"
    (ngModelChange)="onValueChange($event)"
  />
  <label class="wuc-input-checkbox__label" [for]="name">
    <ng-content></ng-content>
  </label>
</div>
