<div
  class="wuc-input-container wuc-input-container--border"
  [class.wuc-input-container--has-error]="hasError"
  [class.wuc-input-container--disabled]="isDisabled"
>
  <input
    #input
    [type]="inputType"
    class="wuc-input wuc-input-password"
    [minLength]="config.minLength"
    [maxLength]="config.maxLength"
    [attr.autocomplete]="autocomplete"
    [attr.inputmode]="inputmode"
    [attr.aria-label]="ariaLabel"
    [attr.id]="name ?? null"
    [attr.e2e]="e2e ?? null"
    [placeholder]="placeholder"
    [value]="value"
    (input)="onInput(input.value)"
    (blur)="onBlur()"
    (focusin)="showDropdown = true"
    (focusout)="showDropdown = false"
    [disabled]="isDisabled"
  />
  <div class="wuc-input__icon">
    <div class="wuc-input-password__toggle" (click)="toggleVisiblePassword()">
      <wuc-icon-ui size="s" [icon]="icon" type="primary"></wuc-icon-ui>
    </div>
  </div>
</div>
<div *ngIf="showDropdown && !hideHint" class="wuc-input-password__dropdown">
  <div class="wuc-input-password__dropdown-balloon">
    <wuc-balloon padding="medium" arrowPosition="topLeft">
      <div class="wuc-input-password__text">{{ config.passwordContainsText }}</div>
      <wuc-list>
        <wuc-list-item>
          <ng-container *ngTemplateOutlet="checkIcon; context: { ok: hasRequiredAmountOfLetters }"></ng-container>
          <span>{{ replacePlaceholder(config.amountOfLettersText, config.amountOfLetters.toString()) }}</span>
        </wuc-list-item>
        <wuc-list-item>
          <ng-container *ngTemplateOutlet="checkIcon; context: { ok: hasRequiredAmountOfNumbers }"></ng-container>
          <span>{{ replacePlaceholder(config.amountOfNumbersText, config.amountOfNumbers.toString()) }}</span>
        </wuc-list-item>
        <wuc-list-item>
          <ng-container *ngTemplateOutlet="checkIcon; context: { ok: hasRequiredLength }"></ng-container>
          <span>{{ replacePlaceholders(config.passwordLengthText, config.minLength, config.maxLength) }}</span>
        </wuc-list-item>
      </wuc-list>
      <div class="wuc-input-password__text">
        {{ replacePlaceholder(config.amountOfSpecialCharactersText, config.amountOfSpecialCharacters.toString()) }}
      </div>
      <wuc-list>
        <wuc-list-item>
          <ng-container
            *ngTemplateOutlet="checkIcon; context: { ok: hasRequiredAmountOfSpecialCharacters }"
          ></ng-container>
          <span class="wuc-input-password__special-characters">{{ config.specialCharacters }}</span>
        </wuc-list-item>
      </wuc-list>
      <wuc-progress-bar [percentage]="progressValue" [hasDynamicColors]="true"></wuc-progress-bar>
      <div class="wuc-input-password__hint-text">{{ passwordHintText }}</div>
    </wuc-balloon>
  </div>
</div>

<ng-template #checkIcon let-ok="ok">
  <wuc-icon-ui
    class="wuc-input-password__list-icon"
    [icon]="ok ? 'check-closed' : 'cross-closed'"
    [type]="ok ? 'primary' : 'secondary'"
    size="xs"
  ></wuc-icon-ui>
</ng-template>
