<div
  class="wuc-card"
  [attr.tabindex]="clickable ? 0 : null"
  [class.wuc-card--primary]="borderColor === 'primary' || cardStyle === 'primary'"
  [class.wuc-card--tertiary]="background === 'dark' || cardStyle === 'tertiary'"
  [class.wuc-card--quaternary]="cardStyle === 'quaternary'"
  [class]="
    'wuc-card--border-' +
    borderWidth +
    ' wuc-card--radius-' +
    radius +
    ' wuc-card--padding-' +
    padding +
    ' wuc-card--align-' +
    textAlign
  "
  [class.wuc-card--clickable]="clickable"
  [class.wuc-card--footer-content]="footerContentRef.children.length"
  [class.wuc-card--height-fit-content]="shouldHeightFitContent"
  (click)="onCardClick($event)"
>
  <div
    class="wuc-card__additional-content"
    [class.wuc-card__additional-content--bottom-right]="additionalContentLocation === 'bottom-right'"
    [class.wuc-card__additional-content--top-right]="additionalContentLocation === 'top-right'"
  >
    <ng-content select="[slot=additionalContent]"></ng-content>
  </div>
  <div class="wuc-card__content">
    <ng-content></ng-content>
  </div>
  <div #footerContentRef class="wuc-card__footer-content">
    <ng-content select="[slot=footerContent]"></ng-content>
  </div>
</div>
