import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
    name: 'wucHighlight',
})
export class WucHighlightPipe implements PipeTransform {
    constructor(private domSanitizer: DomSanitizer) {}

    public transform(text: string, search: string): SafeHtml {
        if (!text) {
            return '';
        }
        if (!search) {
            return text;
        }
        const highlightedText = text.replace(
            new RegExp(`(${search})`, 'gi'),
            (match: string) => `<span class="text text--strong">${match}</span>`
        );
        return this.domSanitizer.bypassSecurityTrustHtml(`<span style="white-space: pre">${highlightedText}</span>`);
    }
}
