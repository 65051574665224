import { InputModeEnum } from './input-mode.enum';
import { InputModeType } from './input-mode.type';

export class InputModeService {
    public static getInputmodeForInput(pattern: string, input: HTMLInputElement): InputModeType {
        return InputModeService.getInputmodeAtPosition(pattern, input.selectionStart);
    }

    public static getInputmodeAtPosition(pattern: string, position: number | null): InputModeEnum {
        if (!position) {
            position = 0;
        }
        switch (pattern[position]) {
        case 'Z':
            return InputModeEnum.Text;
        case '9':
            return InputModeEnum.Numeric;
        }
        return InputModeEnum.Text;
    }
}
