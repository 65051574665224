import { Component, Input } from '@angular/core';

@Component({
    selector: 'wuc-tip-card',
    templateUrl: 'tip-card.component.html',
    styleUrls: ['tip-card.component.scss'],
})
export class WucTipCardComponent {
    @Input() public noMaxImageSize: boolean = false;
}
