<div class="wuc-input-alert">
  <wuc-balloon arrowPosition="topLeft" padding="medium" [borderStyle]="alertType === 'error' ? 'solid' : 'dashed'">
    <div class="wuc-input-alert__messages">
      <ng-container *ngIf="messages?.length; else contentTemplate">
        <div class="wuc-input-alert__message" *ngFor="let message of messages">
          <span *ngIf="icon" class="wuc-input-alert__message-icon">
            <wuc-icon-ui [icon]="icon" type="primary"></wuc-icon-ui>
          </span>
          <span class="wuc-input-alert__message-text">
            {{ message }}
          </span>
        </div>
      </ng-container>
    </div>
  </wuc-balloon>
</div>

<ng-template #contentTemplate>
  <div class="wuc-input-alert__message">
    <span *ngIf="icon" class="wuc-input-alert__message-icon">
      <wuc-icon-ui [icon]="icon" type="primary"></wuc-icon-ui>
    </span>
    <span class="wuc-input-alert__message-text">
      <ng-content></ng-content>
    </span>
  </div>
</ng-template>
