import { Component, ContentChildren, HostBinding, Input, QueryList, TemplateRef, ViewChild } from '@angular/core';
import { WucTableCellComponent } from '../table-cell';
@Component({
    selector: 'wuc-table-row',
    templateUrl: 'table-row.component.html',
    styleUrls: ['table-row.component.scss']
})
export class WucTableRowComponent {
    @HostBinding('class.table-row--border-bottom')
    @Input()
    public border?: 'bottom';

    @HostBinding('class.table-row--border-bottom')
    @Input()
    public borderSize?: 'thin' | 'normal' | 'thick' = 'normal';

    @Input() public header?: boolean;
    @Input() public cellPadding: 'normal' | 'vertical' | 'horizontal' | 'none' = 'normal';

    @ViewChild('tableRowHeader', { read: TemplateRef })
    public tableRowHeader!: TemplateRef<HTMLElement>;

    @ViewChild('tableRowBody', { read: TemplateRef })
    public tableRowBody!: TemplateRef<HTMLElement>;

    @ViewChild('tableRowExpanded', { read: TemplateRef })
    public tableRowExpanded!: TemplateRef<HTMLElement | { first: boolean; last: boolean }>;

    @ContentChildren(WucTableCellComponent)
    public cells!: QueryList<WucTableCellComponent>;

    @ContentChildren(WucTableRowComponent)
    public rows!: QueryList<WucTableRowComponent>;

    public showExpand: boolean = false;

}
