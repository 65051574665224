<div class="wuc-progress-bar">
  <div class="wuc-progress-bar__rating-label" *ngIf="label" [title]="label">
    {{ label }}
  </div>
  <div class="wuc-progress-bar__rating" [title]="percentage">
    <div
      class="wuc-progress-bar__rating-percentage"
      [style.width.%]="percentage"
      [class.wuc-progress-bar__rating-percentage--primary]="color === 'primary'"
      [class.wuc-progress-bar__rating-percentage--error-high]="color === 'error-high'"
      [class.wuc-progress-bar__rating-percentage--error-medium]="color === 'error-medium'"
      [class.wuc-progress-bar__rating-percentage--error-low]="color === 'error-low'"
      [class.wuc-progress-bar__rating-percentage--success]="color === 'success'"
    ></div>
  </div>
</div>
