import { NgClass, NgIf } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucIconUiModule } from '../icon-ui';
// Do not import from index, creates a cyclic import
import { WucListItemComponent } from './list-item.component';

@NgModule({
    imports: [NgIf, NgClass, WucIconUiModule],
    declarations: [WucListItemComponent],
    exports: [WucListItemComponent],
})
export class WucListItemModule {}
