import { Component, Input, Optional } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { PrettyLicensePlateService } from '../../../core';
import { WucInputBaseComponent } from '../input-base.component';
import { InputInterface } from '../input.interface';

@Component({
    selector: 'wuc-input-license-plate-nl',
    templateUrl: 'input-license-plate-nl.component.html',
    styleUrls: ['input-license-plate-nl.component.scss'],
    // eslint-disable-next-line
    inputs: WucInputBaseComponent.inputs,
    providers: [{ provide: WucInputBaseComponent, useExisting: WucInputLicensePlateNlComponent }],
})
export class WucInputLicensePlateNlComponent
    extends WucInputBaseComponent
    implements ControlValueAccessor, InputInterface
{
    @Input() public placeholder: string = '';

    public isDisabled: boolean = false;
    public formattedValue: string = '';
    public value: string = '';
    public onChange!: (value: string) => void;
    public onTouched!: () => void;

    constructor(
        @Optional() public override ngControl: NgControl,
        private prettyLicensePlateService: PrettyLicensePlateService
    ) {
        super(ngControl);
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    // Update value from API; API => view
    public writeValue(value: string): void {
        this.updateValue(value);
    }

    // Update value from view "on change"; View => API
    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    // Update value from view "on blur"; View => API
    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    // Set disabled state from API
    public setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    public onFormatLicensePlate(value: string): void {
        this.updateValue(value);
        this.onChange(this.value);
    }

    public updateValue(value: string): void {
        this.formattedValue = value ? this.prettyLicensePlateService.format(value, 'NL') : '';
        this.value = this.prettyLicensePlateService.trim(this.formattedValue);
    }
}
