import { AfterViewInit, ChangeDetectorRef, Component, ContentChild } from '@angular/core';
import { WucSummaryTableLabelComponent } from '../summary-table-label';
import { WucSummaryTableValueComponent } from '../summary-table-value';

@Component({
    selector: 'wuc-summary-table-row',
    templateUrl: 'summary-table-row.component.html',
    styleUrls: ['summary-table-row.component.scss'],
})
export class WucSummaryTableRowComponent implements AfterViewInit {
    @ContentChild(WucSummaryTableLabelComponent)
    public label!: WucSummaryTableLabelComponent;

    @ContentChild(WucSummaryTableValueComponent)
    public value!: WucSummaryTableValueComponent;

    constructor(private changeDetectorRef: ChangeDetectorRef) {}

    public ngAfterViewInit(): void {
        this.changeDetectorRef.detectChanges();
    }
}
