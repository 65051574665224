import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WucIconUiModule } from '../../common';
import { WucFormFieldLabelComponent } from './form-field-label.component';

@NgModule({
    imports: [FormsModule, ReactiveFormsModule, CommonModule, WucIconUiModule],
    declarations: [WucFormFieldLabelComponent],
    exports: [WucFormFieldLabelComponent],
})
export class WucFormFieldLabelModule {}
