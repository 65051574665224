import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { IconUiIcon, IconUiSize } from '../icon-ui';
import { WucListItemBaseComponent } from '../list/list-item-base.component';

@Component({
    selector: 'wuc-list-item',
    templateUrl: 'list-item.component.html',
    styleUrls: ['list-item.component.scss'],
    providers: [{ provide: WucListItemBaseComponent, useExisting: WucListItemComponent }],
})
export class WucListItemComponent extends WucListItemBaseComponent {
    @Input() public noPadding: boolean = false;

    constructor(public override changeDetectorRef: ChangeDetectorRef) {
        super(changeDetectorRef);
    }

    public get size(): IconUiSize {
        switch (this.listOptions.icon) {
        case 'dot-small':
            return 'xxxs';
        case 'dot':
            return 'xxs';
        default:
            return 'xs';
        }
    }

    public get icon(): IconUiIcon {
        switch (this.listOptions.icon) {
        case 'check':
            return 'check-closed';
        case 'cross':
            return 'cross-closed';
        case 'arrow':
            return 'right-open';
        default:
            return 'bullet';
        }
    }
}
