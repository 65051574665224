import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucProgressBarComponent } from './progress-bar.component';

@NgModule({
    declarations: [WucProgressBarComponent],
    exports: [WucProgressBarComponent],
    imports: [CommonModule],
})
export class WucProgressBarModule {}
