import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucBalloonModule, WucIconUiModule } from '../../common';
import { WucInputAlertComponent } from './input-alert.component';

@NgModule({
    declarations: [WucInputAlertComponent],
    exports: [WucInputAlertComponent],
    imports: [WucIconUiModule, WucBalloonModule, CommonModule],
})
export class WucInputAlertModule {}
