import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { BackgroundColorType } from '../../../core';

@Component({
    selector: 'wuc-card',
    templateUrl: 'card.component.html',
    styleUrls: ['card.component.scss'],
})
export class WucCardComponent {
    @Input() public cardStyle: 'primary' | 'secondary' | 'tertiary' | 'quaternary' = 'secondary';
    @Input() public radius: 'small' | 'medium' = 'small';
    @Input() public borderWidth: 'normal' | 'small' | 'none' = 'normal';
    @Input() public padding: 'none' | 'small' | 'medium' | 'large' | 'very-large' = 'small';
    @Input() public textAlign?: 'center';
    @Input() public clickable: boolean = false;
    @Input() public additionalContentLocation: 'top-right' | 'bottom-right' = 'top-right';
    @Input() public shouldHeightFitContent: boolean = false;
    /**
     * @deprecated use cardStyle.
     */
    @Input() public borderColor?: 'primary';
    /**
     * @deprecated use cardStyle.
     */
    @Input() public background?: BackgroundColorType;

    @Output() public selected = new EventEmitter<void>();

    @HostListener('keydown', ['$event'])
    private onKeyDown(event: KeyboardEvent): void {
        if (event.code === 'Space') {
            this.handleSelected(event);
        }
    }

    public onCardClick(event: MouseEvent): void {
        this.handleSelected(event);
    }

    private handleSelected(event: Event): void {
        if (this.clickable) {
            event.preventDefault();
            event.stopPropagation();
            this.selected.emit();
        }
    }
}
