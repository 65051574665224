import { Component, Input } from '@angular/core';

@Component({
    selector: 'wuc-line',
    templateUrl: 'line.component.html',
    styleUrls: ['line.component.scss'],
})
export class WucLineComponent {
    @Input() noMarginTop = false;
    @Input() noMarginBottom = false;
}
