<div class="form-field-label">
  <label [for]="for">
    <div class="wuc-form-field-label__wrapper">
      <div class="wuc-form-field-label__content">
        <ng-content></ng-content>
        <wuc-icon-ui
          *ngIf="info"
          class="wuc-form-field-label__info-icon"
          (click)="onInfoIconClick($event)"
          icon="information"
          type="primary"
        ></wuc-icon-ui>
      </div>
    </div>
  </label>
</div>
<div *ngIf="isInfoVisible">
  <ng-content select="wuc-form-field-label-info"></ng-content>
</div>
