<div
  class="wuc-input-container wuc-input-container--border wuc-select-container"
  [class.wuc-select-container--disabled]="isDisabled"
  [class.wuc-input-container--has-error]="hasError"
>
  <select
    #select
    class="wuc-input wuc-select"
    [class.input--placeholder]="value === null && placeholder"
    [attr.name]="name"
    [attr.autocomplete]="autocomplete"
    [attr.inputmode]="inputmode"
    [attr.aria-label]="ariaLabel"
    [attr.id]="name ?? null"
    [attr.e2e]="e2e ?? null"
    (ngModelChange)="onChange($event)"
    [(ngModel)]="value"
    (blur)="onTouched()"
    [disabled]="isDisabled"
  >
    <option
      *ngIf="placeholder"
      class="wuc-select-option select-option--placeholder"
      [value]="null"
      disabled
      hidden
      [selected]="!value"
    >
      {{ placeholder }}
    </option>
    <ng-container *ngFor="let option of options">
      <option
        class="wuc-select-option"
        [attr.disabled]="option.value === null || option.value === '' ? 'disabled' : null"
        [ngValue]="option.value"
        [selected]="option.value === value"
      >
        <ng-content *ngTemplateOutlet="option.optionContent || emptyTemplate"></ng-content>
      </option>
    </ng-container>
  </select>
  <ng-template #emptyTemplate></ng-template>
</div>
