<wuc-compare-table
  *ngIf="data"
  #compareTable
  [isHoverBorderHidden]="!isHoverBorderVisible"
  [isMobileView]="isMobileView"
  [tableBackgroundColor]="tableBackgroundColor"
  [labelColumnBackgroundColor]="labelColumnBackgroundColor"
  [dataColumnBackgroundColor]="dataColumnBackgroundColor"
  [selectedColumnKey]="selectedColumnKey"
  [extraInfoColumnKey]="data.extraInfo?.columnKey"
  [hasCursorPointer]="hasCursorPointer"
  [labelColumnMultiplier]="1.5"
  (clicked)="onTableClick($event)"
>
  <div slot="extraInfo" *ngIf="data?.extraInfo">
    <h4 class="wuc-compare-table-coverage__extra-info-title">{{ data.extraInfo?.title }}</h4>
    <p>{{ data.extraInfo?.subTitle }}</p>
  </div>
  <wuc-compare-table-column>
    <wuc-compare-table-cell></wuc-compare-table-cell>

    <ng-container *ngTemplateOutlet="data.columnLabels ? columnLabelsTemplate : textLabelsTemplate"></ng-container>

    <ng-template #textLabelsTemplate>
      <ng-container *ngFor="let label of data.labels; let index = index">
        <wuc-compare-table-cell horizontalAlignment="left">
          <span [innerHtml]="label | wucSafeHtml"></span>
          <ng-container *ngIf="index === 0">
            <ng-container *ngTemplateOutlet="firstColumnFirstRow"></ng-container>
          </ng-container>
        </wuc-compare-table-cell>
      </ng-container>
    </ng-template>

    <ng-template #columnLabelsTemplate>
      <ng-container *ngFor="let columnLabel of data.columnLabels; let index = index">
        <wuc-compare-table-cell horizontalAlignment="left">
          <wuc-compare-table-cell-item [item]="columnLabel" [isColumnLabel]="true"></wuc-compare-table-cell-item>
          <ng-container *ngIf="index === 0">
            <ng-container *ngTemplateOutlet="firstColumnFirstRow"></ng-container>
          </ng-container>
        </wuc-compare-table-cell>
      </ng-container>
    </ng-template>

    <wuc-compare-table-cell></wuc-compare-table-cell>
  </wuc-compare-table-column>
  <wuc-compare-table-column
    *ngFor="let column of data.columns; let index = index"
    [key]="column.key"
    [isDisabled]="column.disabled || false"
  >
    <div slot="extraInfo" *ngIf="data.extraInfo?.columnKey === column.key">
      <span>{{ data.extraInfo?.title }}</span>
    </div>
    <wuc-compare-table-cell>
      <span
        [style.font-size]="'clamp(1rem, ' + (column.titleFontSizeCorrection || 3) + 'vw, var(--default-font-size))'"
        class="wuc-compare-table-coverage__title"
        [innerHtml]="column.title | wucSafeHtml"
      ></span>
      <span
        *ngIf="column.subTitle"
        class="wuc-compare-table-coverage__sub-title"
        [innerHtml]="column.subTitle | wucSafeHtml"
      ></span>
    </wuc-compare-table-cell>
    <wuc-compare-table-cell *ngFor="let row of column.rows" [verticalAlignment]="row.verticalAlignment || 'center'">
      <wuc-compare-table-cell-item *ngFor="let item of row.items" [item]="item"></wuc-compare-table-cell-item>
    </wuc-compare-table-cell>
    <wuc-compare-table-cell [preventClick]="true" verticalAlignment="top">
      <div class="wuc-compare-table-coverage__actions">
        <div *ngFor="let action of column.actions">
          <ng-container [ngSwitch]="action.type">
            <ng-container *ngSwitchCase="'button'">
              <wuc-button
                [small]="true"
                [disabled]="action.isDisabled || false"
                [type]="action.isPrimary ? 'primary' : 'secondary'"
                (buttonClick)="onButtonClick(column.key, action)"
                [attr.data-testid]="testIdPrefix + '-' + column.key"
              >
                <span
                  class="wuc-compare-table-coverage__action-text"
                  [innerHtml]="isMobileView ? action.labelMobile || action.label : action.label"
                ></span>
              </wuc-button>
            </ng-container>
            <ng-container *ngSwitchCase="'link'">
              <wuc-link
                [route]="action.routerLink || null"
                [url]="action.href || null"
                [type]="action.isPrimary ? 'primary' : 'secondary'"
                (clicked)="onLinkClick(action)"
              >
                <span
                  class="wuc-compare-table-coverage__action-text"
                  [innerHtml]="isMobileView ? action.labelMobile || action.label : action.label"
                ></span>
              </wuc-link>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </wuc-compare-table-cell>
  </wuc-compare-table-column>
  <div slot="footer" *ngIf="data.footer">
    <p class="wuc-compare-table-coverage__footer" [innerText]="data.footer"></p>
  </div>
</wuc-compare-table>

<ng-template #firstColumnFirstRow>
  <ng-content select="[slot=first-column-first-row]"></ng-content>
</ng-template>
