import { Component, Inject, Input, LOCALE_ID, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { RatingSizeType } from './rating-size.type';

@Component({
    selector: 'wuc-rating[rating]',
    templateUrl: 'rating.component.html',
    styleUrls: ['rating.component.scss'],
})
export class WucRatingComponent implements OnInit, OnChanges {
    @Input() public rating?: number;
    @Input() public size: RatingSizeType = 'medium';
    @Input() public hideDecimalWhenZero: boolean = false;

    public integerPart!: string;
    public seperator?: string;
    public decimalPart?: string;

    constructor(@Inject(LOCALE_ID) private locale: string) {}

    public ngOnInit(): void {
        this.seperator = this.locale === 'nl' ? '.' : ',';
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['rating']) {
            if (this.rating) {
                const parts = this.rating.toFixed(1).split('.');
                this.integerPart = parts[0];
                this.decimalPart = parts[1];
            } else {
                this.integerPart = '';
                this.decimalPart = '';
            }
        }
    }
}
