import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucSafeHtmlModule } from '../../../shared';
import { WucButtonModule, WucPictureModule } from '../../common';
import { WucCompareTableModule } from '../compare-table';
import { WucCompareTableInsurancesComponent } from './compare-table-insurances.component';

@NgModule({
    imports: [CommonModule, WucCompareTableModule, WucPictureModule, WucSafeHtmlModule, WucButtonModule],
    declarations: [WucCompareTableInsurancesComponent],
    exports: [WucCompareTableInsurancesComponent],
})
export class WucCompareTableInsurancesModule {}
