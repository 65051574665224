import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WucCardModule } from '../../common';
import { WucInputRadioModule } from '../input-radio';
import { WucInputRadioCardComponent } from './input-radio-card.component';

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule, WucCardModule, WucInputRadioModule],
    declarations: [WucInputRadioCardComponent],
    exports: [WucInputRadioCardComponent],
})
export class WucInputRadioCardModule {}
