import { WucChatbotListItemElementInterface } from '../chatbot-answer';

export enum WucChatbotItemEnum {
    Question = 'question',
    Answer = 'answer',
}

export interface WucChatbotListItemInterface {
    type: WucChatbotItemEnum;
    value: string | WucChatbotListItemElementInterface[];
}
