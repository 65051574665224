import { Component, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { RackColumnsType } from '../../layout/rack/rack-columns.type';
import { WucInputBaseComponent } from '../input-base.component';
import { InputInterface } from '../input.interface';
import { WucInputRadioCardGroupItemInterface } from './input-radio-card-group-item';

@Component({
    selector: 'wuc-input-radio-card-group',
    templateUrl: 'input-radio-card-group.component.html',
    styleUrls: ['input-radio-card-group.component.scss'],
    providers: [
        {
            provide: WucInputBaseComponent,
            useExisting: WucInputRadioCardGroupComponent,
        },
    ],
    // eslint-disable-next-line
    inputs: WucInputBaseComponent.inputs,
})
export class WucInputRadioCardGroupComponent
    extends WucInputBaseComponent
    implements ControlValueAccessor, InputInterface, OnInit, OnDestroy
{
    @Input() public items!: WucInputRadioCardGroupItemInterface[];

    @Input() public columnsMobile: RackColumnsType = 1;
    @Input() public columnsTablet?: RackColumnsType;
    @Input() public columnsDesktop?: RackColumnsType;
    @Input() public gridGap: 'none' | 'small' | 'medium' | 'large' = 'medium';

    public static selector = 'wuc-input-radio-card-group';
    public static nextId = 0;
    public readonly id: string;

    public formControl = new FormControl<string | {}>('');

    public isDisabled: boolean = false;

    public onChange!: (_: string | {} | null) => void;
    public onTouched!: () => void;

    private subscriptions: Subscription = new Subscription();

    constructor(@Optional() public override ngControl: NgControl) {
        super(ngControl);
        this.id = `${WucInputRadioCardGroupComponent.selector}-${WucInputRadioCardGroupComponent.nextId++}`;
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    public ngOnInit(): void {
        this.subscriptions.add(
            this.formControl.valueChanges.subscribe(() => {
                this.onValueChange();
            })
        );
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public registerOnChange(onChange: (value: string | {} | null) => void): void {
        this.onChange = onChange;
    }

    public registerOnTouched(onTouched: () => void): void {
        this.onTouched = onTouched;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
        if (this.isDisabled) {
            this.formControl.disable();
        } else {
            this.formControl.enable();
        }
    }

    public writeValue(value: string | {} | null): void {
        this.formControl.setValue(value);
    }

    public onValueChange(): void {
        this.onChange(this.formControl.value);
        this.onTouched();
    }
}
