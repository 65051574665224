import { NgModule } from '@angular/core';
import { WucButtonModule } from '../../common';
import { WucNavigationItemModule } from '../navigation-item';
import { WucNavigationComponent } from './navigation.component';

@NgModule({
    declarations: [WucNavigationComponent],
    exports: [WucNavigationComponent],
    imports: [WucButtonModule, WucNavigationItemModule],
})
export class WucNavigationModule {}
