import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucBalloonModule, WucIconUiModule, WucPictureModule } from '../../common';
import { WucChatbotInputModule } from '../chatbot-input';
import { WucChatbotMiniComponent } from './chatbot-mini.component';

@NgModule({
    declarations: [WucChatbotMiniComponent],
    exports: [WucChatbotMiniComponent],
    imports: [WucChatbotInputModule, WucBalloonModule, WucIconUiModule, CommonModule, WucPictureModule],
})
export class WucChatbotMiniModule {}
