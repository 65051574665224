import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WucIconUiModule } from '../../common';
import { WucFormFieldStateModule } from '../form-field-state';
import { WucInputCurrencyComponent } from './input-currency.component';

@NgModule({
    imports: [FormsModule, ReactiveFormsModule, CommonModule, WucIconUiModule, WucFormFieldStateModule],
    declarations: [WucInputCurrencyComponent],
    exports: [WucInputCurrencyComponent],
    providers: [CurrencyPipe],
})
export class WucInputCurrencyModule {}
