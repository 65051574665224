<div
  class="wuc-chatbot"
  [class.wuc-chatbot--is-mobile]="isMobile"
  [class.wuc-chatbot--is-dialog]="isDialog"
  [style.max-height]="maxHeight"
>
  <div #list class="wuc-chatbot__list">
    <wuc-chatbot-list #listComponent [items]="items" (dialogOption)="onDialogOption($event)" [isMobile]="isMobile">
      <div slot="intro">
        <ng-content select="[slot=intro]"></ng-content>
      </div>
      <div *ngIf="escalation" #escalationComponent slot="footer" class="wuc-chatbot__list-footer">
        <wuc-chatbot-escalation
          [isCompactView]="isMobile || isTablet"
          [options]="escalation"
          (feedback)="onFeedback($event)"
          (buttonClick)="onFeedbackButtonClick()"
          [newQuestion$]="newQuestion$"
        />
      </div>
    </wuc-chatbot-list>
  </div>
  <div class="wuc-chatbot__input">
    <wuc-chatbot-input
      #input
      [isDisabled]="isBusy"
      [isMobile]="isMobile"
      [buttonText]="buttonText"
      [placeholder]="placeholder"
      [maxNumberOfCharacters]="maxNumberOfCharacters"
      [maxNumberOfCharactersText]="maxNumberOfCharactersText"
      (question)="onQuestion($event)"
    >
      <ng-container slot="footer">
        <ng-content select="[slot=footer]"></ng-content>
      </ng-container>
    </wuc-chatbot-input>
  </div>
  <div #componentBottom class="wuc-chatbot__bottom"></div>
</div>
