<span
  #dropTrigger
  class="wuc-drop-trigger"
  [class.wuc-drop-trigger--full-width]="fullWidth"
  wucClickOutside
  (clickedOutside)="hide()"
>
  <ng-content select="wuc-button"></ng-content>
  <span
    class="wuc-drop-trigger__trigger-text"
    [class.wuc-drop-trigger__trigger-text--underlined]="!openOnClick"
    (mouseenter)="onMouseEnter()"
    (mouseleave)="onMouseLeave()"
    (click)="onToggle()"
  >
    <ng-content select="[slot=trigger]"></ng-content>
  </span>
  <div
    #drop
    class="wuc-drop-trigger__drop"
    [class.wuc-drop-trigger__drop--hidden]="!isDropVisible"
    [ngClass]="['wuc-drop-trigger__drop--horizontal-' + balloonHorizontalAlignment]"
  >
    <ng-content></ng-content>
  </div>
</span>
