import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'wuc-scribble-circle',
    templateUrl: 'scribble-circle.component.html',
    styleUrls: ['scribble-circle.component.scss'],
})
export class WucScribbleCircleComponent implements OnInit {
    @Input() public scribbleText: string = '';

    public ngOnInit(): void {
        if (this.scribbleText.length > 12) {
            console.error(
                `Maximum length of scribbleText is 12 characters, You are currently using ${this.scribbleText.length}.`
            );
        }
    }
}
