import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WucIconUiModule } from '../../common';
import { WucFormFieldStateModule } from '../form-field-state';
import { WucInputTextComponent } from './input-text.component';

@NgModule({
    imports: [FormsModule, ReactiveFormsModule, CommonModule, WucIconUiModule, WucFormFieldStateModule],
    declarations: [WucInputTextComponent],
    exports: [WucInputTextComponent],
})
export class WucInputTextModule {}
