import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucButtonModule } from '../../common';
import { WucInputReadOnlyComponent } from './input-read-only.component';

@NgModule({
    imports: [WucButtonModule, CommonModule],
    declarations: [WucInputReadOnlyComponent],
    exports: [WucInputReadOnlyComponent],
})
export class WucInputReadOnlyModule {}
