import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucIconUiModule } from '../icon-ui';
import { WucLinkModule } from '../link';
import { WucModalComponent } from './modal.component';

@NgModule({
    declarations: [WucModalComponent],
    exports: [WucModalComponent],
    imports: [WucIconUiModule, CommonModule, WucLinkModule],
})
export class WucModalModule {}
