import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { ArrowPositionType } from './balloon-arrow-position.type';

@Component({
    selector: 'wuc-balloon',
    templateUrl: 'balloon.component.html',
    styleUrls: ['balloon.component.scss'],
})
export class WucBalloonComponent implements AfterViewInit {
    private _arrowPosition: ArrowPositionType = 'topMiddle';

    @Input() @HostBinding('class.wuc-balloon--full-width') public isFullWidth: boolean = false;

    @Input()
    public set arrowPosition(arrowPosition: ArrowPositionType) {
        this._arrowPosition = arrowPosition;
        this.setArrowLocation();
    }

    public get arrowPosition(): ArrowPositionType {
        return this._arrowPosition;
    }

    @Input() public backgroundStyle: 'primary' | 'secondary' | 'tertiary' | 'primary-dashed' = 'primary';
    @Input() public padding: 'none' | 'small' | 'medium' | 'large' = 'small';
    /**
     * @deprecated use backgroundStyle='primary-dashed'.
     */
    @Input() public borderStyle: 'solid' | 'dashed' = 'solid';
    /**
     * @deprecated never a shadow.
     */
    @Input() public hideShadow: boolean = false;
    @Input() public minWidth?: string;

    private _hasCloseIcon: boolean = false;
    @Input() public set hasCloseIcon(hasCloseIcon: boolean) {
        this._hasCloseIcon = hasCloseIcon;
        this.changeDetectorRef.detectChanges();
    }

    public get hasCloseIcon(): boolean {
        return this._hasCloseIcon;
    }

    @Output() public closeClicked: EventEmitter<Event> = new EventEmitter<Event>();

    @ViewChild('balloonContent', { read: ElementRef })
    public contentElementRef!: ElementRef;

    @ViewChild('balloonArrow', { read: ElementRef })
    public arrowElementRef!: ElementRef;

    public arrowSide$: Subject<string> = new Subject<string>();
    public arrowPosition$: Subject<string> = new Subject<string>();

    private style!: CSSStyleDeclaration;

    constructor(private changeDetectorRef: ChangeDetectorRef) {}

    public ngAfterViewInit(): void {
        this.style = this.arrowElementRef.nativeElement.style;
        this.setArrowLocation();
        this.changeDetectorRef.detectChanges();
    }

    public setArrowLocation(): void {
        const parts = this.arrowPosition.split(/(?=[A-Z])/);
        if (parts.length === 2) {
            this.arrowSide$.next(parts[0]);
            this.arrowPosition$.next(parts[1].toLowerCase());
        }
    }

    public resetArrowOffset(): void {
        this.style.removeProperty('transform');
        this.style.removeProperty('left');
        this.style.removeProperty('right');
    }

    public setArrowRight(right: number): void {
        this.style.transform = 'translateX(1.2rem)';
        this.style.left = 'unset';
        this.style.right = `${right}px`;
    }

    public setArrowLeft(left: number): void {
        this.style.transform = 'translateX(-1.2rem)';
        this.style.right = 'unset';
        this.style.left = `${left}px`;
    }

    public onCloseIconClick(event: Event): void {
        this.closeClicked.emit(event);
    }
}
