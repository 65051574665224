export enum KeyCodeEnum {
    ArrowDown = 'ArrowDown',
    ArrowUp = 'ArrowUp',
    ArrowLeft = 'ArrowLeft',
    ArrowRight = 'ArrowRight',
    Backspace = 'Backspace',
    End = 'End',
    Enter = 'Enter',
    Escape = 'Escape',
    Home = 'Home',
    PageDown = 'PageDown',
    PageUp = 'PageUp',
    Tab = 'Tab',
}
