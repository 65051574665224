import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
    WucAccordionModule,
    WucButtonModule,
    WucCardModule,
    WucIconModule,
    WucIconUiModule,
    WucLinkModule,
} from '../../common';
import { WucInputTextareaModule } from '../../forms';
import { WucChatbotEscalationComponent } from './chatbot-escalation.component';

@NgModule({
    declarations: [WucChatbotEscalationComponent],
    exports: [WucChatbotEscalationComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        WucButtonModule,
        WucCardModule,
        WucIconUiModule,
        WucInputTextareaModule,
        WucLinkModule,
        WucAccordionModule,
        WucIconModule,
    ],
})
export class WucChatbotEscalationModule {}
