import { NgModule } from '@angular/core';
import { WucFormFieldStateModule } from '../form-field-state';
import { WucInputIbanNLComponent } from './input-iban-nl.component';

@NgModule({
    declarations: [WucInputIbanNLComponent],
    exports: [WucInputIbanNLComponent],
    imports: [WucFormFieldStateModule],
})
export class WucInputIbanNlModule {}
