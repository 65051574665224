import { Component, Input, TemplateRef, ViewChild } from '@angular/core';

@Component({
    selector: 'wuc-summary-table-value',
    templateUrl: 'summary-table-value.component.html',
    styleUrls: ['summary-table-value.component.scss'],
})
export class WucSummaryTableValueComponent {
    @Input()
    public isSmall: boolean = false;

    @ViewChild('summaryTableValue', { read: TemplateRef })
    public templateRef!: TemplateRef<HTMLElement>;
}
