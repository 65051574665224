import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WucSelectOptionComponent } from './select-option.component';

@NgModule({
    imports: [FormsModule, ReactiveFormsModule, CommonModule],
    declarations: [WucSelectOptionComponent],
    exports: [WucSelectOptionComponent],
})
export class WucSelectOptionModule {}
