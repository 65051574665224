<div
  class="wuc-navigation-item"
  [class.wuc-navigation-item--opened]="opened"
  [class.wuc-navigation-item--show-divider]="showDivider"
  [class.wuc-navigation-item--width-full]="width === 'full'"
  [class.wuc-navigation-item--first]="firstItem"
  [class.wuc-navigation-item--last]="lastItem"
  (mouseover)="isHovered = !isHovered"
  (mouseout)="isHovered = !isHovered"
>
  <a
    *ngIf="route && !url"
    class="wuc-navigation-item__button"
    [routerLink]="route"
    [routerLinkActiveOptions]="{ exact: true }"
    routerLinkActive="wuc-navigation-item__button--active"
    #rla="routerLinkActive"
    tabindex="0"
  >
    <ng-container *ngTemplateOutlet="label; context: { $implicit: rla.isActive || isHovered }"></ng-container>
  </a>
  <a
    *ngIf="!route && url"
    class="wuc-navigation-item__button"
    [href]="url"
    tabindex="0"
  >
    <ng-container *ngTemplateOutlet="label; context: { $implicit: isActive || isHovered }"></ng-container>
  </a>
  <ng-container *ngIf="!route && !url">
    <span class="wuc-navigation-item__button" (click)="toggle()" (keyup.enter)="toggle()" tabindex="0">
      <ng-container *ngTemplateOutlet="label; context: { $implicit: isActive }"></ng-container>
      <span class="wuc-navigation-item__icon-toggle">
        <wuc-icon-ui icon="bottom-open" size="xs" [type]="isActive ? 'primary' : 'dark'"></wuc-icon-ui>
      </span>
    </span>
    <div
      #content
      class="wuc-navigation-item__content"
      [class.wuc-navigation-item__content--position-left]="position === 'left'"
      [class.wuc-navigation-item__content--position-right]="position === 'right'"
      [class.wuc-navigation-item__content--width-full]="width === 'full'"
      [class.wuc-navigation-item__content--width-large]="width === 'large'"
    >
      <ng-content></ng-content>
    </div>
  </ng-container>
</div>

<ng-template #label let-isActive>
  <span *ngIf="icon" class="wuc-navigation-item__icon">
    <wuc-icon-ui [icon]="icon" size="xs" [type]="isActive ? 'primary' : 'dark'"></wuc-icon-ui>
  </span>
  <span class="wuc-navigation-item__name" [class.wuc-navigation-item__name--active]="isActive === true">
    {{ name }}
  </span>
</ng-template>
