import { Inject, Injectable } from '@angular/core';
import { InputPhonenumberCountryInterface } from './input-phonenumber-country.interface';
import { InputPhonenumberFormatResultInterface } from './input-phonenumber-format-result.interface';
import { InputPhonenumberNumberFormatInterface } from './input-phonenumber-number-format.interface';
import { InputPhonenumberPrefixTypeEnum } from './input-phonenumber-prefix-type.enum';

@Injectable()
export class WucInputPhonenumberService {
    // service based on https://catamphetamine.gitlab.io/libphonenumber-js/

    constructor(@Inject('phonenumberConfig') private phonenumberConfig: InputPhonenumberCountryInterface) {}

    /**
     * Formats the given phonenumer
     * @param phonenumber - User phonenumber input, possibly containing dashes, spaces, etc.
     * @return The formatted phonenumber
     */
    public format(phonenumber: string): InputPhonenumberFormatResultInterface | null {
        if (!phonenumber) {
            return {
                formatted: '',
                cleaned: '',
            };
        }

        const sanitized = this.sanitize(phonenumber);
        const usedCountryCode = this.getPhonenumberCountryCode(sanitized);

        if (usedCountryCode && usedCountryCode !== this.phonenumberConfig.countryCode) {
            return null;
        }

        const usedPrefix = this.getPhonenumberPrefixType(sanitized);
        const dePrefixed = this.removePrefix(sanitized);
        const matchingNumberFormat = this.getMatchingNumberFormat(dePrefixed);
        const formatted = matchingNumberFormat
            ? this.applyFormat(dePrefixed, matchingNumberFormat.pattern, matchingNumberFormat.format)
            : dePrefixed;

        return {
            formatted: this.applyPrefix(formatted, usedPrefix, true),
            cleaned: this.applyPrefix(dePrefixed, usedPrefix, false),
        };
    }

    /**
     * Removes al non-numeric characters from the phonenumber except a leading '+'
     * @param phonenumber - User phonenumber input, possibly containing dashes, spaces, etc.
     * @return The input without non-numeric characters except a leading '+'
     */
    private sanitize(phonenumber: string): string {
        return phonenumber.replace(/(?!^\+)[^\d]/g, '');
    }

    /**
     * Determines the country code for the given number
     * @param phonenumber - Sanitized phonenumber, may only contain numbers and a leading '+'
     * @return The used country code or null
     */
    private getPhonenumberCountryCode(phonenumber: string): string | null {
        return phonenumber.match(/^(\+|00)(?<countryCode>\d{2})/)?.groups?.['countryCode'] || null;
    }

    /**
     * Determines the way the number is entered, national or international with leading zeros or the + sign
     * @param phonenumber - Sanitized phonenumber, may only contain numbers and a leading '+'
     * @return The detected prefix type
     */
    private getPhonenumberPrefixType(phonenumber: string): InputPhonenumberPrefixTypeEnum | null {
        if (phonenumber.startsWith('+')) {
            return InputPhonenumberPrefixTypeEnum.InternationalPlus;
        } else if (phonenumber.startsWith(this.phonenumberConfig.internationalPrefix)) {
            return InputPhonenumberPrefixTypeEnum.InternationalZeros;
        }
        return InputPhonenumberPrefixTypeEnum.National;
    }

    /**
     * Removes the prefix leaving the bare national number (without leading 0)
     * e.g. +316123456789, +3106123456789, 00316123456789, 003106123456789 or 06123456789 --> 6123456789
     * @param phonenumber - Sanitized phonenumber, may only contain numbers and optional a leading '+'
     * @return A phonenumber without leading 0's of '+' sign
     */
    private removePrefix(phonenumber: string): string {
        return phonenumber.replace(/^(\+\d{2}0?|00\d{2}0?|0)/, '');
    }

    /**
     * Returns the matching number format
     * @param phonenumber - Sanitized phonenumber whithout prefix
     * @return A mathing number format
     */
    private getMatchingNumberFormat(phonenumber: string): InputPhonenumberNumberFormatInterface | undefined {
        return this.phonenumberConfig.formats.find((item: InputPhonenumberNumberFormatInterface) =>
            item.testPattern.some((pattern: string) => phonenumber.match(new RegExp(`^(${pattern})`)))
        );
    }

    /**
     * Formats phonenumber
     * @param phonenumber - Sanitized phonenumber whithout prefix
     * @param pattern - regular expression for grouping digits (e.g. '(\\d{2})(\\d{3})(\\d{2})(\\d{2})')
     * @param format - string containing format structure (e.g. '$1 - $2 $3 $4')
     * @return A formatted phonenumber without prefix
     */
    private applyFormat(phonenumber: string, pattern: string, format: string): string {
        return phonenumber.replace(new RegExp(pattern), format);
    }

    /**
     * Adds prefix according to a previously determined prefix type
     * @param phonenumber - phonenumber whithout prefix
     * @param prefixType - type of prefix to prepend
     * @param useSpacing - adds a space between prefix and phonenumber
     * @return The given phonenumber with prefix added
     */
    private applyPrefix(
        phonenumber: string,
        prefixType: InputPhonenumberPrefixTypeEnum | null,
        useSpacing: boolean = true
    ): string {
        const space = useSpacing ? ' ' : '';
        switch (prefixType) {
        case InputPhonenumberPrefixTypeEnum.National:
            return `${this.phonenumberConfig.nationalPrefix}${phonenumber}`;
        case InputPhonenumberPrefixTypeEnum.InternationalZeros:
            // eslint-disable-next-line max-len
            return `${this.phonenumberConfig.internationalPrefix}${this.phonenumberConfig.countryCode}${space}${phonenumber}`;
        case InputPhonenumberPrefixTypeEnum.InternationalPlus:
            return `+${this.phonenumberConfig.countryCode}${space}${phonenumber}`;
        }

        /* istanbul ignore next */
        return phonenumber;
    }
}
