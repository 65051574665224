<div
  #explanationArrow
  [class.wuc-explanation-arrow--full-width]="isFullWidth"
  [class]="['wuc-explanation-arrow', 'wuc-explanation-arrow--' + type + '-' + iconPositionClass]"
>
  <div
    #explanationArrowContent
    class="wuc-explanation-arrow__content"
    [class.wuc-explanation-arrow__content--hidden]="!explanationArrowContent.innerHTML"
  >
    <ng-content></ng-content>
  </div>
  <div *ngIf="text" class="wuc-explanation-arrow__message">
    <wuc-icon class="wuc-explanation-arrow__icon" [icon]="icon" [size]="size"></wuc-icon>
    <span
      [class]="['wuc-explanation-arrow__text', 'wuc-explanation-arrow__text--' + fontSize]"
      [class.wuc-explanation-arrow__text--unset-wrap]="type === 'tertiary' && position === 'bottomLeft'"
      [innerHtml]="text | wucSafeHtml"
    ></span>
  </div>
</div>
