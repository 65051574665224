import { NgModule } from '@angular/core';
import { WucIconUiModule } from '../../common';
import { WucInputLicensePlateNlComponent } from './input-license-plate-nl.component';

@NgModule({
    imports: [WucIconUiModule],
    declarations: [WucInputLicensePlateNlComponent],
    exports: [WucInputLicensePlateNlComponent],
})
export class WucInputLicensePlateNlModule {}
