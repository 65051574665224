import { Component, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { WucInputBaseComponent } from '../input-base.component';
import { InputInterface } from '../input.interface';

@Component({
    selector: 'wuc-input-radio-card',
    templateUrl: 'input-radio-card.component.html',
    providers: [
        {
            provide: WucInputBaseComponent,
            useExisting: WucInputRadioCardComponent,
        },
    ],
    // eslint-disable-next-line
    inputs: WucInputBaseComponent.inputs,
})
export class WucInputRadioCardComponent
    extends WucInputBaseComponent
    implements ControlValueAccessor, InputInterface, OnInit, OnDestroy {
    @Input() public value!: string | {};
    @Input() public testId?: string;
    /**
     * Do not use 'id' as it's a reserved attribute name
     */
    @Input() public inputId?: string;

    public static selector = 'wuc-input-radio-card';
    public static nextId = 0;

    public formControl = new UntypedFormControl();
    public isDisabled: boolean = false;

    public onChange!: (_: string | {}) => void;
    public onTouched!: () => void;

    private subscriptions: Subscription = new Subscription();

    constructor(@Optional() public override ngControl: NgControl) {
        super(ngControl);
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    public ngOnInit(): void {
        if (this.ngControl.control) {
            this.subscriptions.add(
                this.ngControl.control.valueChanges.subscribe((value) => {
                    if (this.value !== value) {
                        this.formControl.setValue(value);
                    }
                })
            );
        }
        if (!this.inputId) {
            this.inputId = `${WucInputRadioCardComponent.selector}-${WucInputRadioCardComponent.nextId++}`;
        }
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public registerOnChange(onChange: (value: string | {}) => void): void {
        this.onChange = onChange;
    }

    public registerOnTouched(onTouched: () => void): void {
        this.onTouched = onTouched;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
        if (this.isDisabled) {
            this.formControl.disable();
        } else {
            this.formControl.enable();
        }
    }

    public writeValue(value: string | {}): void {
        this.formControl.setValue(value);
    }

    public onValueChange(): void {
        this.onChange(this.value);
        this.onTouched();
    }

    public onCardClick(): void {
        this.formControl.setValue(this.value);
        this.onValueChange();
    }
}
