<a
    *ngIf="route && !url"
    [routerLink]="route"
    [fragment]="routeFragment"
    [queryParams]="queryParams"
    routerLinkActive="wuc-link__anchor--active"
    [routerLinkActiveOptions]="{ exact: isRouterLinkActiveExact }"
    [attr.rel]="relJoined"
    [attr.title]="linkTitle"
    (click)="onClick()"
    class="wuc-link__anchor-base"
>
    <ng-container *ngTemplateOutlet="linkBody"/>
</a>

<a
    *ngIf="!route && url"
    [href]="url"
    [attr.title]="linkTitle"
    [attr.rel]="relJoined"
    [attr.target]="target || (openUrlInNewTab ? '_blank' : null)"
    (click)="onClick()"
    class="wuc-link__anchor-base"
>
    <ng-container *ngTemplateOutlet="linkBody"/>
</a>

<ng-container *ngIf="!route && !url">
    <ng-container *ngTemplateOutlet="linkBody"/>
</ng-container>

<ng-template #linkBody>
    <span
        class="wuc-link"
        [class.wuc-link--primary]="type === 'primary'"
        [class.wuc-link--secondary]="type === 'secondary'"
        [class.wuc-link--tertiary]="type === 'tertiary'"
        [class.wuc-link--quaternary]="type === 'quaternary'"
        [class.wuc-link--quinary]="type === 'quinary'"
        [class.wuc-link--button]="type === 'button-primary' || type === 'button-secondary'"
        [class.wuc-button]="type === 'button-primary' || type === 'button-secondary'"
        [class.wuc-button--primary]="type === 'button-primary'"
        [class.wuc-button--secondary]="type === 'button-secondary'"
        [class.wuc-button--small]="isButtonSmall"
        [class.wuc-link--is-full-width]="isFullWidth"
    >
        <span class="wuc-link__drop-trigger-container" *ngIf="iconLeft && hasDropTrigger">
            <wuc-drop-trigger
                class="wuc-link__drop-trigger"
                [balloonHasCloseIcon]="true"
                [openOnClick]="true"
                balloonHorizontalAlignment="left"
            >
                <wuc-button
                    type="clean"
                    iconType="primary"
                    [small]="true"
                    [icon]="iconLeft"
                    [title]="linkTitle ?? ''"
                ></wuc-button>
                <wuc-balloon minWidth="30rem" padding="medium">
                    <ng-content select="[slot=dropTriggerContent]"/>
                </wuc-balloon>
            </wuc-drop-trigger>
        </span>
        <ng-container *ngIf="!route && !url; else contentTemplate">
            <span
                class="wuc-link__anchor"
                [class.wuc-link__anchor--no-wrap]="noWrap"
                tabindex="0"
                [attr.title]="linkTitle"
                (click)="onClick($event)"
                (keyup)="onKeyUp($event)"
            >
                <ng-container *ngTemplateOutlet="content"/>
            </span>
        </ng-container>

        <ng-template #contentTemplate>
            <span class="wuc-link__anchor" [class.wuc-link__anchor--no-wrap]="noWrap">
                <ng-container *ngTemplateOutlet="content"/>
            </span>
        </ng-template>
    </span>
</ng-template>

<ng-template #content>
    <span class="wuc-link__icon wuc-link__icon--left" *ngIf="iconLeft && !hasDropTrigger">
        <wuc-icon-ui
            [icon]="iconLeft"
            [size]="iconSize"
            [type]="type === 'button-primary' ? 'white' : 'primary'"
        ></wuc-icon-ui>
    </span>
    <span class="wuc-link__content">
        <ng-content></ng-content>
    </span>
    <span class="wuc-link__icon wuc-link__icon--right" *ngIf="iconRight">
        <wuc-icon-ui
            [icon]="iconRight"
            [size]="iconSize"
            [type]="type === 'button-primary' ? 'white' : 'primary'"
        ></wuc-icon-ui>
    </span>
</ng-template>
