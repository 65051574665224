import { DOCUMENT } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    ElementRef,
    EventEmitter,
    HostListener,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { BackgroundColorType } from '../../../core';
import { WucCompareTableInterface } from './compare-table.interface';
import { WucCompareTableCellItemComponent, WucCompareTableColumnComponent } from './components';

@Component({
    selector: 'wuc-compare-table',
    templateUrl: 'compare-table.component.html',
    styleUrls: ['compare-table.component.scss'],
})
export class WucCompareTableComponent implements WucCompareTableInterface, OnInit, OnChanges, AfterViewInit, OnDestroy {
    @Output() public clicked = new EventEmitter<string>();

    @Input() public hasCursorPointer: boolean = false;
    @Input() public isHoverBorderHidden: boolean = false;
    @Input() public isBorderVisible: boolean = false;
    @Input() public tableBackgroundColor: BackgroundColorType = 'light';
    @Input() public labelColumnBackgroundColor: BackgroundColorType = 'light';
    @Input() public dataColumnBackgroundColor: BackgroundColorType = 'dark';
    @Input() public extraInfoColumnKey?: string;
    @Input() public selectedColumnKey?: string;
    @Input() public dataColumnsInMobileView: number = 2;
    @Input() public labelColumnMultiplier: number = 2;

    private _isMobileView: boolean = false;

    @Input()
    public set isMobileView(isMobileView: boolean) {
        this._isMobileView = isMobileView;

        if (this.isInitDone) {
            this.isSwipeHintDisabled = false;
            this.repositionColumns();
        }
        this.changeDetectorRef.detectChanges();
    }

    public get isMobileView(): boolean {
        return this._isMobileView;
    }

    @ViewChild('dropContainer')
    public dropContainer!: ElementRef;

    @ViewChild('compareTable')
    public compareTable!: ElementRef;

    @ViewChild('extraInfoTemplate', { read: TemplateRef })
    public extraInfoTemplateRef!: TemplateRef<HTMLElement>;

    @ContentChildren(WucCompareTableColumnComponent)
    public columns: QueryList<WucCompareTableColumnComponent> = new QueryList<WucCompareTableColumnComponent>();

    @ContentChildren(WucCompareTableCellItemComponent, { descendants: true })
    public cellItems: QueryList<WucCompareTableCellItemComponent> = new QueryList<WucCompareTableCellItemComponent>();

    public labelColumn!: WucCompareTableColumnComponent;
    public scrollColumns: WucCompareTableColumnComponent[] = [];
    public columnCount: number = 0;
    public rowCount: number = 0;
    public columnWidth: number = 0;
    public currentColumnIndex: number = 0;
    public isUserSwipeMoving: boolean = false;
    public isInitDone: boolean = false;

    private isSwipeHintDisabled: boolean = false;
    private userSwipeStartValue: number = 0;
    private userSwipeStartOffset: number = 0;
    private userSwipeNewOffset: number = 0;
    private isUserSwipeStarted: boolean = false;
    private isMouseDown: boolean = false;
    private isTouched: boolean = false;
    private subscriptions: Subscription = new Subscription();

    // Bind Touch Events
    @HostListener('touchstart', ['$event'])
    public onTouchStart(event: TouchEvent): void {
        if (this.isMobileView) {
            this.startUserSwipeTracking(event.targetTouches[0].pageX);
        }
    }

    @HostListener('touchmove', ['$event'])
    public onTouchMove(event: TouchEvent): void {
        if (this.isMobileView) {
            this.onUserSwipeMove(event.targetTouches[0].pageX);
        }
    }

    @HostListener('touchend')
    public onTouchEnd(): void {
        this.endUserSwipeTracking();
    }

    // Bind Mouse Events
    @HostListener('mousedown', ['$event'])
    public onMouseDown(event: MouseEvent): void {
        if (this.isMobileView) {
            this.isMouseDown = true;
            this.startUserSwipeTracking(event.clientX);
            event.preventDefault();
        }
    }

    @HostListener('mousemove', ['$event'])
    public onMouseMove(event: MouseEvent): void {
        if (this.isMobileView && this.isMouseDown) {
            this.onUserSwipeMove(event.clientX);
        }
    }

    @HostListener('mouseleave')
    @HostListener('mouseup')
    public onMouseEnd(): void {
        this.isMouseDown = false;
        this.endUserSwipeTracking();
    }

    constructor(private changeDetectorRef: ChangeDetectorRef, @Inject(DOCUMENT) private document: Document) {}

    public ngOnInit(): void {
        this.subscriptions.add(
            fromEvent<Event>(this.document.defaultView as Window, 'resize')
                .pipe(debounceTime(10))
                .subscribe(() => {
                    this.repositionColumns();
                })
        );
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['selectedColumnKey']) {
            this.onSelectedColumnChange();
        }
        if (changes['dataColumnsInMobileView']) {
            this.setColumnPositions(0, this.currentColumnIndex);
            this.changeDetectorRef.detectChanges();
        }
    }

    public ngAfterViewInit(): void {
        this.initialize();
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public reInitialize(): void {
        setTimeout(() => this.initialize());
    }

    public initialize(): void {
        this.initColumns();

        this.currentColumnIndex = this.getColumnIndexByKey(this.selectedColumnKey);

        this.changeDetectorRef.detectChanges();
        this.setColumnPositions(0, this.currentColumnIndex);
        this.changeDetectorRef.detectChanges();

        this.initSetDropContainer();

        this.isInitDone = true;
    }

    public handleClick(columnKey: string): void {
        if (this.userSwipeStartOffset === this.userSwipeNewOffset) {
            this.clicked.emit(columnKey);
        }
    }

    private initColumns(): void {
        if (this.columns.length < 3) {
            throw new Error('There must be at least three columns, one label- and two data columns');
        }
        this.columns.forEach((column: WucCompareTableColumnComponent) => column.setParent(this));
        /* set the label column */
        this.labelColumn = this.columns.first;
        this.labelColumn.isLabelColumn = true;
        this.labelColumn.isFixed = true;
        this.labelColumn.index = -1;
        /* get the scroll columns*/
        this.scrollColumns = [...this.columns].slice(1);
        this.scrollColumns.forEach((column: WucCompareTableColumnComponent, index: number) => {
            column.index = index;
            column.resetOffset();
        });
        this.columnCount = this.columns.length;
        this.rowCount = this.labelColumn.cells.length;

        this.setLastColumn();
    }

    private repositionColumns(): void {
        this.temporarilyDisableSmoothAnimation();

        if (this.isMobileView) {
            this.setColumnPositions(0, this.currentColumnIndex);
        } else {
            this.resetColumns();
        }
    }

    public redraw(): void {
        this.repositionColumns();
    }

    public get hasExtraInfo(): boolean {
        return !!this.extraInfoColumnKey;
    }

    public getGridTemplateColumnsStyle(): string {
        const dataColumnCount = this.isMobileView ? this.dataColumnsInMobileView : this.columnCount - 1;
        const columnPercentage = 100 / (dataColumnCount + this.labelColumnMultiplier);
        const labelColumnPercentage = `${columnPercentage * this.labelColumnMultiplier}%`;
        const dataColumnPercentage = `${columnPercentage}%`;

        return `[first] ${labelColumnPercentage} repeat(${this.columnCount - 1}, ${dataColumnPercentage})`;
    }

    public getGridTemplateRowsStyle(): string | null {
        let rowCount = this.rowCount;
        if (this.hasExtraInfo) {
            rowCount++;
        }
        return `repeat(${rowCount}, auto)`;
    }

    public get arrowPositionPercentage(): number {
        const columnIndex = [...this.scrollColumns].findIndex(
            (column: WucCompareTableColumnComponent) => column.key === this.extraInfoColumnKey
        );
        return (100 / this.scrollColumns.length) * (columnIndex + 0.5);
    }

    private onSelectedColumnChange(): void {
        let newSelectedColumnIndex: number = this.getColumnIndexByKey(this.selectedColumnKey);

        if (this.isMobileView) {
            this.setColumnPositions(this.currentColumnIndex, newSelectedColumnIndex);

            this.scrollColumns.forEach((column: WucCompareTableColumnComponent) => {
                if (column.index === this.currentColumnIndex) {
                    column.startShrinkAnimation();
                } else if (!column.isFixed) {
                    column.temporarilyHide();
                }
            });

            this.setLastColumn();
        }

        this.currentColumnIndex = newSelectedColumnIndex;
        this.changeDetectorRef.detectChanges();
    }

    private setColumnPositions(lastColumnIndex: number, newColumnIndex: number): void {
        this.scrollColumns.forEach((column: WucCompareTableColumnComponent) => {
            if (column.index === newColumnIndex) {
                column.columnOrderOffset = column.index;
                column.isFixed = true;
            } else if (column.index < newColumnIndex) {
                column.columnOrderOffset = -1;
                column.isFixed = false;
            } else {
                column.columnOrderOffset = 0;
                column.isFixed = false;
            }
        });

        this.setScrollColumnsOffset(
            lastColumnIndex + (newColumnIndex >= 0 && newColumnIndex < lastColumnIndex ? -1 : 0)
        );

        this.setColumnWidth();

        this.setLastColumn();
    }

    private setColumnWidth(): void {
        this.columnWidth =
            this.compareTable.nativeElement.clientWidth / (this.dataColumnsInMobileView + this.labelColumnMultiplier);
    }

    private startUserSwipeTracking(currentPosition: number): void {
        this.isTouched = true;
        this.setColumnWidth();
        this.userSwipeStartValue = currentPosition;
        this.userSwipeStartOffset = this.userSwipeNewOffset;
        this.isUserSwipeStarted = true;
        this.isSwipeHintDisabled = true;
    }

    public newOffset: number = 0;
    public movement: number = 0;

    private onUserSwipeMove(position: number): void {
        if (!this.isUserSwipeStarted) {
            return;
        }

        let movement = this.userSwipeStartValue - position;
        const newOffset = this.userSwipeStartOffset + movement;

        if (newOffset < 0) {
            movement = -this.userSwipeStartOffset;
        }

        const scrollColumnsWidth = (this.scrollColumns.length - this.dataColumnsInMobileView) * this.columnWidth;

        this.newOffset = newOffset;
        this.movement = movement;

        if (newOffset > scrollColumnsWidth) {
            movement = scrollColumnsWidth - this.userSwipeStartOffset;
        }

        this.setScrollColumnsIncrementalOffset(movement);
        this.isUserSwipeMoving = true;
    }

    private endUserSwipeTracking(): void {
        if (!this.isUserSwipeMoving) {
            return;
        }

        const itemsScrollIndexFractal = this.userSwipeNewOffset / this.columnWidth;
        const roundedOffset = Math.round(itemsScrollIndexFractal);

        this.setScrollColumnsOffset(roundedOffset);

        this.setLastColumn();

        this.isUserSwipeMoving = false;
        this.isUserSwipeStarted = false;
        this.isMouseDown = false;
    }

    private setScrollColumnsIncrementalOffset(offset: number): void {
        this.userSwipeNewOffset = this.userSwipeStartOffset + offset;
        this.scrollColumns.forEach((column: WucCompareTableColumnComponent) => {
            column.relativeOffset = offset;
        });
    }

    public setScrollColumnsOffset(offset: number): void {
        this.scrollColumns.forEach((column: WucCompareTableColumnComponent) => {
            column.offset = offset;
            column.relativeOffset = 0;
        });
    }

    public get showSwipeHand(): boolean {
        return this.isMobileView && this.scrollColumns.length > 2;
    }

    public get showSwipeHint(): boolean {
        return !this.isSwipeHintDisabled && this.showSwipeHand;
    }

    private resetColumns(): void {
        this.scrollColumns.forEach((column: WucCompareTableColumnComponent) => {
            column.resetOffset();
        });
        this.setLastColumn();
    }

    private temporarilyDisableSmoothAnimation(): void {
        this.scrollColumns.forEach((column: WucCompareTableColumnComponent) =>
            column.temporarilyDisableSmoothAnimation()
        );
    }

    private getColumnIndexByKey(key: string | undefined): number {
        if (!key) {
            return -1;
        }
        return this.scrollColumns.findIndex((column: WucCompareTableColumnComponent) => column.key === key);
    }

    private initSetDropContainer(): void {
        this.subscriptions.add(
            this.cellItems.changes.subscribe((items: QueryList<WucCompareTableCellItemComponent>) =>
                items.forEach((item: WucCompareTableCellItemComponent) =>
                    item.setDropContainerElement(this.dropContainer.nativeElement)
                )
            )
        );
        this.cellItems.notifyOnChanges();
    }

    private setLastColumn(): void {
        if (this.scrollColumns.length) {
            this.scrollColumns.forEach((column: WucCompareTableColumnComponent) => {
                column.isLastColumn = false;
            });
            if (this.isMobileView) {
                this.scrollColumns.reduce(
                    (prev: WucCompareTableColumnComponent, current: WucCompareTableColumnComponent) =>
                        prev.index - prev.columnOrderOffset > current.index - current.columnOrderOffset ? prev : current
                ).isLastColumn = true;
            } else {
                this.columns.last.isLastColumn = true;
            }
        }
    }
}
