import { NgModule } from '@angular/core';
import { WucIconUiModule } from '../../common';
import { WucFormFieldStateModule } from '../form-field-state';
import { WucInputPhonenumberComponent } from './input-phonenumber.component';
import { WucInputPhonenumberService } from './input-phonenumber.service';

@NgModule({
    imports: [WucFormFieldStateModule, WucIconUiModule],
    declarations: [WucInputPhonenumberComponent],
    exports: [WucInputPhonenumberComponent],
    providers: [WucInputPhonenumberService],
})
export class WucInputPhonenumberModule {}
