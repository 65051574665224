import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { WucBalloonModule } from '../balloon';
import { WucButtonModule } from '../button';
import { WucDropTriggerModule } from '../drop-trigger';
import { WucIconUiModule } from '../icon-ui';
import { WucLinkComponent } from './link.component';

@NgModule({
    imports: [CommonModule, RouterModule, WucIconUiModule, WucDropTriggerModule, WucBalloonModule, WucButtonModule],
    declarations: [WucLinkComponent],
    exports: [WucLinkComponent],
})
export class WucLinkModule {}
