import { Component, Optional } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { WucInputBaseComponent } from '../input-base.component';
import { InputInterface } from '../input.interface';

@Component({
    selector: 'wuc-input-checkbox',
    templateUrl: 'input-checkbox.component.html',
    styleUrls: ['input-checkbox.component.scss'],
    // eslint-disable-next-line
    inputs: WucInputBaseComponent.inputs,
    providers: [{ provide: WucInputBaseComponent, useExisting: WucInputCheckboxComponent }],
})
export class WucInputCheckboxComponent extends WucInputBaseComponent implements ControlValueAccessor, InputInterface {
    public isDisabled: boolean = false;
    public value?: string;
    public onChange!: (value: string) => void;
    public onTouched!: () => void;

    constructor(@Optional() public override ngControl: NgControl) {
        super(ngControl);
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    // Update value from API; API => view
    public writeValue(value: string): void {
        this.value = value;
    }

    // Update value from view "on change"; View => API
    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    // Update value from view "on blur"; View => API
    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    // Set disabled state from API
    public setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    public onValueChange(value: string): void {
        this.writeValue(value);
        this.onChange(value);
        this.onTouched();
    }
}
