import { Component, Input } from '@angular/core';
import { IconGeneralIcon } from '../icon';

@Component({
    selector: 'wuc-page-block',
    templateUrl: 'page-block.component.html',
    styleUrls: ['page-block.component.scss'],
})
export class WucPageBlockComponent {
    @Input() public blockType: 'solid' | 'open' = 'solid';
    @Input() public icon?: IconGeneralIcon;
    @Input() public skeletonHeight: string = '6.4rem';
    @Input() public isLoading: boolean = false;
    @Input() public isMobile: boolean = false;
    @Input() public hideHeader: boolean = false;
    /**
     * @deprecated Use `isLoading` instead.
     */
    @Input() public skeleton: boolean = false;
}
