import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WucFormFieldStateModule } from '../form-field-state';
import { WucInputCheckboxComponent } from './input-checkbox.component';

@NgModule({
    imports: [FormsModule, ReactiveFormsModule, CommonModule, WucFormFieldStateModule],
    declarations: [WucInputCheckboxComponent],
    exports: [WucInputCheckboxComponent],
})
export class WucInputCheckboxModule {}
